import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseDialogComponent } from '@shared/components/base-dialog/base-dialog.component';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent extends BaseDialogComponent implements OnInit {

  constructor(modal: NgbActiveModal) {
    super(modal);
  }

  ngOnInit() {
  }
}
