import { IRamos } from './../../../../core/models/ramo.models';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FiltroProdutosUsuario } from '@core/models/consulta-metodo.models';
import { ProdutoCard } from '@core/models/produto.models';
import { ConsultaService } from '@core/services/consulta.service';
import { MatPageable } from '@core/table/mat-table';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { StorageService } from '@shared/utils/services/storage.service';
import moment from 'moment';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-dash-favorites',
  templateUrl: './dash-favorites.component.html',
  styleUrls: ['./dash-favorites.component.scss']
})
export class DashFavoritesComponent implements OnInit {

  produtos: ProdutoCard[] = [];

  pageableProduto = new MatPageable(0, 12);
  filtro: FiltroProdutosUsuario = { favorito: 1 };

  constructor(private consultaService: ConsultaService, private _storageService: StorageService, private _router: Router, public appSharedDataService: AppSharedDataService) { }

  ngOnInit(): void {
    this.produtos = this._storageService.get({ storageKey: EStorage.FAVORITES })?.value

    if (!(this.produtos && this.produtos.length)) {
      this.consultaService.getProdutosConsultaCrud(this.pageableProduto, this.filtro).subscribe((data) => {
        this.produtos = data
        this._storageService.set({ storageKey: EStorage.FAVORITES, data: data, expire: moment().add(1, 'hour').toDate() })
      })
      
    }
  }

  navigateTo(produto: any){
    const url = `/consulta/${produto.idProduto}/${produto.metodos[0].id}/${produto.dsServico}/${produto.metodos[0].dsMetodo}?favorito=true`
    this._router.navigateByUrl(url)
  }
     
}
