import { NgModule } from '@angular/core';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabResultadoComponent } from './components/tab-resultado/tab-resultado.component';
import { SharedModule } from '@shared/shared.module';
import { TabsRoutingModule } from './tabs-routing.module';
import { DynamicContentModule } from '@shared/components/dynamic-content/dynamic-content.module';

@NgModule({
  declarations: [TabsComponent, TabResultadoComponent],
  imports: [
    SharedModule,
    TabsRoutingModule,
    DynamicContentModule
  ],
  exports: [
    TabsComponent,
    TabResultadoComponent
  ]
})
export class TabsModule { }
