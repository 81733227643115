import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RnpaRestInterface } from './rnpa-rest-interface.service';
import { TipoPropostaApoliceEnum } from '../enumerators/types';
import { ApoliceStatusEnum } from '../enumerators';
import { ApoliceVidaGrupo, ApoliceVidaIndividual } from '../models';

interface ISearchParams {
  numeroDocumento: string,
  numeroApolice: string,
  tipoCadastro: string,
  dataCriacaoInicio: Date,
  dataCriacaoFim: Date,
  status: ApoliceStatusEnum,
  page: number,
  limit: number,
  sort: string,
  sortType: 'ASC' | 'DESC'
}

@Injectable({
  providedIn: 'root'
})
export class ApoliceService extends RnpaRestInterface {

  consultaUrl = environment.apiGatewayDns
  private _apolicesUrl = '/rnpa-pessoas/v1/propostas-apolices/apolices'
  private _apolicesViUrl = '/rnpa-pessoas/v1/propostas-apolices/apolices/vida-individual'
  private _apolicesVgUrl = '/rnpa-pessoas/v1/propostas-apolices/apolices/vida-grupo'
  private _motivoRecusaUrl = '/rnpa-pessoas/v1/motivos-recusa'

  constructor(
    public http: HttpClient
  ) {
    super(http)
  }

  // POST proposta
  createApolice(body, type: TipoPropostaApoliceEnum) {
    const methodUrl = type == TipoPropostaApoliceEnum.Vi ? this._apolicesViUrl : this._apolicesVgUrl
    const url = this.consultaUrl + methodUrl
    return this.post(url, body.Serialize())
  }

  // PATCH Apolice
  atualizarApolice(body: ApoliceVidaIndividual | ApoliceVidaGrupo) {
    let methodUrl = body.tipoPropostaApolice == TipoPropostaApoliceEnum.Vi ? this._apolicesViUrl : this._apolicesVgUrl
    const url = this.consultaUrl + methodUrl + `/${body.id}`
    const serializedBody = body.tipoPropostaApolice == TipoPropostaApoliceEnum.Vi ? new ApoliceVidaIndividual(body).SerializePatch() : new ApoliceVidaGrupo(body).SerializePatch()

    return this.patch(url, serializedBody)
  }

  // GET apolices
  listarApolices(searchParams: ISearchParams) {
    let appendedParams = new HttpParams()
    for (let param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        let serializedParam

        if (param == 'numeroDocumento')
          serializedParam = searchParams[param].replace(/[-./]/g, '')

        appendedParams = appendedParams.set(param, serializedParam || searchParams[param].toString())
      }
    }

    const url = this.consultaUrl + this._apolicesUrl
    return this.getFullResponse(url, appendedParams)
  }

  // GET motivo recusa
  listarMotivoRecusa() {
    const url = this.consultaUrl + this._motivoRecusaUrl
    return this.get(url)
  }
}