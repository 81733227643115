import { Component } from '@angular/core';
import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hora-result',
  templateUrl: './hora-result.component.html',
  styleUrls: ['./hora-result.component.scss']
})
export class HoraResultComponent  implements Field {
  config: FieldConfig;
  group: FormGroup;
}

