
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ApoliceStatusEnum, ProposalStatusEnum } from '../../enumerators';
import { TipoPropostaApoliceEnum } from '../../enumerators/types';

@Component({
  selector: 'app-output-template-consulta',
  templateUrl: './output-template-consulta.component.html',
  styleUrls: ['./output-template-consulta.component.scss', './../../styles.scss']
})
export class OutputTemplateConsultaComponent implements OnInit {

  proposals = Object.values(ProposalStatusEnum)
  apolices = Object.values(ApoliceStatusEnum)

  @Input() analiseBody: any
  @Input() pageControllerStateEmitter: EventEmitter<'form' | 'output'>

  resultadoAnaliseList = {
    apolices: [{key: 'vigente', enum: ApoliceStatusEnum.Vigente}, {key: 'cancelada', enum: ApoliceStatusEnum.Cancelada}],
    propostas: [{key: 'emAnalise', enum: ProposalStatusEnum.EmAnalise}, {key: 'aceita', enum: ProposalStatusEnum.Aceita}, {key: 'recusada', enum: ProposalStatusEnum.Recusada}]
  }

  // Enums
  proposalStatusEnum = ProposalStatusEnum
  apoliceStatusEnum = ApoliceStatusEnum
  tipoPropostaApoliceEnum = TipoPropostaApoliceEnum

  constructor() { }

  ngOnInit() {
  }

  goBack(){
    this.pageControllerStateEmitter.emit('form')
  }

}
