import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Masks } from '@core/custom-validators/masks';

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ds-custom-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
  ],
})
export class DatepickerComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() ngModel: any;
  @Input() name: string = '';
  @Input() mask: string = '';
  @Input() id: string = ''
  @Input() label: string = '';
  @Input() value: any = null;
  @Input() todayAsMaxDate: any = ''
  @Input() readonly: boolean = false
  @Input() opcional: any
  @Input() required: boolean = false

  @Output() ngModelChangeValue = new EventEmitter<any>()

  // Evento de validacao
  @Input() validateInputEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  dateInputValue: any // valor do input
  wasInputFocused: boolean = false; // foco do input
  wrongInput: boolean = false // formato invalido de data

  dateMask = {
    mask: Masks.data,
    guide: false,
    modelClean: true
  };

  constructor(
  ) { }

  ngOnInit() {
    if (this.todayAsMaxDate) {
      this.todayAsMaxDate = new Date()
    }

    this.validateInput()
  }

  validateInput() {
    this.validateInputEvent.subscribe((res) => {
      this.wasInputFocused = true
    })
  }

  emitDate(dateEvent) {
    const dateValue = (dateEvent.value) ? (dateEvent.value['_d']) : null
    this.ngModelChangeValue.emit(dateValue)
  }
  
  setInputValue(value: string) {
    this.dateInputValue = value ? value : null
    this.wrongInput = this.dateInputValue == null ? false : this.wrongInput 
  }

  validateFormat(dateValue: Date){
    this.wrongInput = !dateValue ? true : false
  }

}