import { AfterContentInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { BaseComponent } from '@core/component/base.component';
import { HttpStatusEnum } from '@core/http-interceptors/http-status';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { FuncionalidadeEnum } from '@core/models/funcionalidade.models';
import { NotificacaoElement } from '@core/models/notificacao.models';
import { PerfilEnum } from '@core/models/perfil.models';
import { TipoManualEnum } from '@core/models/tipo-manual.models';
import { ManualService } from '@core/services/manual.service';
import { MenuService } from '@core/services/menu.service';
import { MessageService } from '@core/services/message.service';
import { NotificacaoService } from '@core/services/notificacao.service';
import { NotificationService } from '@core/services/notification.service';
import { UsuarioService } from '@core/services/usuario.service';
import { MatPageable } from '@core/table/mat-table';
import { environment } from '@env/environment';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { NotificacaoDetalheComponent } from '@modules/notificacao/components/notificacao-detalhe/notificacao-detalhe.component';
import { TrocarSenhaComponent } from '@modules/trocar-senha/trocar-senha.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AppSharedDataService } from 'src/app/app-shared-data.service';
import { HeaderSharedDataService } from './header-shared-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentInit {

  @ViewChild("logo") logo;
  @ViewChild("abas") abas;
  @ViewChild("searchInput") searchInput: ElementRef;

  isLoggedIn$: Observable<boolean>;
  nomeUsuario: string;
  faStopwatch = faStopwatch;
  minutos: string;
  segundos: string;
  timer: any;
  timerToken: any;
  data: any;
  dataToken: any;
  pesquisaForm: FormGroup;
  pageableNotificacao = new MatPageable();
  notificacoes: NotificacaoElement[] = [];
  fotoUsuario: string;
  showSearch: boolean = true;
  stopTimerToken: boolean;
  isLogged: boolean;
  searchActive: boolean = false;
  menuAtivo: string;
  menuHover: any;
  menuUsuarioEnum = MenuUsuarioEnum;
  menuConfiguracoes: any[] = [];
  funcionalidadeEnum = FuncionalidadeEnum;
  perfilUsuario: string;
  ramoAtivo: boolean = false;
  inativarHome: boolean;
  wasInside = false;

  public isFocused = false;
  historicoPesquisa: any[] = [];

  tour: boolean;
  arrayStepTour: number = 1;
  stepsTour: any[] = [];
  titleTour: string;
  descriptionTour: string;

  menuConfiguracoesScroll: boolean = false;

  constructor(private appSharedDataService: AppSharedDataService,
    public authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private notificacaoService: NotificacaoService,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private headerSharedDataService: HeaderSharedDataService,
    private usuarioService: UsuarioService,
    private idle: Idle, private keepalive: Keepalive,
    private manualService: ManualService,
    private menuService: MenuService) {
    super(authenticationService)
  }

  ngAfterContentInit(): void {

    this.headerSharedDataService.showSearch$.subscribe((data) => {
      this.router.events.subscribe((event) => {

        if (event instanceof NavigationEnd) {
          let url = window.location.href.includes("produtos");
          if (!url) {
            if ((this.router.url.startsWith('/dashboard') || this.router.url.startsWith('/favorito'))) {
              this.showSearch = true;
              setTimeout(() => {
                this.showSearch = data;
                this.search.setValue(undefined);
              }, 100);
            }
            else
              this.showSearch = false;
          } else
            this.showSearch = false;
        }
      })
    })


    this.menuService.menuUsuarioConfiguracao().subscribe((data) => this.menuConfiguracoes = data);
    this.createTour();
  }

  ngOnInit() {

    this.idle.setIdle(60);
    this.idle.setTimeout(60 * environment.tempoAcesso);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeoutWarning.subscribe((countdown) => {

    });
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => {
      this.data = moment().add(environment.tempoAcesso, "minutes");
      this.localStorageService.setItem('dataExpiracaoAcesso', this.data);
    });

    this.idle.watch();

    this.pesquisaForm = this.formBuilder.group({
      pesquisa: ''
    });

    this.isLoggedIn$ = this.authenticationService.isLoggedIn;
    this.isLoggedIn$.subscribe((result) => {
      if (result) {
        this.isLogged = true;
        if (this.authenticationService.currentUserValue) {
          this.nomeUsuario = this.authenticationService.currentUserValue.nomeReduzido ? this.authenticationService.currentUserValue.nomeReduzido : this.authenticationService.currentUserValue.nomeCompleto;
          this.getPerfilUsuario();
          this.usuarioService.getDadosPerfil(this.authenticationService.currentUserValue.id_usuario).subscribe((data) => {
            this.appSharedDataService.setActiveTour(data.tour);
            if (data instanceof Array) {
              this.fotoUsuario = data[0].foto;
            }
            else {
              this.fotoUsuario = data.foto;
            }
          })
        }
        this.getNotificacoes();
      } else
        this.isLogged = false;
    })

    this.appSharedDataService.menuToogle$.subscribe(() => {
      if (this.logo)
        this.logo.nativeElement.classList.toggle("logo-fechado");
      if (this.abas)
        this.abas.nativeElement.classList.toggle("abas-fechado");
    })

    this.headerSharedDataService.atualizarMeusDados$.subscribe((data) => {
      if (data.foto)
        this.fotoUsuario = data.foto;

      this.nomeUsuario = data.nomeReduzido ? data.nomeReduzido : data.nomeCompleto;

      let userData = JSON.parse(this.localStorageService.getItem('currentUser'));
      if (userData) {
        userData.nomeReduzido = data.nomeReduzido;
        userData.nomeCompleto = data.nomeCompleto;
        this.localStorageService.setItem('currentUser', JSON.stringify(userData));
      }
    })

    let data = this.localStorageService.getItem('dataExpiracaoAcesso');
    if (data) {
      this.data = moment(data);

      this.timer = setInterval(() => {
        let dataAtual = moment();
        if (this.data.toDate() < dataAtual.toDate()) {
          clearInterval(this.timer);
          this.authenticationService.logout(true, 'Tempo de sessão expirado');
        }

        let momentDate = moment.utc(this.data.diff(dataAtual.toDate()));
        this.minutos = momentDate.format("mm")
        this.segundos = momentDate.format("ss")
      }, 1000)
    }

    let dataToken = this.localStorageService.getItem('dataExpiracaoToken');
    if (dataToken) {
      this.dataToken = moment(dataToken);

      this.timerToken = setInterval(() => {
        let dataAtual = moment();
        let duration = moment.duration(this.dataToken.diff(dataAtual));
        let minutes = duration.asMinutes();
        if ((minutes < environment.tempoRestanteRefreshToken) && !this.stopTimerToken) {
          this.stopTimerToken = true;
          this.authenticationService.refreshToken().subscribe((response) => {
            if (response.token) {
              this.localStorageService.setItem('token', JSON.stringify(response.token));
              this.dataToken = moment().add(environment.tempoAcesso, "minutes");
              this.localStorageService.setItem('dataExpiracaoToken', this.dataToken);
            }
            this.stopTimerToken = false;
          },
            (error) => {
              if (this.timer)
                clearInterval(this.timer);

              this.authenticationService.logout(true, 'Tempo de sessão expirado. Não foi possível renovar');
            })
        }
      }, 1000)
    }

    this.search.valueChanges.subscribe((val) => {
      if (val) {
        this.searchActive = true;
      }
      else {
        this.headerSharedDataService.onSearch(val);
        if (this.searchActive)
          this.searchActive = false;
      }
    })

    this.appSharedDataService.ramoAtivo$.subscribe((res) => {
      if (res) {
        this.activeMenuItem(null)
      } else {
        this.activeMenuItem(this.menuUsuarioEnum.Dashboard)
      }
    })
  }

  logout(): void {
    this.messageService.confirm('Deseja realmente sair do sistema?', (confirm) => {
      if (confirm === true)
        this.authenticationService.logout();
    })
  }

  getRamoAtivo() {
    this.appSharedDataService.ramoAtivo$.subscribe((res) => {
      if (res || this.search.value) {
        this.ramoAtivo = false;
        if (this.ramoAtivo) {
          return "active";
        } else {
          return null;
        }
      }
    })
  }

  get tempoTerminando(): boolean {
    return this.minutos == '00' && parseInt(this.segundos) > 0;
  }

  get search() {
    return this.pesquisaForm.controls.pesquisa;
  }

  getNotificacoes(redirectToDash?:boolean): void {
    
    // Verifica se esta mandando para Dashboard
    const isDashboard: boolean = redirectToDash ? true : this.router.url.includes('dashboard')
   
    if (this.isLogged && isDashboard)
      if (this.authenticationService.currentUserValue)
        this.notificacaoService.buscarNotificacoes(this.pageableNotificacao, this.authenticationService.currentUserValue.id_usuario).subscribe((data) => {
          if (Array.isArray(data))
            this.notificacoes = data;
        }, (error) => {
          if (error.status == HttpStatusEnum.NotFound) {
            this.notificacoes = [];
          }
        })
  }

  get hasNotificacoes(): boolean {
    return this.notificacoes.length > 0;
  }

  get countNotificacoes(): number {
    return this.notificacoes.length;
  }

  deleteNotificacao(notificacao: NotificacaoElement, event): void {
    if (event)
      this.notificacaoService.excluirNotificacao(notificacao).subscribe((data) => {
        this.notificationService.success('Notificação excluída com sucesso!!!');
        this.getNotificacoes();
      })
  }

  deleteTodasNotificacoes(): void {
    if (this.authenticationService.currentUserValue)
      this.messageService.confirm('Deseja realmente excluir todas as notificações?', (data) => {
        if (data === true)
          this.notificacaoService.excluirTodasNotificacoes(this.authenticationService.currentUserValue.id_usuario).subscribe((data) => {
            this.notificationService.success('Notificações excluídas com sucesso!!!');
            this.getNotificacoes();
          })

      })
  }

  openNotificacao(notificacao: NotificacaoElement, event): void {

    if (event.target.nodeName == 'DIV') {
      let component = this.modalService.open(NotificacaoDetalheComponent, {
        keyboard: false,
        beforeDismiss: () => this.authenticationService.currentUserValue == null,
        windowClass: 'modal-back-drop-z-index-999'
      });
      let instance = <NotificacaoDetalheComponent>component.componentInstance;
      instance.notificacao = notificacao;

      component.result.then((data) => {
      })
    }
  }

  goToMeuPerfil(): void {
    this.router.navigate(['perfil'])
  }

  goToMinhasConsultas(): void {
    this.router.navigate(['minhas-consultas'])
  }

  openTrocarSenha(): void {
    let component = this.modalService.open(TrocarSenhaComponent, {
      keyboard: false,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      }
    });
    let instance = <TrocarSenhaComponent>component.componentInstance;
    component.result.then((data) => {
    })
  }

  openCentralAtendimento(): void {
    window.open(environment.urlCentralAtendimento, '_blank')
  }

  searchProdutos(): void {
    this.headerSharedDataService.onSearch(this.search.value);
    this.activeMenuItem(this.menuUsuarioEnum.Pesquisar);
  }

  ngOnDestroy(): void {
    if (this.timer)
      clearInterval(this.timer);

    if (this.timerToken)
      clearInterval(this.timerToken);
  }

  get urlManual() {
    return this.isMaster || this.isUsuario ? '/assets/manual/Manual do Usuario - Novo Portal - Master e Comum.pdf' : '/assets/manual/Manual do Usuario - Novo Portal - Administrador.pdf';
  }

  downloadManual() {
    this.manualService.listar({ ativo: true, idTipoManual: TipoManualEnum.Sistema }).subscribe((data) => {
      if (data.length) {
        let filter = data.filter(x => x.idPerfil == this.usuarioLogado.id_perfil);
        if (filter.length) {
          this.manualService.download(filter[0]).subscribe((data) => {
            let splitUrl = filter[0].url.split('/');
            FileSaver.saveAs(data.body, splitUrl[splitUrl.length - 1]);
          })
        } else {
          let filter = data.filter(x => x.idPerfil == null);
          if (filter.length) {
            this.manualService.download(filter[0]).subscribe((data) => {
              let splitUrl = filter[0].url.split('/');
              FileSaver.saveAs(data.body, splitUrl[splitUrl.length - 1]);
            })
          } else {
            this.notificationService.info("Nenhum manual encontrado.");
          }
        }
      } else {
        this.notificationService.info("Nenhum manual encontrado.");
      }
    }
    )
  }

  timerIcon() {
    return this.minutos ? `Sessão encerra em ${this.minutos}:${this.segundos}` : `Sessão Ecnerrada`
  }

  scrollBody(active) {
    active ? this.menuConfiguracoesScroll = true : this.menuConfiguracoesScroll = false;
  }

  activeMenuItem(urlAcesso) {
    if (this.menuAtivo != urlAcesso) {
      this.menuAtivo = urlAcesso;
      if (!urlAcesso || !this.menuConfiguracoesScroll)
        this.appSharedDataService.setConfiguracaoMenu(false)
      if (urlAcesso == MenuUsuarioEnum.Configuracoes && this.menuConfiguracoesScroll)
        this.appSharedDataService.setConfiguracaoMenu(true)
    }
  }

  activeMenuItemToggle(urlAcesso) {
    this.wasInside = true;
    if (this.menuAtivo == urlAcesso) {
      this.menuAtivo = null;
    } else {
      this.menuAtivo = urlAcesso;
    }
    if (!urlAcesso || !this.menuConfiguracoesScroll)
      this.appSharedDataService.setConfiguracaoMenu(false)
    if (urlAcesso == MenuUsuarioEnum.Configuracoes && this.menuConfiguracoesScroll)
      this.appSharedDataService.setConfiguracaoMenu(true)
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside && this.menuAtivo != null) {
      this.menuAtivo = null;
    }
    this.wasInside = false;
  }

  resetOpenMenuConfiguracoes() {
    this.menuConfiguracoes.forEach(x => x.open = false);
  }

  closeItems(item) {
    this.menuConfiguracoes.forEach(x => {
      if (x != item) {
        x.open = false;
      }
    });
  }

  getPerfilUsuario() {
    switch (this.authenticationService.currentUserValue.id_perfil.toString()) {
      case PerfilEnum.Administrador:
        this.perfilUsuario = 'Administrador'
        break;
      case PerfilEnum.Api:
        this.perfilUsuario = 'Api'
        break;
      case PerfilEnum.Master:
        this.perfilUsuario = 'Master'
        break;
      case PerfilEnum.SuperUsuario:
        this.perfilUsuario = 'Super Usuário'
        break;
      case PerfilEnum.Usuario:
        this.perfilUsuario = 'Usuário'
        break;
    }
  }

  createTour(): void {
    this.appSharedDataService.activeTour$.subscribe((res) => this.tour = res);
    this.appSharedDataService.stepsTour$.subscribe((res) => this.stepsTour = res);
    this.appSharedDataService.stepTourJson$.subscribe((res) => {
      this.titleTour = res[this.arrayStepTour].title;
      this.descriptionTour = res[this.arrayStepTour].description;
    });
  }

  resetMenuBootstrap(): void {
    this.appSharedDataService.setResetMenuBootstrap(true);
    this.pesquisaForm.controls.pesquisa.reset();
    this.activeMenuItem(this.menuUsuarioEnum.Dashboard);

  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}

export enum MenuUsuarioEnum {
  Dashboard = 'dashboard',
  Pesquisar = 'pesquisar',
  Configuracoes = 'configuracoes',
  Notificacoes = 'notificacoes',
  Favoritos = 'favorito',
  Lotes = 'lote',
  Usuarios = 'usuarios',
  Empresas = 'empresas',
  Contratos = 'contratos',
  Manual = 'manual'
}
