import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-updates',
  templateUrl: './dash-updates.component.html',
  styleUrls: ['./dash-updates.component.scss']
})
export class DashUpdatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
