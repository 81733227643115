import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';

@Component({
  selector: 'form-input',
  styleUrls: ['form-input.component.scss'],
  template: `
    <div class="form-group col-md" [formGroup]="group" [style.display]="config.visible?'block':'none'">
       <label for="{{config.name}}" [class.required]="config.required">{{ config.label }}</label>
        <div *ngIf="config.mask; else noMask">
          <input type="text" class="form-control input-sm" [textMask]="config.mask" [attr.placeholder]="config.placeholder" [formControlName]="config.name"/>
        </div>
        <ng-template #noMask>
          <input type="text" class="form-control input-sm" [attr.placeholder]="config.placeholder" [formControlName]="config.name"/>
        </ng-template>
  </div>
  `
})
export class FormInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}