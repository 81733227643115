import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatPageable } from '@core/table/mat-table';
import { ProdutoCombo, FiltroProduto, ProdutoElement, Produto } from '@core/models/produto.models';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

const routes = {
  buscatodos: (pagina: number, registros: number) => `produto/buscatodos/${pagina}/${registros}`,
  buscaPorParametros: `produto/buscaporvalores`,
  inativar: `produto/inativarstatus`,
  reativar: `produto/ativarstatus`,
  buscaPorId: (id: number) => `crud/produtos/${id}`,
  cadastrar: `produto/cadastrar`,
  atualizar: `produto/alterar`,
  publicar: `produto/ativarpublicacao`,
  removerPublicacao: `produto/inativarpublicacao`,
  colocarEmDesenvolvimento: `produto/emdesenvolvimentostatus`,
  cadastrarCrud: `crud/salvarProduto`,
  atualizarCrud: (idProduto: number) => `crud/alterarProduto/${idProduto}`,
  buscaProdutosEmpresa: (idEmpresa: number) => `crud/produtos/disponiveis/${idEmpresa}`,
}


@Injectable({
  providedIn: 'root'
})
export class ProdutoService {


  constructor(private apiService: ApiService) { }

  buscarTodosCombo(pagination: MatPageable) {
    return this.apiService.get<ProdutoCombo[]>(routes.buscatodos(pagination.pagina, pagination.registros)).pipe(map((data) => {
      return data.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
    }));
  }

  buscarProdutosEmpresa(idEmpresa: number) {
    return this.apiService.getCustomApi<ProdutoCombo[]>(`${environment.lote}/${routes.buscaProdutosEmpresa(idEmpresa)}`).pipe(map((data) => {
      return data.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
    }));
  }

  getProdutos(pagination: MatPageable, filtro: FiltroProduto) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.get<any>(routes.buscaPorParametros + pagination.query, params).pipe(map((data) => {
      if (Array.isArray(data.produtos))
        data.produtos.forEach(x => {
          if (x.ramos instanceof Object) {
            x.ramos = [x.ramos];
          }
        })
      else {
        if (data.produtos.ramos instanceof Object) {
          data.produtos.ramos = [data.produtos.ramos];
        }
      }

      if (Array.isArray(data.produtos)) {
        return <ProdutoElement[]>data.produtos
      }

      return <ProdutoElement[]>[data.produtos];
    }));
  }

  inativar(idProduto: string) {
    return this.apiService.put(routes.inativar, {
      Produto: {
        idProduto: idProduto
      }
    });
  }

  reativar(idProduto: string) {
    return this.apiService.put(routes.reativar, {
      Produto: {
        idProduto: idProduto
      }
    });
  }

  salvar(produto: Produto) {
    if (produto.idProduto)
      return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.atualizarCrud(produto.idProduto)}`, produto);
    else
      return this.apiService.postCustomApi<any>(`${environment.lote}/${routes.cadastrarCrud}`, produto);
  }

  getProduto(id: number) {
    return this.apiService.getCustomApi<Produto>(`${environment.lote}/${routes.buscaPorId(id)}`);
  }

  publicar(idProduto: string) {
    return this.apiService.put(routes.publicar, {
      Produto: {
        idProduto: idProduto
      }
    });
  }

  removerPublicacao(idProduto: string) {
    return this.apiService.put(routes.removerPublicacao, {
      Produto: {
        idProduto: idProduto
      }
    });
  }

  colocarEmDesenvolvimento(idProduto: string) {
    return this.apiService.put(routes.colocarEmDesenvolvimento, {
      Produto: {
        idProduto: idProduto
      }
    });
  }


  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
}
