import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { FiltroProdutosUsuario } from '@core/models/consulta-metodo.models';
import { RamosMenuEnum } from '@core/models/ramo.models';
import { StatusRamoEnum } from '@core/models/status-ramo.models';
import { StepsTourEnum } from '@core/models/tour.models';
import { DashboardService } from '@core/services/dashboard.service';
import { NotificationService } from '@core/services/notification.service';
import { ParametroPortalService } from '@core/services/parametro-portal.service';
import { RamoService } from '@core/services/ramo.service';
import { MatPageable } from '@core/table/mat-table';
import { environment } from '@env/environment';
import {
  faBuilding,
  faCar,
  faClipboardList,
  faCogs,
  faDesktop,
  faFileSignature,
  faHome,
  faReceipt,
  faStar,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { ContatoComponent } from '@modules/contato/contato.component';
import { SobreComponent } from '@modules/sobre/sobre.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JoyrideService } from 'ngx-joyride';
import { AppSharedDataService } from 'src/app/app-shared-data.service';
import { HeaderSharedDataService } from '../header/header-shared-data.service';
import { StorageService } from '@shared/utils/services/storage.service';
import { EStorage } from '@shared/utils/enums/storage.enum';
import moment from 'moment';

export class ItemMenuBoostrap {
  nome: string;
  rota?: any;
  subItens?: ItemMenuBoostrap[];
  open?: boolean;
  icone?: any;
  permissao?: string[];
  ativo?: boolean;
  menuAtivo?: boolean;
  linkExterno?: string;
  svg?: string;
  breadCrumb?: any;
  rotaRamo?: string;
  id?: number;
}

@Component({
  selector: 'app-menu-boostrap',
  templateUrl: './menu-boostrap.component.html',
  styleUrls: ['./menu-boostrap.component.scss']
})
export class MenuBoostrapComponent implements OnInit, AfterViewInit {

  menu: ItemMenuBoostrap[];
  faHome = faHome;
  faUsers = faUsers;
  faBuilding = faBuilding;
  faFileSignature = faFileSignature;
  faCogs = faCogs;
  faReceipt = faReceipt;
  faStar = faStar;
  faClipboardList = faClipboardList;
  faCar = faCar;
  faDesktop = faDesktop;
  @ViewChild("sidebar") sidebar;
  versao: string;
  active: boolean;
  searchVisible: boolean;
  menuMobile: any;
  menuOver: boolean = false;
  subMenuAtivo: any;
  subMenuAtivoBoolean: boolean = false;
  subMenuAtivoOpen: boolean = false;
  menuUsuarioAtivo: boolean = false;
  ramos: any[] = [];
  meusProdutosGroup: any[] = [];
  menuFinal: any[] = [];
  filtro: FiltroProdutosUsuario;
  pageableProduto = new MatPageable(0, 999);
  linkActive: any;

  tour: boolean;
  propertyTourId: number = 37;
  arrayStepTour = 0;
  stepsTour: any;
  stepsJson: any;
  stepTour: string;
  titleTour: string;
  descriptionTour: string;
  counterSteps: number = 0;

  menuDashboard: boolean = false;
  ramoActive: any;

  produtoAtivo: boolean = false;
  paginasAgrupadasPbi: any;
  produtosMenuPowerBi: any[] = [];
  isPainelDashboard: boolean;
  subItemPbi: any;
  metodoAtivo: any;

  @HostListener('document:click', ['$event'])
  handleClick(event) {
    this.createBorder(event);
  }

  @Output() menuLoaded = new EventEmitter<boolean>()

  constructor(
    private router: Router, private modalService: NgbModal,
    private appSharedDataService: AppSharedDataService,
    private authenticationService: AuthenticationService,
    private headerSharedDataService: HeaderSharedDataService,
    private ramoService: RamoService,
    private notificationService: NotificationService,
    private joyrideTour: JoyrideService,
    private parametroPortalService: ParametroPortalService,
    private dashboardService: DashboardService,
    private storageService: StorageService
  ) {
  }

  ngOnInit() {
    this.filtro = {};
    this.versao = environment.version;
    this.validateTour();

    this.headerSharedDataService.searchVisible$.subscribe((data) => this.searchVisible = data);
    this.appSharedDataService.menuBootstrap$.subscribe((data) => this.meusProdutosGroup = data);
    this.appSharedDataService.resetMenuBootstrap$.subscribe((res) => {
      if (res)
        this.linkActive = undefined;
      this.subMenuAtivoOpen = this.subMenuAtivoBoolean = false;
      this.meusProdutosGroup.forEach(x => x.open = false);
    });

    this.produtosMenuPowerBi = this.dashboardService.powerBiMenu

    this.ramoService.getRamos(new MatPageable(), { idStatus: StatusRamoEnum.Ativo }).subscribe((data) => {
      this.ramos = data;
      this.buscarProdutosPorRamo(data);
    })

    this.appSharedDataService.metodoAtivo$.subscribe(res => {
      if (res) {
        this.metodoAtivo = res;
        this.linkActive = res.metodo;
        (res.ramo.length == 1) ? res.ramo = this.ramos.find(x => x.id == res.ramo) : null;
        this.goToRoute(res.ramo, res.produto);
      }
    })

    if (!this.linkActive) {
      this.appSharedDataService.ramoAtivo$.subscribe(res => {
        if (typeof res === 'object') {
          this.subMenuAtivoOpen = this.subMenuAtivoBoolean = false;
          this.meusProdutosGroup.find(x => x.id == res.id).open = true;
          this.meusProdutosGroup.filter(x => x.id != res.id).forEach(x => x.open = false);
        } else {
          let ramoAtual = this.ramos.find(x => x.id == res)
          this.subMenuAtivoOpen = this.subMenuAtivoBoolean = false;
          this.meusProdutosGroup.filter(x => x.id != ramoAtual.id).forEach(x => x.open = false);
        }
      })
    }

    this.appSharedDataService.pagePbiChangedNext$.subscribe(res => {
      this.subItemPbi = res;
    })

    this.appSharedDataService.pagePbiChangedPrevious$.subscribe(res => {
      this.subItemPbi = res;
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.borderTour();
    }, 10)
  }

  toogleItem(ramo: ItemMenuBoostrap) {
    ramo.open = !ramo.open;
    this.meusProdutosGroup.filter(x => x.nome != ramo.nome).forEach(x => x.open = false);
    this.appSharedDataService.setRamoAtivo(ramo.id);
    ramo.open ? this.goToProdutos(ramo) : null;
  }

  goToRoute(ramo: any, produto: any) {
    this.subItemPbi = undefined;
    if (ramo.id == 1) {
      if (produto != undefined && produto.idProduto && produto.idProduto == 80) {
        this.isPainelDashboard = true;
        if (this.linkActive) {
          if (this.linkActive.subItem) {
            this.subItemPbi = this.linkActive.subItem.subItem[0].nameReport;
          } else {
            let linkActive = this.produtosMenuPowerBi.find((x: any) => x.id == this.linkActive.id);
            if (linkActive) {
              this.subItemPbi = linkActive.subItem[0].nameReport;
            }
          }
        }
      } else {
        this.isPainelDashboard = false;
      }
    }
    if (ramo.id != 1) {
      this.isPainelDashboard = false;
    }
    let produtoAtivo;
    this.meusProdutosGroup.forEach(x => {
      if (ramo && !produto) {
        ramo.open = !ramo.open;
        this.meusProdutosGroup.filter(x => x.nome != ramo.nome).forEach(x => x.open = false);
      }
      if (x.produtos) {
        if (x.nome == ramo.nome && produto) {

          if (this.linkActive) {
            x.produtos.find(s => {
              if (s.idProduto == produto.idProduto) {
                if (!produto.ativo) {
                  produto.ativo = false;
                  produtoAtivo = false;
                  this.produtoAtivo = produtoAtivo;
                }
              }
            })
          }
          x.produtos.filter(y => y.idProduto != produto.idProduto).forEach(y => y.ativo = false);
          this.adicionarMenuPowerBi(produto);
          this.subMenuAtivo = produto;
        }
        else {
          x.ativo = false;
          x.produtos.forEach(i => i.ativo = false);

          if (this.subMenuAtivo != produto)
            this.subMenuAtivo = null;
        }
      }
    })

    if (ramo) {
      this.meusProdutosGroup.find(x => x.nome == ramo.nome ? x.open = true : x.open = false);
    }

    if (this.subMenuAtivo) {
      if (!this.menuMobile && produtoAtivo == undefined)
        this.subMenuAtivoBoolean = (this.subMenuAtivoOpen = !this.subMenuAtivoOpen);

      if (!this.menuMobile && produtoAtivo == false)
        this.subMenuAtivoBoolean = (this.subMenuAtivoOpen = !this.subMenuAtivoOpen);

      if (this.menuOver)
        this.subMenuAtivoBoolean = (this.subMenuAtivoOpen = !this.subMenuAtivoOpen);
    }


  }

  goToRouteSubAtivo(menu: any, item: any, subItem: any): void {
    subItem.open = !subItem.open;
    menu.subItens.forEach(x => {
      if (x.nome == item.nome && subItem) {
        x.subItens.find(s => s.nome == subItem.nome ? subItem.open = true : subItem.open = false);
        x.subItens.filter(y => y.nome != subItem.nome).forEach(y => y.open = false);
      }
      else {
        x.ativo = false;
        if (x.subItens)
          x.subItens.forEach(i => i.open = false);
      }
    });
    this.router.navigate(subItem.rota)
  }

  goToDashboard(produto: any, metodo: any, subItem: any) {
    this.appSharedDataService.setPaginaPbi(subItem);
    sessionStorage.setItem('reportNamePBI', subItem.nameReport);
    this.goToConsulta(produto, metodo, subItem);

  }

  toogleMenu(): void {
    const arrayDeElementos = Array.from(this.sidebar.nativeElement.childNodes);
    const conteudoMenu = arrayDeElementos.find((elemento: HTMLElement) => elemento.classList.contains('conteudo-menu')) as HTMLElement;
    this.sidebar.nativeElement.classList.toggle("active");
    this.appSharedDataService.setToogleMenu();
    this.active = this.sidebar.nativeElement.classList.contains("active");
    if (this.active) {
      conteudoMenu.style.setProperty('overflow-y', 'initial', 'important')
    } else {
      conteudoMenu.style.removeProperty('overflow-y')
    }
    this.menuMobile = !this.menuMobile;
    this.subMenuAtivoBoolean ? (this.subMenuAtivoOpen = !this.subMenuAtivoOpen) : this.subMenuAtivoOpen = false;
    this.appSharedDataService.setMenuActive(this.active);
  }

  over() {
    this.menuMobile = false;
    this.menuOver = true;
    this.toogleMenu();
  }

  out() {
    this.menuMobile = false;
    this.menuOver = false;
    this.toogleMenu();
  }

  openSobre(): void {
    this.modalService.open(SobreComponent, {
      keyboard: false,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      centered: true
    });
  }

  openContato(): void {
    this.modalService.open(ContatoComponent, {
      keyboard: false,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      centered: true
    });
  }

  addIconsMenu(ramo) {
    switch (ramo.id) {
      case RamosMenuEnum.Automovel:
        return "automovel"
      case RamosMenuEnum.Habitacional:
        return "habitacional"
      case RamosMenuEnum.Multiramo:
        return "multiramo"
      case RamosMenuEnum.Saude:
        return "saude"
      case RamosMenuEnum.Previdencia:
        return "previdencia"
      case RamosMenuEnum.Vida:
        return "vida"
    }
  }

  showMetodo(produto) {
    return produto.metodos.length >= 2;
  }

  goToConsulta(produto: any, metodo: any, subItem: any): void {
    this.linkActive = metodo;
    if (subItem) {
      this.subItemPbi = subItem.nameReport;
    }
    if (produto.acesso && metodo.acesso) {
      this.router.navigate(['/consulta', produto.idProduto, metodo.id, produto.dsServico, metodo.dsMetodo], { queryParamsHandling: 'merge', queryParams: { favorito: produto.favorito } });
    }
    else
      this.notificationService.info('Você não possui permissão para realizar esta consulta!!!');
  }

  goToLinkExterno(acesso: any): void {
    if (!acesso)
      this.notificationService.info('Você não possui permissão para acessar este produto!!!');
  }

  validateTour() {
    this.appSharedDataService.activeTour$.subscribe((res) => {
      this.tour = res;
      if (this.tour) {
        this.parametroPortalService.getParametroPortal(this.propertyTourId).subscribe((res: any) => {
          const stepsJson = this.decodeEntities(res.valor);
          const stepsTour = []

          stepsJson.tour.forEach(x => stepsTour.push(x.step));
          this.appSharedDataService.setStepsTour(stepsTour);
          this.appSharedDataService.setStepTourJson(stepsJson.tour);

          this.titleTour = stepsJson.tour[this.arrayStepTour].title;
          this.descriptionTour = stepsJson.tour[this.arrayStepTour].description;
          this.stepsTour = stepsTour;

          this.joyrideTour.startTour({
            steps: this.stepsTour,
            stepDefaultPosition: 'right',
            showCounter: false
          });
        })
      }
    });
  }

  decodeEntities(str) {
    const element = document.createElement('div');
    if (str && typeof str === 'string') {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    return JSON.parse(str);
  }

  buscarProdutosPorRamo(ramos): void {
    let meusProdutos = this.storageService.get({ storageKey: EStorage.MENU })
    if (meusProdutos) {
      this.meusProdutosGroup = meusProdutos.value;
      this.menuLoaded.emit(true)

      if (meusProdutos) {
        if (this.meusProdutosGroup.length) {
          this.adicionarMenuPowerBi(this.meusProdutosGroup);
        }
      }

    } else {
      this.dashboardService.getAllRamos().subscribe((ramoRes) => {
        this.meusProdutosGroup = ramoRes.body
        this.storageService.set({storageKey: EStorage.MENU, data: ramoRes.body, expire: moment().add(1,'hour').toDate()})
        this.menuLoaded.emit(true)
      })

      this.appSharedDataService.menuBootstrap$.subscribe((data) => {
        this.meusProdutosGroup = data;
        this.adicionarMenuPowerBi(this.meusProdutosGroup);
      });
    }

  }

  adicionarMenuPowerBi(menu: any) {
    if (menu.length) {
      const ramos = menu.find((automoveis: any) => automoveis.id == 1)
      const produto = ramos.produtos.find((dashboard: any) => dashboard.idProduto == 80)
      if (!(produto && produto.metodos)) return
      produto.metodos.forEach(metodo => metodo.subItem = this.produtosMenuPowerBi.find(x => x.id == metodo.id));
    } else {
      if (!(menu && menu.metodos)) return
      menu.metodos.forEach(metodo => metodo.subItem = this.produtosMenuPowerBi.find(x => x.id == metodo.id));
    }
  }

  createBorder(event): void {
    let eventClass = event.target.classList;
    if (this.tour) {
      if (eventClass.contains('next'))
        this.counterSteps++
      else if (eventClass.contains('back'))
        this.counterSteps--
      else
        this.counterSteps

      setTimeout(() => {
        this.borderTour();
      }, 5)
    }
  }

  borderTour(): void {
    let e = document.querySelector("div.backdrop-target");
    if (e) {
      switch (this.counterSteps) {
        case StepsTourEnum.menuBootstrapStep:
          e.innerHTML = "<div class='b-tour b-tour-top-left'><div>";
          break;
        case StepsTourEnum.headerStep:
          e.innerHTML = "<div class='b-tour headerStep b-tour-top-left'></div><div class='b-tour headerStep b-tour-bottom-left'></div><div class='b-tour headerStep b-tour-top-right'></div><div class='b-tour headerStep b-tour-bottom-right'></div>";
          break;
        case StepsTourEnum.dashboardCardStep:
          e.innerHTML = "<div class='b-tour dashboardCardStep b-tour-top-left'></div><div class='b-tour dashboardCardStep b-tour-bottom-left'></div><div class='b-tour dashboardCardStep b-tour-top-right'></div><div class='b-tour dashboardCardStep b-tour-bottom-right'></div>";
          break;
      }
    }
  }

  goToProdutos(ramo): void {
    this.router.navigate([`/dashboard/${ramo.id}/produtos`]);
  }
}