import { createNumberMask } from 'text-mask-addons';
import { MASKS } from 'ng-brazil';

export const Masks = {
    telefone: (value) => {
        let numbers = value.match(/\d/g);
        let numberLength = 0;
        if (numbers) {
            numberLength = numbers.join("").length;
        }

        if (numberLength > 10) {
            return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        } else {
            return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        }
    },
    cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    contrato: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    decimal: createNumberMask({
        decimalSymbol: ',',
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalLimit: 9
    }),
    currency: MASKS.currency.textMask,
    integer: createNumberMask({
        decimalSymbol: ',',
        prefix: '',
        includeThousandsSeparator: false
    }),
    periodoAno: [/\d/, /\d/, /\d/, /\d/],
    data: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    cpfCnpj: (value) => {
        let numbers = value.match(/\d/g);
        let numberLength = 0;
        if (numbers) {
            numberLength = numbers.join("").length;
        }

        if (numberLength > 11) {
            return Masks.cnpj
        } else {
            return Masks.cpf
        }
    },
    cep: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    susep: [/\d/, /\d/, /\d/, /\d/, /\d/],
    ip: [/[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/, /[0-9,*,.]/],
    imei: [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, '-', /\w/, /\w/, '-', /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, '-', /\w/],
    any: Array(240).fill(/.*/), // Array com 240 caracteres livres
    chassi: [/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/,/[A-Za-z0-9]/],
    placa:[/[a-zA-Z]/,/[a-zA-Z]/,/[a-zA-Z]/,/[0-9]/,/[A-Za-z0-9]/,/[0-9]/,/[0-9]/],
    cnae: [/[0-9]/,/[0-9]/],
    char: Array(240).fill(/^([A-Za-zá-úÁ-ÚàâãèêïôõöçñÀÂÃÈÏÔÕÖÇÑ\s]{1,240})$/),
}
