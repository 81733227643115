import { NgModule } from '@angular/core';

import { FavoritoRoutingModule } from './favorito-routing.module';
import { FavoritoComponent } from './pages/favorito/favorito.component';
import { SharedModule } from '@shared/shared.module';
import { HeaderSharedDataService } from '@shared/components/header/header-shared-data.service';
import { ComponentsProdutoModule } from '@modules/produto/components/components-produto.module';

@NgModule({
  declarations: [FavoritoComponent],
  imports: [
    SharedModule,
    FavoritoRoutingModule,
    ComponentsProdutoModule
  ],
  providers:[HeaderSharedDataService]
})
export class FavoritoModule { }
