import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabHeader } from '../../../models';

@Component({
  selector: 'ds-header-tab-menu',
  templateUrl: './header-tab-menu.component.html',
  styleUrls: ['./header-tab-menu.component.scss']
})


export class HeaderTabMenuComponent implements OnInit {

  @Input() tabHeaders: TabHeader[] = []
  @Output() tabHeaderStateEmitter = new EventEmitter<TabHeader[]>()

  constructor() { }

  ngOnInit() {
  }

  // Seleciona a tab
  selectTab(tab: TabHeader){
    if(!tab.active){
      for(let tab of this.tabHeaders) tab.active = false
      tab.active = true
      this.emitTabState()
    }
  }

  // Emite o estado do tab menu para o componente pai
  emitTabState(){
    this.tabHeaderStateEmitter.emit(this.tabHeaders)
  }

}
