import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SobreComponent } from './sobre.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [SobreComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [SobreComponent]
})
export class SobreModule { }
