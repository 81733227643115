import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApoliceStatusEnum } from '../enumerators/status';
import { TipoCadastroEnum, TipoPropostaApoliceEnum } from '../enumerators/types';
import { ApoliceVidaGrupo, ApoliceVidaIndividual } from '../models';
import { CoberturaResponse } from '../models/ramos-coberturas';
import { RamosCoberturasService } from '../services/ramos-coberturas.service';
import { TiposProdutoService } from '../services/tipos-produto.service';
import { ToastService } from '../services/toast.service';
import { Masks } from './../../../../../core/custom-validators/masks';
import { ApoliceService } from './../services/apolice.service';
import { CnaeService } from '../services/cnae.service';

interface ISearchParams {
  numeroDocumento: string,
  numeroApolice: string,
  tipoCadastro: string,
  dataCriacaoInicio: Date,
  dataCriacaoFim: Date,
  status: ApoliceStatusEnum,
  page: number,
  limit: number,
  sort: string,
  sortType: 'ASC' | 'DESC'
}

@Component({
  selector: 'app-alterar-apolice',
  templateUrl: './alterar-apolice.component.html',
  styleUrls: ['./alterar-apolice.component.scss', './../styles.scss',]
})

export class AlterarApoliceComponent implements OnInit {

  closeResult = '';

  // Obrigatorio em custom products
  cadastro: { nomeProduto: string, nomeMetodo: string } = {
    nomeProduto: 'RNPA Pessoas',
    nomeMetodo: 'AlterarApolice'
  }

  // Main body de apolice 
  apolices: (ApoliceVidaIndividual | ApoliceVidaGrupo)[]
  apoliceSelecionada: ApoliceVidaIndividual | ApoliceVidaGrupo

  // Enums
  apoliceStatusEnum = ApoliceStatusEnum
  tipoPropostaApoliceEnum = TipoPropostaApoliceEnum

  // Opcoes de filtros no header
  motivosRecusa: any[] = []
  statusApolice = Object.values(ApoliceStatusEnum)
  tiposCadastro = Object.values(TipoCadastroEnum)

  // Listagem de coberturas e tipos de produtos
  coberturas: CoberturaResponse[]
  tiposProdutos: any[]
  divisoesCnae: any[]

  searchParams: ISearchParams = {
    numeroDocumento: null,
    numeroApolice: null,
    tipoCadastro: null,
    dataCriacaoInicio: null,
    dataCriacaoFim: null,
    status: null,
    page: 1,
    limit: 10,
    sort: 'dataCriacao',
    sortType: 'DESC',
  }

  // Dicionario de Ids
  idDictionary = {
    coberturas: {},
    motivosRecusa: {},
    tiposProduto: {},
    divisoesCnae: {},
  }

  // Masks
  inputMasks = {
    cnpj: {
      mask: Masks.cnpj,
      guide: false,
      modelClean: true
    },
    cpf: {
      mask: Masks.cpf,
      guide: false,
      modelClean: true
    },
    cpfCnpj: {
      mask: Masks.cpfCnpj,
      guide: false,
      modelClean: true
    },
    telefone: {
      mask: Masks.telefone,
      guide: false,
      modelClean: true
    },
    number: {
      mask: Masks.integer,
      guide: false,
      modelClean: true
    },
    decimal: {
      mask: Masks.decimal,
      guide: false,
      modelClean: true
    },
    currency: {
      mask: Masks.currency,
      guide: false,
      modelClean: true
    }
  }

  clearDateInputEvent = new EventEmitter<boolean>()

  paginationCollectionSize: number = 10

  @Input() idProduto: number = 89;
  @Input() idMetodo: number = 1937;
  @Input() dsServico: string;
  @Input() dsMetodo: string;
  @Input() disabled: any;
  @Input() consulta: any;

  constructor(
    public authenticationService: AuthenticationService,
    private nbgModalService: NgbModal,
    private _apoliceService: ApoliceService,
    private _toastService: ToastService,
    private _tiposProdutoService: TiposProdutoService,
    private _ramosCoberturasService: RamosCoberturasService,
    private _cnaeService: CnaeService,

  ) {
  }

  ngOnInit() {
    this.fillSelectOptions()
    this.listarApolices()
  }

  listarApolices() {
    this._apoliceService.listarApolices(this.searchParams).subscribe(
      (res: HttpResponse<any>) => {
        this.apolices = res.body as Array<ApoliceVidaIndividual | ApoliceVidaGrupo>
        this.paginationCollectionSize = parseInt(res.headers.get('Total-Pages')) * (this.searchParams.limit ? this.searchParams.limit : 10)
      },
      (error) => {
        this._toastService.showError('Desculpe, não foi possível listar as apólices.')
      }
    )
  }

  // Seleção de apolice
  selecionarApolice(apolice: ApoliceVidaIndividual | ApoliceVidaGrupo, modalContent) {
    this.apoliceSelecionada = apolice.tipoPropostaApolice == TipoPropostaApoliceEnum.Vi ? new ApoliceVidaIndividual(apolice) : new ApoliceVidaGrupo(apolice);
    this.openModal(modalContent)
  }

  // Atualiza a apolice do form no modal
  updateApolice() {
    this._apoliceService.atualizarApolice(this.apoliceSelecionada).subscribe((res) => {
      this._toastService.showSuccess('Apolice atualizada com sucesso.')
      this.listarApolices()
      this.apoliceSelecionada = null
      this.nbgModalService.dismissAll()
    }, (err) => {
      const errorMsg = err.status == 422 ? err.error.mensagem : "Não foi possível atualizar a apólice."
      this._toastService.showError(errorMsg)
    })
  }

  openModal(content) {
    this.nbgModalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl', windowClass: 'custom-modal' })
  }

  fillSelectOptions() {
    this._apoliceService.listarMotivoRecusa().subscribe((res: any[]) => {
      this.motivosRecusa = res
      this.idDictionary.motivosRecusa = res.reduce((obj, motivo) => {
        obj[motivo.id] = motivo.motivo;
        return obj;
      }, {});

    })

    this._tiposProdutoService.listarTiposProduto().subscribe((res: any[]) => {
      this.tiposProdutos = res
      this.idDictionary.tiposProduto = res.reduce((obj, motivo) => {
        obj[motivo.id] = motivo.descricao;
        return obj;
      }, {});
    })

    this._ramosCoberturasService.listCoberturas().subscribe((res: CoberturaResponse[]) => {
      this.coberturas = res.sort((a,b) => {
        return a.cobertura > b.cobertura ? 1 : a.cobertura < b.cobertura ? -1 : 0 
      })
      this.idDictionary.coberturas = res.reduce((obj, cobertura) => {
        obj[cobertura.id] = cobertura;
        return obj;
      }, {});
    })

    this._cnaeService.listarDivisoesCnae().subscribe((res: any[]) => {
      this.divisoesCnae = res.sort((a, b) => {
        return parseInt(a.divisaoId) > parseInt(b.divisaoId) ? 1 : parseInt(a.divisaoId) < parseInt(b.divisaoId) ? -1 : 0
      });

      this.idDictionary.divisoesCnae = res.reduce((obj, cnae) => {
        obj[cnae.divisaoId] = cnae.descricao;
        return obj;
      }, {});
    })
  }

  // Lida com o evento do componente de paginacao
  setPage(page: number) {
    this.searchParams.page = page
    this.listarApolices()
  }

  // Limpa os campos de filtros
  clearFilter() {
    this.searchParams = {
      numeroDocumento: null,
      numeroApolice: null,
      tipoCadastro: null,
      dataCriacaoInicio: null,
      dataCriacaoFim: null,
      status: null,
      page: this.searchParams.page,
      limit: this.searchParams.limit,
      sort: null,
      sortType: null,
    }
    // Emite evento para limpar datepicker-range
    this.clearDateInputEvent.emit(true)
    this.listarApolices()
  }

  // Ordenação da lista
  sortBy(field: string) {
    this.searchParams.sort = field
    this.searchParams.sortType = (this.searchParams.sortType == 'ASC') ? 'DESC' : 'ASC'

    this.listarApolices()
  }

  handlePeriodoEmit(dateEvent: { dataInicio: Date, dataFinal: Date }) {
    this.searchParams.dataCriacaoInicio = dateEvent.dataInicio
    this.searchParams.dataCriacaoFim = dateEvent.dataFinal
  }

}
