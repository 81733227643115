import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Manual, FiltroManual } from '@core/models/manual.models';
import { environment } from '@env/environment';


const routes = {
  salvar: `crud/salvarManual`,
  alterar: (id: number) => `crud/alterarManual/${id}`,
  listar: `crud/manuais/listar`,
  download: (idManual: number) => `crud/download/manual/${idManual}`,
  downloadManualProduto: (idProduto: number) => `crud/download/manual/produto/${idProduto}`
}

@Injectable({
  providedIn: 'root'
})
export class ManualService {
  constructor(private apiService: ApiService) { }

  salvar(manual: Manual) {
    if (manual.id)
      return this.alterar(manual);

    return this.apiService.postCustomApi(`${environment.lote}/${routes.salvar}`, manual);
  }

  alterar(manual: Manual) {
    return this.apiService.putCustomApi(`${environment.lote}/${routes.alterar(manual.id)}`, manual);
  }

  listar(filtro: FiltroManual) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.getCustomApi<Manual[]>(`${environment.lote}/${routes.listar}`, params);
  }

  excluir(manual: Manual) {
    manual.ativo = false;
    return this.apiService.putCustomApi(`${environment.lote}/${routes.alterar(manual.id)}`, manual);
  }

  download(manual: Manual) {
    return this.apiService.downloadGetCustomApi(`${environment.lote}/${routes.download(manual.id)}`);
  }

  downloadManualProduto(idProduto: number) {
    return this.apiService.downloadGetCustomApi(`${environment.lote}/${routes.downloadManualProduto(idProduto)}`);
  }
}
