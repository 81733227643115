import { Component, HostListener, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-file-upload-new-layout',
  templateUrl: './file-upload-new-layout.component.html',
  styleUrls: ['./file-upload-new-layout.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadNewLayoutComponent,
      multi: true
    }
  ]
})
export class FileUploadNewLayoutComponent implements ControlValueAccessor {

  onChange: Function;
  file: File | null = null;
  @Input() extensoes: string[];
  faTrash = faTrash;
  @Input() buttonLabel:string;
  @Input() boxLabel:string;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
  }

  constructor(private host: ElementRef<HTMLInputElement>) {
  }

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }

  clear(): void {
    this.writeValue(null);
  }
}
