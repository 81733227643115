import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from '@core/services/usuario.service';
import { SenhaAlterar } from '@core/models/meu-perfil.models';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.scss']
})
export class TrocarSenhaComponent implements OnInit {

  trocarSenhaForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private notificationService: NotificationService) { }


  ngOnInit() {

    this.trocarSenhaForm = this.formBuilder.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', Validators.required],
      confirmaSenhaNova: ['', Validators.required]
    })
  }

  close() {
    this.activeModal.close();
  }

  salvar(): void {
    if (this.trocarSenhaForm.valid) {
      let obj = { ...<SenhaAlterar>this.trocarSenhaForm.value }
      let countRegras = 0;

      if (obj.senhaNova != obj.confirmaSenhaNova) {
        this.notificationService.info('A confirmação de senha não está igual a nova senha!!!');
        return;
      }

      if (obj.senhaNova.length < 8 || obj.senhaNova.length > 16) {
        this.notificationService.info('A senha deve ter no mínimo 8 e no máximo 16 caracteres!!!');
        return;
      }

      if (this.hasLowerCase(obj.senhaNova))
        countRegras++;

      if (this.hasNumber(obj.senhaNova))
        countRegras++;

      if (this.hasSpecialCharacter(obj.senhaNova))
        countRegras++;

      if (this.hasUpperCase(obj.senhaNova))
        countRegras++;

      if (countRegras < 3) {
        this.notificationService.info("A senha deve conter, no mínimo, 3 classes de caracteres: letras. maiúsculas, letras minúsculas, números ecaracteres especiais.");
        return;
      }

      this.usuarioService.trocarSenha(obj).subscribe((data) => {
        this.notificationService.success('Senha alterada com sucesso!!!');
        this.close();
      })
    }
  }

  hasLowerCase(str): boolean {
    return (/[a-z]/.test(str));
  }

  hasUpperCase(str): boolean {
    return (/[A-Z]/.test(str));
  }

  hasSpecialCharacter(str): boolean {
    return /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }

  hasNumber(str): boolean {
    return (/[0-9]/.test(str));
  }
}
