import { Directive, OnInit, Output, EventEmitter } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Directive({
  selector: '[customAutoComplete]'
})
export class CustomAutoCompleteDirective implements OnInit {

  @Output() onEndScroll: EventEmitter<any> = new EventEmitter();

  constructor(private selectElem: MatAutocomplete) {
  }

  ngOnInit() {
    this.selectElem.opened.subscribe(() => this.registerPanelScrollEvent());
  }

  registerPanelScrollEvent() {
    setTimeout(() => {
      const panel = this.selectElem.panel.nativeElement;
      panel.addEventListener('scroll', event => this.loadAllOnScroll(event));
    });

  }

  loadAllOnScroll(event) {
    if ((event.target.scrollTop + event.target.offsetHeight) == event.target.scrollHeight) {
      this.onEndScroll.emit(true);
    }
  }
}
