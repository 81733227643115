import { StorageService } from '@shared/utils/services/storage.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dash-recent-activity',
  templateUrl: './dash-recent-activity.component.html',
  styleUrls: ['./dash-recent-activity.component.scss']
})
export class DashRecentActivityComponent implements OnInit {

  constructor(private _localStorageService: LocalStorageService, private storageService: StorageService, private _router: Router) { }

  activities: { historico: { date: Date, url: string }[] } = { historico: [] }

  ngOnInit(): void {
    this.activities = JSON.parse(this._localStorageService.getItem('atividade-recente')) || null

    if (this.activities) this.findProducts()
  }

  findProducts() {
    const ramos = this.storageService.get({ storageKey: EStorage.MENU }).value as Array<any>
    let produtosFull: any[] = []

    // Encontrar o produto
    for (let ramo of ramos) {
      for (let produto of ramo.produtos) {
        produtosFull.push(produto)
      }
    }

   const mapedMetodos = this.activities.historico.map((activity) => {
      const regexConsultaProdutoMetodo = /\/consulta\/(\d+)\/(\d+)\//;

      const matches = activity.url.match(regexConsultaProdutoMetodo);
      const produtoId = matches[1];
      const metodoId = matches[2];

      const produto = produtosFull.find(el => el.idProduto == produtoId)

      const fullProdutoBody = {
        produto: produto.nomeProduto || 'Produto não encontrado',
        metodo: produto.metodos.find(el => el.id == metodoId).nome || 'Método não encontrado',
        date: activity.date,
        url: activity.url
      }

      return fullProdutoBody
    })
    this.activities.historico = mapedMetodos
  }

  navigateTo(url: string){
    this._router.navigateByUrl(url)
  }

}
