import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { Observable } from 'rxjs';
import { LocalStorageEnum } from '../enumerators';

@Injectable()
export class RnpaAuthInterceptor implements HttpInterceptor {

  private _authToken: string = 'Basic OTc3ZTZiYzktMmVmYS00MTU1LWJlMzQtYjFhNTdhNGI4MTE5OmE2YWEzZWYwLTRjZWMtNDc2Ni1hZmYwLWIzM2JiZmE4NDRlNQ==';

  constructor(public authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let modifiedReq: HttpRequest<any>
    let httpAuthHeaders: HttpHeaders

    // Verifica se é de authenticacao para Assistencia 24h e clona a req
    if (request.headers.has('assistencia24h_auth_request')) {
      const token = localStorage.getItem(LocalStorageEnum.TokenSensedia)
      // Header para req de autenticacao
      httpAuthHeaders = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      })

      modifiedReq = request.clone({ headers: httpAuthHeaders })
      return next.handle(modifiedReq);
    }
    
    // Verifica se é de authenticacao para RNPA e clona a req
    if (request.headers.has('rnpa_auth_request')) {
      // Header para req de autenticacao
      httpAuthHeaders = new HttpHeaders({
        'Authorization': this._authToken,
        'Content-Type': 'application/json',
        'client_id': '977e6bc9-2efa-4155-be34-b1a57a4b8119',
        'client_secret': 'a6aa3ef0-4cec-4766-aff0-b33bbfa844e5'
      })

      modifiedReq = request.clone({ headers: httpAuthHeaders })
      return next.handle(modifiedReq);
    }

    // Verifica se é uma chamada RNPA para usar o Bearer customizado
    if (!request.headers.has('rnpa_req')) {
      return next.handle(request)
    }

    if (localStorage.getItem(LocalStorageEnum.TokenSensedia)) {
      this.getCustomHeader(request, next)
    }

    // Caso não possua token, deve requisitar o token e chamar a requisição dnv para passar pelo interceptor
    return this.getCustomHeader(request, next)
  }

  getCustomHeader(request: HttpRequest<any>, next: HttpHandler) {
    const token = localStorage.getItem(LocalStorageEnum.TokenSensedia)
    const httpAuthHeaders = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'associate': this.authenticationService.currentUserValue.nome_fantasia || 'N/A',
      'Content-Disposition': request.headers.has('FormData') ? 'multipart/form-data' :  request.headers.has('Text') ? 'application/octet-stream' : 'application/json',
    })

    const modifiedReq = request.clone({ headers: httpAuthHeaders });
    return next.handle(modifiedReq);
  }
}
