import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';

@Pipe({
    name: "sortDate"
})
export class ArraySortDatePipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (field) {
              
               let dataA = this.parse(a[field]);
               let dataB = this.parse(b[field]);

                if (dataA > dataB) {
                    return -1;
                } else if (dataA < dataB) {
                    return 1;
                } else {
                    return 0;
                }
            }
            else {
                return 0;
            }
        });
        return array;
    }

    parse(val){
        let data:any= '';
        if (Object.keys(val).length === 0 && val.constructor === Object)
        data = '';

        if (val.indexOf('/') == -1 && moment(val, 'YYYY-MM-DD HH:mm').isValid()) {
            if (moment(val, 'YYYY-MM-DD HH:mm').hours() > 0)
            val = moment(val, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
            else
            val = moment(val, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY');
            data = val;
        }

        if (val.toString().length == 8) {
            val = `${val.substring(0, 2)}/${val.substring(2, 4)}/${val.substring(4, 8)}`;
        }

        if (val.toString().indexOf('/')) {
            if (moment(val, 'DD/MM/YYYY').isValid())
            data = moment(val, 'DD/MM/YYYY').toDate();
            if (moment(val, 'YYYY/MM/DD').isValid())
            data = moment(val, 'YYYY/MM/DD').toDate();
            if (moment(val, 'DD-MM-YYYY').isValid())
            data = moment(val, 'DD-MM-YYYY').toDate();
            if (moment(val, 'YYYY-MM-DD').isValid())
            data = moment(val, 'YYYY-MM-DD').toDate();
        }

        return data;
    }
}