import { FormControl } from "@angular/forms";
const CPF = require('cpf')
const CNPJ = require('cnpj')

export function cpfCnpjValidator(control: FormControl) {
    if (control.value != undefined && control.value != null && control.value != '') {

        let numbers = control.value.match(/\d/g);
        let numberLength = 0;
        if (numbers) {
            numberLength = numbers.join("").length;
        }
        if (numberLength > 11 && (control.value != null && control.value != undefined && control.value != '') && !CNPJ.validate(control.value))
            return {
                cnpj: true
            }
        else
            if (numberLength <= 11 && (control.value != null && control.value != undefined && control.value != '') && !CPF.isValid(control.value))
                return {
                    cpf: true
                }
    }
    return null;
}
