import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

const routes = {
  urlVerify: (token: any) => `auth/siteverify/${token}`,
}

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  constructor(private apiService: ApiService) { }

  getResult(token: any) {
    return this.apiService.get<any>(routes.urlVerify(token));
  }
}
