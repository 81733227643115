import { cpfValidator } from './cpf-validator';
import { telefoneValidator } from './telefone-validator';
import { cnpjValidator } from './cnpj-validator';
import { nullValidator } from './null-validator';
import { contratoValidator } from './contrato-validator';
import { requiredFileType } from './file-type-validator';
import { imageSizeValidator } from './image-size-validator';
import { hostValidator } from './host-validator';
import { whiteSpace } from './white-space-validator';
import { cpfCnpjValidator } from './cpf-cnpj-validator';
import { cepValidator } from './cep-validator';
import { dataValidator } from './data-validator';
import { chassiValidator } from './chassi-validator';
import { placaValidator } from './placa-validator';
import { EmailValidator } from '@angular/forms';

export const CustomValidators = {
    cpf: cpfValidator,
    telefone: telefoneValidator,
    cnpj: cnpjValidator,
    nullValue: nullValidator,
    contrato: contratoValidator,
    fileType: requiredFileType,
    imageSize: imageSizeValidator,
    host:hostValidator,
    whiteSpace:whiteSpace,
    cpfCnpj:cpfCnpjValidator,
    cep:cepValidator,
    data:dataValidator,
    chassi:chassiValidator,
    placa:placaValidator,
    email: EmailValidator,
}