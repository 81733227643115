import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Injectable, OnInit, AfterViewInit, Inject } from '@angular/core';
import { DynamicContentComponent } from '@shared/components/dynamic-content/dynamic-content.component';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {

  constructor() { }

  generateCustomContent(dynamicConteinerComponent: DynamicContentComponent, template: string, objRetornoServico: any) {

    let replaceTemplate = template.replace(new RegExp("''", 'g'), "'");
    dynamicConteinerComponent.compileTemplate(replaceTemplate, class RuntimeComponent implements OnInit, AfterViewInit {
      objServico: any = objRetornoServico;
      obj: any = {};
      dataLoaded: boolean = false;
      entity: any;
      env = environment;
      mostrarErro: boolean = false;

      ngOnInit() {

        if (Array.isArray(this.objServico))
          this.obj = this.objServico;
        else
          Object.keys(this.objServico).forEach((key) => {
            let newKey = key;
            if (key.split(':').length == 2)
              newKey = key.split(':')[1];
            this.obj[newKey] = this.objServico[key];
          })
      }

      isArray(obj: any): boolean {
        return Array.isArray(obj);
      }

      hasValue(obj: any): boolean {
        if (!obj)
          return false;

        if (obj == "null")
          return false;

        if (Object.keys(obj).length === 0 && obj.constructor === Object)
          return false;

        return obj;
      }

      initData(value) {
        if (!this.dataLoaded && !this.entity) {
          this.entity = value;
        }
      }

      ngAfterViewInit(): void {
        setTimeout(() => {
          if (this.entity)
            this.dataLoaded = true;
        }, 0)
      }

      decodeString(val: string) {
        try {
          if (val == '[object Object]')
            return '';

          if (!this.hasValue(val))
            return '';

          return decodeURIComponent(escape(val));
        } catch {
          return val;
        }
      }

      toArray(obj) {
        if (this.isArray(obj))
          return obj;

        if (this.hasValue(obj))
          return [obj];

        return [];
      }

      separarPor(obj1, obj2, separador): string {

        if (this.hasValue(obj1) && this.hasValue(obj2))
          return this.decodeString(`${obj1} ${separador} ${obj2}`);

        if (this.hasValue(obj1))
          return this.decodeString(`${obj1}`);

        if (this.hasValue(obj2))
          return this.decodeString(`${obj2}`);

        return '';
      }

      abreFoto(url) {
        window.open(url, "_blank", "toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=0, resizable=0, left=0, top=0, width=810, height=620");
      }

      openService(serviceName: string, operationName: string, queryString: string): void {

      }

      normalize(text: string) {
        if (text)
          return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        return text;
      }

      trim(text: string) {
        if (text)
          return text.replace(new RegExp(' ', 'g'), '');

        return text;
      }


      onScroll(scrollData: any[], scrollFull: any[]) {

        if (scrollData.length == scrollFull.length)
          return;

        let start = scrollData.length;
        let end = start + 10;
        if (end > scrollFull.length)
          end = scrollFull.length;

        for (let i = start; i < end; i++) {
          scrollData.push(scrollFull[i]);
        }
      }

      downloadArquivoComToken(url: string) {
        const token = JSON.parse(localStorage.getItem('PORTAL-EV-token')).replace(/^["']|["']$/g, '')
        const partes = url.split('=');
        const nomeArquivo = partes[partes.length - 1];
        const setEnvUrl =  environment.consulta + url.split('.br')[1]

        fetch(setEnvUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then(response => {
            if (!response.ok) {
              this.mostrarErro = true;
              throw new Error('Não foi possível baixar o arquivo.');
            }
            return response.blob();
          })
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = nomeArquivo;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  }
}
