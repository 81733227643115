import { Component } from '@angular/core';
import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-boolean-result',
  templateUrl: './boolean-result.component.html',
  styleUrls: ['./boolean-result.component.scss']
})
export class BooleanResultComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  get value() {
    if (this.config.value == 0)
      return 'Não';

    if (this.config.value == 'N')
      return 'Não';

    if (this.config.value == 1)
      return 'Sim';

    if (this.config.value == 'S')
      return 'Sim';

    return this.config.value;
  }
}
