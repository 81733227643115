import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'brDateResult' })
export class BrDateResultPipe implements PipeTransform {
    transform(value: any): string {
        if (value) {


            if (Object.keys(value).length === 0 && value.constructor === Object)
                return '';

            if (value.indexOf('/') == -1 && moment(value, 'YYYY-MM-DD HH:mm').isValid()) {
                if (moment(value, 'YYYY-MM-DD HH:mm').hours() > 0)
                    value = moment(value, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
                else
                    value = moment(value, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY');
                return value;
            }

            if (value.toString().length == 8) {
                value = `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4, 8)}`;
            }

            if (value.toString().indexOf('/')) {
                if (moment(value, 'DD/MM/YYYY').isValid())
                    return moment(value, 'DD/MM/YYYY').format("DD/MM/YYYY");
                if (moment(value, 'YYYY/MM/DD').isValid())
                    return moment(value, 'YYYY/MM/DD').format("DD/MM/YYYY");
                if (moment(value, 'DD-MM-YYYY').isValid())
                    return moment(value, 'DD-MM-YYYY').format("DD/MM/YYYY");
                if (moment(value, 'YYYY-MM-DD').isValid())
                    return moment(value, 'YYYY-MM-DD').format("DD/MM/YYYY");
            }

        }

        if (Object.keys(value).length === 0 && value.constructor === Object)
            return '';

        return value;
    }
}