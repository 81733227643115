import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(private modal: NgbActiveModal) { }
  data: any;

  ngOnInit() {

  }

  ok(): void {
    this.modal.close(true);
  }

  cancel(): void {
    this.modal.close(false);
  }
}
