export const environment = {
  production: false,
  i18nPrefix: '',
  //apiGatewayDns: 'https://api-s.cnseg.org.br',
  api: 'https://api-hmlg.cnseg.org.br/portalservicos',
  lote:'https://api-hmlg.cnseg.org.br/lote',
  consulta:'https://api-hmlg.cnseg.org.br',
  apiGatewayDns: 'https://api-dev.cnseg.org.br',
  apiwso2: 'https://api-hmlg.cnseg.org.br',
  auth: 'auth',
  mock: false,
  version: require('../../package.json').version,
  urlFinanceiro: 'http://financeiro.cnseg.org.br/connect/',
  urlCentralAtendimento: 'https://atendimento.cnseg.org.br/Portal/',
  urlProtecaoDados: 'https://fenaseg-privacy.my.onetrust.com/webform/d1c50a38-ed47-4b75-95f4-c6bd2d3c5541/0aaa86dc-0b3c-4205-aff7-4e3eace440ea',
  refreshToken: 'http://api-hmlg.cnseg.org.br/login/1.0.0/refresh-token',
  tempoAcesso:60,
  tempoRestanteRefreshToken:10,
  urlSistema:'https://api-hmlg.cnseg.org.br',
  apiRNSPessoas:'http://10.11.30.65:8280/rnspessoas',
  apiRNSCartaoProtegido:'https://solucoes-hmlg.cnseg.org.br/riscosdiversos/1.0.0',
  apiCoincidenciasRNS:'http://10.11.30.65:8280/consultaservico/1.0.0',
  apiSccp: 'https://solucoes-hmlg.cnseg.org.br/sccp/1.0.0',
  apiSccpBatch: 'https://solucoes-hmlg.cnseg.org.br/sccpapi/1.0.0',
  apiRnsPessoasBatch: 'https://api-hmlg.cnseg.org.br/rnspessoas/1.0.0',
  painelBordo: 'https://api-hmlg.cnseg.org.br/paineldebordo/consulta',
  recaptcha: {
    siteKey: '6LdFUHAjAAAAAMlApD-C5eAVR1ZWwybunkaYwaWS',
  }
};