import { Injectable } from '@angular/core';
import moment from 'moment';
import { ELocalStorage, EStorage } from '../enums/storage.enum';

interface StorageReturn {
  value: any,
  expire?: string,
}

interface StorageData {
  storageKey: EStorage;
  storage?: Storage;
  storageName?: string;
}

interface StorageDataSet extends StorageData {
  data: any,
  expire?: Date,
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get({
    storageKey,
    storage = localStorage,
    storageName
  }: StorageData): StorageReturn | undefined {
    const value = storage.getItem(storageName || ELocalStorage.STORAGE);
    if (!value) return;
    const objects = JSON.parse(value);
    const object = objects[storageKey];
    if(this.isObjectEmpty(object)){
      return undefined
    }
    if (object && moment().isAfter(object['expire'])) {
      this.remove({ storageKey });
      return undefined;
    }
    return object;
  }

  set({
    storageKey,
    data,
    expire,
    storage = localStorage,
    storageName
  }: StorageDataSet
  ): void {
    const value = storage.getItem(storageName || ELocalStorage.STORAGE);
    const objects = value ? JSON.parse(value) : {};
    const payload = Object.assign(objects, {
      [storageKey]: {
        value: data,
        expire: expire ? expire.toISOString() : undefined,
      },
    });
    storage.setItem(ELocalStorage.STORAGE, JSON.stringify(payload));
  }

  remove({ storageKey, storage = localStorage, storageName }: StorageData): void {
    const value = storage.getItem(storageName || ELocalStorage.STORAGE);
    const objects = value ? JSON.parse(value) : {};
    const payload = Object.assign(objects);
    delete payload[storageKey];
    storage.setItem(ELocalStorage.STORAGE, JSON.stringify(payload));
  }

  private isObjectEmpty(obj: object) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }
}