import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '@shared/components/input-dialog/input-dialog.component';
import { BadRequestDialogComponent } from '@shared/components/bad-request-dialog/bad-request-dialog.component';
import { HttpResponseValidation } from '@core/http-interceptors/http-error';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ConfirmSinobProcessamentoDialogComponent } from '@shared/components/confirm-sinob-processamento-dialog/confirm-sinob-processamento-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private dialog: NgbModal,
    private authenticationService:AuthenticationService) { }

  confirm(message: string, callback: ((value: any) => void), title?: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      centered: true,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      backdropClass:'modal-backdrop-message-dialog'
    });
    dialogRef.componentInstance.data = {
      title: title,
      message: message,
    }
    dialogRef.result.then(callback);
  }

  inputDialog(message: string, action: string, callback: ((value: any) => void)) {
    const dialogRef = this.dialog.open(InputDialogComponent, {
      centered: true,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      backdropClass:'modal-backdrop-message-dialog'
    });
    dialogRef.componentInstance.data = {
      action: action,
      message: message
    }

    dialogRef.result.then(callback);
  }

  confirmPeriodoProcessamentoDialog(message: string, callback: ((value: any) => void)) {
    const dialogRef = this.dialog.open(ConfirmSinobProcessamentoDialogComponent, {
      centered: true,
      beforeDismiss: () => {
        return this.authenticationService.currentUserValue == null;
      },
      backdropClass:'modal-backdrop-message-dialog'
    });
    dialogRef.componentInstance.data = {
      message: message
    }

    dialogRef.result.then(callback);
  }
}
