import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RnpaRestInterface } from './rnpa-rest-interface.service';
import { TipoPropostaApoliceEnum } from '../enumerators/types';
import { ProposalStatusEnum } from '../enumerators';
import { PropostaVidaGrupo, PropostaVidaIndividual } from '../models';

interface ISearchParams {
  numeroDocumento: string,
  motivoRecusaId: string,
  tipoCadastro: string,
  dataCriacaoInicio: Date,
  dataCriacaoFim: Date,
  status: ProposalStatusEnum,
  page: number,
  limit: number,
  sort: string,
  sortType: 'ASC' | 'DESC'
}

@Injectable({
  providedIn: 'root'
})

export class PropostaService extends RnpaRestInterface {

  consultaUrl = environment.apiGatewayDns

  private _propostasViUrl = '/rnpa-pessoas/v1/propostas-apolices/propostas/vida-individual'
  private _propostasVgUrl = '/rnpa-pessoas/v1/propostas-apolices/propostas/vida-grupo'
  private _propostasUrl = '/rnpa-pessoas/v1/propostas-apolices/propostas'
  private _analiseRiscoUrl = '/rnpa-pessoas/v1/propostas-apolices/propostas/risco-mercado'

  private _motivoRecusaUrl = '/rnpa-pessoas/v1/motivos-recusa'

  constructor(
    public http: HttpClient
  ) {
    super(http)
  }

  // POST proposta
  createProposta(body, type: TipoPropostaApoliceEnum) {
    const methodUrl = type == TipoPropostaApoliceEnum.Vi ? this._propostasViUrl : this._propostasVgUrl
    const url = this.consultaUrl + methodUrl
    return this.post(url, body.Serialize())
  }

  // PATCH proposta
  atualizarProposta(body: PropostaVidaIndividual | PropostaVidaGrupo) {
    let methodUrl = body.tipoPropostaApolice == TipoPropostaApoliceEnum.Vi ? this._propostasViUrl : this._propostasVgUrl
    const url = this.consultaUrl + methodUrl + `/${body.id}`
    const serializedBody = body.tipoPropostaApolice == TipoPropostaApoliceEnum.Vi ? (new PropostaVidaIndividual(body).SerializePatch()) : (new PropostaVidaGrupo(body).SerializePatch())

    return this.patch(url, serializedBody)
  }

  // GET propostas
  listarPropostas(searchParams: ISearchParams) {
    let appendedParams = new HttpParams()
    for (let param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        let serializedParam

        if (param == 'numeroDocumento')
          serializedParam = searchParams[param].replace(/[-./]/g, '')

        appendedParams = appendedParams.set(param, serializedParam || searchParams[param].toString())
      }
    }

    const url = this.consultaUrl + this._propostasUrl
    return this.getFullResponse(url, appendedParams)
  }

  // GET motivo recusa
  listarMotivoRecusa() {
    const url = this.consultaUrl + this._motivoRecusaUrl
    return this.get(url)
  }

  // POST Analise de Risco
  consultarAnaliseRisco(body: {numeroProposta: string, documento: string}){
    const url = this.consultaUrl + this._analiseRiscoUrl
    return this.post(url, body)
  }
}