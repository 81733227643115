import { Injectable } from '@angular/core';
import { FuncionalidadeEnum } from '@core/models/funcionalidade.models';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

constructor() { }

  menuBootstrap() {
    return of(
      [
        {
          id: 1,
          nome: 'Automóvel',
          icone: 'uil uil-car-sideview',
          rotaRamo: '/dashboard/1/produtos',
          subItens: [
            {
              nome: 'Combo Veículo',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta por Placa',
                      rota: ['/consulta/73/880/consultaVeiculoComboPlus/ConsultaVeiculoComboPlusPlaca']
                    },
                    {
                      nome: 'Consulta por Chassi',
                      rota: ['/consulta/73/880/consultaVeiculoComboPlus/ConsultaVeiculoComboPlusChassi']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'EV Básico',
              linkExterno: 'https://enquadramentoveiculo.cnseg.org.br/'
            },
            {
              nome: 'Base Licenciamento',
              rota: ['/consulta/57/766/consultabaselicenciamento/consultaChassiPlaca'],
              breadCrumb: {
                itemPrincipal: 'Automóvel',
                rota: '/dashboard/1/produtos'
              }
            },
            {
              nome: 'BVD',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta por Placa',
                      rota: ['/consulta/11/243/consultabvs/ConsultaPlaca']
                    },
                    {
                      nome: 'Consulta por Chassi',
                      rota: ['/consulta/11/242/consultabvs/ConsultaChassi']
                    },
                    {
                      nome: 'Consulta por Motor',
                      rota: ['/consulta/11/244/consultabvs/ConsultaMotor']
                    },
                    {
                      nome: 'Consulta por Caixa de Câmbio',
                      rota: ['/consulta/11/245/consultabvs/ConsultaCxCambio']
                    },
                    {
                      nome: 'Consulta por Renavam',
                      rota: ['/consulta/11/246/consultabvs/ConsultaRenavam']
                    },
                    {
                      nome: 'Consulta por Base de Agregados',
                      rota: ['/consulta/11/684/consultabvs/ConsultaMotorRenavam']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'Central de Bônus',
              linkExterno: 'https://centralbonus.ceser.org.br/FWSCB_PRD/'
            },
            {
              nome: 'DPVAT Bilhetes',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta por Placa E Período',
                      rota: ['/consulta/6/220/consultadpvat/BuscaPlaca']
                    },
                    {
                      nome: 'Consulta por Chassi E Período',
                      rota: ['/consulta/6/221/consultadpvat/BuscaChassi']
                    },
                    {
                      nome: 'Consulta por Placa',
                      rota: ['/consulta/6/222/consultadpvat/BuscaPlacaSemPeriodo']
                    },
                    {
                      nome: 'Consulta por Chassi',
                      rota: ['/consulta/6/224/consultadpvat/BuscaChassiSemPeriodo']
                    },
                    {
                      nome: 'Consulta por CNPJ/CPF e Período',
                      rota: ['/consulta/6/225/consultadpvat/BuscaCnpjCpf']
                    },
                    {
                      nome: 'Consulta por CNPJ/CPF',
                      rota: ['/consulta/6/226/consultadpvat/BuscaCnpjCpfSemPeriodo']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'Marca / Modelo',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta Código Marca/Modelo',
                      rota: ['/consulta/39/553/consultamarcamodelo/ConsultaCodigoMarcaModelo']
                    },
                    {
                      nome: 'Consulta Código por Marca',
                      rota: ['/consulta/39/554/consultamarcamodelo/ConsultaModeloPorMarca']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'Recall',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta Recall Chassi',
                      rota: ['/consulta/38/913/consultavin/consultaRecallChassi']
                    },
                    {
                      nome: 'Consulta Recall Placa',
                      rota: ['/consulta/38/914/consultavin/consultaRecallPlaca']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'RENACH',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta por Número CNH',
                      rota: ['/consulta/8/234/consultarenach/ConsultaRenach552']
                    },
                    {
                      nome: 'Consulta por CPF',
                      rota: ['/consulta/8/236/consultarenach/ConsultaRenach555']
                    },
                    {
                      nome: 'Consulta Condutor CNH Completo',
                      rota: ['/consulta/8/799/consultarenach/ConsultaCondutorCNHCompleto']
                    },
                    {
                      nome: 'Consulta Condutor CPF Completo',
                      rota: ['/consulta/8/800/consultarenach/ConsultaCondutorCPFCompleto']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'BDRF',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Busca Placa',
                      rota: ['/consulta/2/210/consultabdrf/BuscaPlaca']
                    },
                    {
                      nome: 'Busca Chassi',
                      rota: ['/consulta/2/211/consultabdrf/BuscaChassi']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'BDSII',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Busca Placa',
                      rota: ['/consulta/1/205/consultabdsii/BuscaPlaca']
                    },
                    {
                      nome: 'Busca Chassi',
                      rota: ['/consulta/1/206/consultabdsii/BuscaChassi']
                    },
                    {
                      nome: 'Busca Placa/Chassi',
                      rota: ['/consulta/1/207/consultabdsii/BuscaPlacaChassi']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'Painel de Bordo',
              subItens: [
                {
                  nome: 'Frota Nacional',
                  breadCrumb: {
                    itemPrincipal: 'Automóvel',
                    rota: '/dashboard/1/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Frota Emplacada',
                      rota: ['/dashboard']
                    },
                    {
                      nome: 'Marcas',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Região',
                      rota: ['/tipo-empresa']
                    }
                  ]
                },
                {
                  nome: 'Roubo e Furto',
                  subItens: [
                    {
                      nome: 'Ocorrência',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Marcas',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Região',
                      rota: ['/tipo-empresa']
                    }
                  ]
                },
                {
                  nome: 'Perfil de Seguros',
                  subItens: [
                    {
                      nome: 'Geral',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Marcas',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Região',
                      rota: ['/tipo-empresa']
                    }
                  ]
                },
                {
                  nome: 'Sinistros',
                  subItens: [
                    {
                      nome: 'Ramo / Natureza',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Lorem ipsum',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Lorem ipsum',
                      rota: ['/tipo-empresa']
                    },
                    {
                      nome: 'Lorem ipsum',
                      rota: ['/tipo-empresa']
                    }
                  ]
                }
              ]
            },
          ]
        },
        {
          id: 2,
          nome: 'Habitacional',
          icone: null,
          rotaRamo: 'dashboard/2/produtos',
          svg: 'habitacional',
          subItens: [
            {
              nome: 'BAJ',
              subItens: [
                {
                  nome: null,
                  breadCrumb: {
                    itemPrincipal: 'Habitacional',
                    rota: '/dashboard/2/produtos'
                  },
                  subItens: [
                    {
                      nome: 'Consulta Pendências',
                      rota: ['/consulta/52/726/consultabaj/consultaPendencias']
                    },
                    {
                      nome: 'Consulta Processos',
                      rota: ['/consulta/52/727/consultabaj/consultaProcessos']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'RNS',
              linkExterno: 'https://rns.cnseg.org.br/RegistroNacionalSinistroWeb/login.jsp'
            },
          ]
        },
        {
          id: 3,
          nome: 'Multiramo',
          icone: 'uil uil-file-shield-alt',
          subItens: [
            {
              nome: 'Experiência em Riscos Diversos',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta de Sinistros por CNPJ/CPF do segurado',
                      rota: ['/consulta/76/909/ExperienciaEmRiscosDiversos/consultaSinistroExperienciaEmRiscosDiversos']
                    },
                    {
                      nome: 'Cadastro de Sinistro',
                      rota: ['/consulta/76/910/ExperienciaEmRiscosDiversos/cadastroSinistroExperienciaEmRiscosDiversos']
                    },
                    {
                      nome: 'Processamento Batch',
                      rota: ['/consulta/76/911/ExperienciaEmRiscosDiversos/batchExperienciaRiscosDiversos']
                    },
                    {
                      nome: 'Consulta Coincidencia',
                      rota: ['/consulta/76/923/ExperienciaEmRiscosDiversos/consultaCoincidenciaRiscosDiversos']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'CORREIOS',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta CEP',
                      rota: ['/consulta/5/218/consultaCORREIOS/consultaCEP']
                    },
                    {
                      nome: 'Consulta Logradouro/Uf',
                      rota: ['/consulta/5/219/consultaCORREIOS/consultaLogradouroUf']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'SIBLOQ',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta Telefone Bloqueado',
                      rota: ['/consulta/42/581/consultasibloq/consultaBloqueio']
                    },
                    {
                      nome: 'Download Telefones Bloqueados',
                      rota: ['/consulta/42/582/consultasibloq/consultaTodosBloqueados']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'SICON',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta por CNPJ/CPF',
                      rota: ['/consulta/7/231/consultasicon/CpfCnpj']
                    },
                    {
                      nome: 'Consulta por CNPJ/CPF Detalhado',
                      rota: ['/consulta/7/541/consultasicon/CpfCnpjDetalhado']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'SIPLAV DCF + PEP',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta por CNPJ',
                      rota: ['/consulta/44/691/consultasiplav/buscaPpePorCnpj']
                    },
                    {
                      nome: 'Consulta por CPF',
                      rota: ['/consulta/44/692/consultasiplav/buscaPpePorCpf']
                    },
                    {
                      nome: 'Detalhamento Pep',
                      rota: ['/consulta/44/848/consultasiplav/detalhamentoPep']
                    },
                    {
                      nome: 'Relacionamento Cpf Pep',
                      rota: ['/consulta/44/849/consultasiplav/relacionamentoCpfPep']
                    },
                    {
                      nome: 'Consulta por CNPJ Plus',
                      rota: ['/consulta/44/888/consultasiplav/buscaPpePorCnpjPlus']
                    },
                  ]
                }
              ]
            },
            {
              nome: 'SIPLAV-DCF',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta por CNPJ',
                      rota: ['/consulta/14/250/consultasiplav/buscaCnpj']
                    },
                    {
                      nome: 'Consulta por CPF',
                      rota: ['/consulta/14/251/consultasiplav/buscaCpf']
                    },
                  ]
                }
              ]
            },
          ]
        },
        {
          id: 4,
          nome: 'Saúde',
          rota: ['dashboard/4/produtos'],
          icone: 'uil uil-medical-square',
          subItens: [
            {
              nome: 'SCCP',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta SCCP',
                      rota: ['/consulta/79/915/SCCP/consultaSCCP']
                    },
                    {
                      nome: 'Cadastro SCCP',
                      rota: ['/consulta/79/916/SCCP/cadastroSCCP']
                    },
                    {
                      nome: 'Processamento Batch',
                      rota: ['/consulta/79/924/SCCP/batchSCCP']
                    },
                    {
                      nome: 'Relatorios SCCP',
                      rota: ['/consulta/79/926/SCCP/relatoriosSCCP']
                    },
                  ]
                },
                {
                  nome: 'RNS',
                  linkExterno: 'https://rns.cnseg.org.br/RegistroNacionalSinistroWeb/login.jsp'
                }
              ]
            }
          ]
        },
        {
          id: 5,
          nome: 'Previdência',
          rota: ['dashboard/5/produtos'],
          icone: null,
          svg: 'previdencia',
          subItens: [
            {
              nome: 'SINOB',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Solicitar Requisição',
                      rota: ['/consulta/30/282/consultasinob/SolicitaRequisicao']
                    },
                    {
                      nome: 'Consulta Resultado Múltiplo',
                      rota: ['/consulta/30/283/consultasinob/ConsultaResultadoMultiplo']
                    },
                    {
                      nome: 'Consulta Requisição Cliente',
                      rota: ['/consulta/30/284/consultasinob/ConsultaReqCliente']
                    },
                    {
                      nome: 'Consulta Por CPF',
                      rota: ['/consulta/30/878/consultasinob/ConsultaCpfOnline']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'RNS',
              linkExterno: 'https://rns.cnseg.org.br/RegistroNacionalSinistroWeb/login.jsp'
            }
          ]
        },
        {
          id: 6,
          nome: 'Vida',
          rota: ['dashboard/6/produtos'],
          icone: 'uil uil-heartbeat',
          subItens: [
            {
              nome: 'RNS',
              linkExterno: 'https://rns.cnseg.org.br/RegistroNacionalSinistroWeb/login.jsp'
            },
            {
              nome: 'RNS Pessoas',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta de Sinistros',
                      rota: ['/consulta/74/896/RNSPessoas/consultaSinistroRNSPessoas']
                    },
                    {
                      nome: 'Consulta Coincidencia',
                      rota: ['/consulta/74/905/RNSPessoas/consultaCoincidenciaRNSPessoas']
                    },
                    {
                      nome: 'Cadastro Sinistro',
                      rota: ['/consulta/74/907/RNSPessoas/cadastroSinistroRNSPessoas']
                    },
                    {
                      nome: 'Relatorios RNSPessoas',
                      rota: ['/consulta/74/908/RNSPessoas/consultaRelatoriosRNSPessoas']
                    },
                    {
                      nome: 'Processamento Batch',
                      rota: ['/consulta/74/925/RNSPessoas/batchRNSPessoas']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'SINOB',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Solicitar Requisição',
                      rota: ['/consulta/30/282/consultasinob/SolicitaRequisicao']
                    },
                    {
                      nome: 'Consulta Resultado Múltiplo',
                      rota: ['/consulta/30/283/consultasinob/ConsultaResultadoMultiplo']
                    },
                    {
                      nome: 'Consulta Requisição Cliente',
                      rota: ['/consulta/30/284/consultasinob/ConsultaReqCliente']
                    },
                    {
                      nome: 'Consulta Por CPF',
                      rota: ['/consulta/30/878/consultasinob/ConsultaCpfOnline']
                    }
                  ]
                }
              ]
            },
            {
              nome: 'SIVVE',
              subItens: [
                {
                  nome: null,
                  subItens: [
                    {
                      nome: 'Consulta Por CPF',
                      rota: ['/consulta/53/745/consultaSIVVE/consultaPorCpf']
                    },
                    {
                      nome: 'Consulta Por CPF e Data',
                      rota: ['/consulta/53/746/consultaSIVVE/consultaPorCpfData']
                    },
                    {
                      nome: 'Consulta Por CPF com Sicon',
                      rota: ['/consulta/53/747/consultaSIVVE/consultaPorCpfSicon']
                    },
                    {
                      nome: 'Consulta Por CNPJ Resumido',
                      rota: ['/consulta/53/749/consultaSIVVE/consultaPorCnpjResumido']
                    },
                    {
                      nome: 'Consulta Por CPF, CNPJ e Data',
                      rota: ['/consulta/53/752/consultaSIVVE/consultaPorCpfCnpjData']
                    },
                    {
                      nome: 'Consulta CNPJ Especial',
                      rota: ['/consulta/53/867/consultaSIVVE/consultacnpjespecial']
                    },
                    {
                      nome: 'Consulta Por CPF Plus',
                      rota: ['/consulta/53/876/consultaSIVVE/consultaPorCpfPlus']
                    },
                    {
                      nome: 'Consulta Por CNPJ Plus',
                      rota: ['/consulta/53/877/consultaSIVVE/consultaPorCnpjPlus']
                    },
                    {
                      nome: 'Consulta CNPJ - Quadro Pessoal',
                      rota: ['/consulta/53/890/consultaSIVVE/consultaQuadroPessoalCnpjV2']
                    },
                    {
                      nome: 'Consulta Avançada',
                      rota: ['/consulta/53/898/consultaSIVVE/SIVVEDinamicoContagem']
                    },

                  ]
                }
              ]
            },
          ]
        }
      ]
    )
  }

  menuUsuarioConfiguracao() {
    return of(
      [
        {
          nome: 'Usuários',
          rota: ['/usuarios'],
          icone: 'uil uil-users-alt',
          permissao: [FuncionalidadeEnum.ManterUsuario]
        },
        {
          nome: 'Empresas',
          rota: ['/empresas'],
          icone: 'uil uil-building',
          permissao: [FuncionalidadeEnum.ManterEmpresa]
        },
        {
          nome: 'Contratos',
          rota: ['/contratos'],
          icone: 'uil uil-file-edit-alt',
          permissao: [FuncionalidadeEnum.ManterContrato]
        },
        {
          nome: 'Cadastros',
          icone: 'uil uil-edit',
          permissao: [
            FuncionalidadeEnum.ManterFuncionalidade,
            FuncionalidadeEnum.ManterGrupoEconomico,
            FuncionalidadeEnum.ManterProduto,
            FuncionalidadeEnum.ManterParametroPortal,
            FuncionalidadeEnum.ManterRamo,
            FuncionalidadeEnum.ManterTipoContrato,
            FuncionalidadeEnum.ManterTipoEmpresa,
            FuncionalidadeEnum.ManterTipoProduto
          ],
          subItens: [
            {
              nome: 'Tipo Empresa',
              rota: ['/tipo-empresa'],
              permissao: [FuncionalidadeEnum.ManterTipoEmpresa]
            },
            {
              nome: 'Tipo Produto',
              rota: ['/tipo-produto'],
              permissao: [FuncionalidadeEnum.ManterTipoProduto]
            },
            {
              nome: 'Tipo Contrato',
              rota: ['/tipo-contrato'],
              permissao: [FuncionalidadeEnum.ManterTipoContrato]
            },
            {
              nome: 'Ramo',
              rota: ['/ramo'],
              permissao: [FuncionalidadeEnum.ManterRamo]
            },
            {
              nome: 'Grupo Econômico',
              rota: ['/grupo-economico'],
              permissao: [FuncionalidadeEnum.ManterGrupoEconomico]
            },
            {
              nome: 'Funcionalidade',
              rota: ['/funcionalidade'],
              permissao: [FuncionalidadeEnum.ManterFuncionalidade]
            },
            {
              nome: 'Parâmetros Portal',
              rota: ['/parametros-portal'],
              permissao: [FuncionalidadeEnum.ManterParametroPortal]
            },
            {
              nome: 'Produtos',
              rota: ['/produto'],
              permissao: [FuncionalidadeEnum.ManterProduto],
              subItens: [
                {
                  nome: 'Tipo de Produto',
                  rota: ['/cadastro/tipo-produto'],
                },
                {
                  nome: 'Grupos, Ramos e Coberturas',
                  rota: ['/cadastro/ramos-coberturas'],
                },
                {
                  nome: 'Motivos de Recusa',
                  rota: ['/cadastro/motivos-recusa'],
                },
              ]
            }
          ]
        },
        {
          nome: 'Relatórios',
          icone: 'uil uil-invoice',
          permissao: [FuncionalidadeEnum.RelatorioConsulta, FuncionalidadeEnum.RelatorioContrato],
          subItens: [
            {
              nome: 'Cadastros',
              rota: ['/relatorio/cadastro'],
              permissao: [FuncionalidadeEnum.RelatorioContrato]
            },
            {
              nome: 'Contratos',
              rota: ['/relatorio/contrato'],
              permissao: [FuncionalidadeEnum.RelatorioContrato]
            },
            {
              nome: 'Consultas',
              rota: ['/relatorio/consulta'],
              permissao: [FuncionalidadeEnum.RelatorioConsulta]
            },
            {
              nome: 'Consultas por Protocolo',
              rota: ['/relatorio/consulta-por-protocolo']
            },
            {
              nome: 'Informações',
              rota: ['/relatorio/informacoes'],
            }
          ]
        },
        {
          nome: "Manuais",
          icone: 'uil uil-books',
          rota: ['/manual']
        },
        {
          nome: "Market Share e Metas",
          icone: 'uil uil-hunting',
          rota: ['/market-share']
        },
        {
          nome: 'Faturamento',
          rota: ['/faturamento'],
          icone: 'uil  uil-money-stack',
          permissao: [FuncionalidadeEnum.ManterContrato]
        },
      ]
    )
  }
}
