import { MatPageable } from '@core/table/mat-table';

export class ParametroMetodoCombo {
    id: string;
    nome: string;
    valor: string;
}

export class ParametroMetodoElement {
    id: string;
    ativo: string;
    tipo: string;
    paginacao?: MatPageable;
}

export class ParametroMetodo {
    id: number;
    idMetodo: number;
    nomeParametroPortal: string;
    idTipoParametro: string;
    idTipoDado: number;
    indObrigatorio: string;
    numOrdemExibicao: string;
    ativo: string;
    idParametro: number;
    nomeParametroServico: string;
    excluido: boolean;
    numOrdemTree:string;
    style:string;
    visivel:number;
    nomeParametroServicoPai: string;
    namespace: string;
    constructor() {
    }
}

export class FiltroParametroMetodo {
    idMetodo: number;
}

export class TipoParametroMetodo {
    id: string;
    nome: string;
}

export enum TipoParametroMetodoEnum {
    Entrada = '1',
    Saida = '2',
    Erro = '3'
}


export class ParametroMetodoCrud {
    id: number;
    idMetodo: number;
    nomeParametroPortal: string;
    idTipoParametro: string;
    idTipoDado: number;
    indObrigatorio: boolean;
    numOrdemExibicao: string;
    ativo: boolean;
    idParametro: number;
    nomeParametroServico: string;
    excluido: boolean;
    numOrdemTree:string;
    style:string;
    visivel:boolean;
    nomeParametroServicoPai: string;
    namespace: string;
    filhos:ParametroMetodoCrud[];
    dominio:string;
    obrigatorio:boolean;
    constructor() {
    }
}