import { Component, OnInit } from '@angular/core';
import { BaseDialogComponent } from '@shared/components/base-dialog/base-dialog.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalhe-produto',
  templateUrl: './detalhe-produto.component.html',
  styleUrls: ['./detalhe-produto.component.scss']
})
export class DetalheProdutoComponent  extends BaseDialogComponent implements OnInit {

  descricao:string;
  constructor(modal: NgbActiveModal) {
    super(modal);
  }

  ngOnInit() {
  }
}
