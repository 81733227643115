import { Component, OnInit, HostListener } from '@angular/core';
import { InputDialogData } from './input-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseDialogComponent } from '@shared/components/base-dialog/base-dialog.component';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent extends BaseDialogComponent implements OnInit {

  inputDialogForm: FormGroup;
  data: any;
  constructor(public dialog: NgbActiveModal,
    private formBuilder: FormBuilder) {
    super(dialog)
  }

  ngOnInit() {
    this.inputDialogForm = this.formBuilder.group({
      text: [, Validators.required],
    });
  }

  ok(): void {
    if (this.inputDialogForm.valid) {
      let data = <InputDialogData>this.inputDialogForm.value;
      this.dialog.close(data);
    }
  }

  close(): void {
    this.cancel();
  }
  
  cancel(): void {
    this.dialog.close(false);
  }

  @HostListener('blur') onBlur(event: KeyboardEvent) {
    this.cancel();
  }
}
