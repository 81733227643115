export class SivvePesquisaAvancadaConsulta {
    gruposFiltros: SivveGrupoPesquisa[];
}


export class SivvePesquisaAvancada {
    index?: number;
    id: number;
    nome: string;
    gruposFiltros: SivveGrupoPesquisa[];
    edicaoNome?: boolean;

    constructor(nome: string) {
        this.nome = nome;
        this.gruposFiltros = [];
    }
}

export class SivveGrupoPesquisa {
    nome: string;
    filtros?: SivveItemGrupoPesquisa[];
    expandido: boolean;
    edicaoNome: boolean;
    conectorGrupos: SivveConectorGrupoEnum;
    ordem: number;

    constructor(nome: string) {
        this.filtros = [];
        this.nome = nome;
        this.conectorGrupos = SivveConectorGrupoEnum.AND;
    }
}

export class SivveItemGrupoPesquisa {
    ordem: number;
    tipoFiltro: SivveTipoFiltroEnum;
    operadorLogico: SivveOperadorLogicoEnum;
    valor: any[];
    conectorFiltros: SivveConectorFiltroEnum;
    opcoes: any[];
}

export enum SivveTipoFiltroEnum {
    CNPJ = 'cnpj',
    RazaoSocial = 'razaoSocial',
    DataAbertura = 'dataAbertura',
    CNAEPrincipal = 'cnaePrincipal',
    CNAESecundario = 'cnaeSecundario',
    RamoAtividade = 'ramoAtividade',
    NaturezaJuridica = 'naturezaJuridica',
    UF = 'uf',
    Municipio = 'municipio',
    SituacaoCadastral = 'situacaoCadastral',
    OptanteSimei = 'optanteSimei',
    OptanteSimples = 'optanteSimples',
    QtdFuncionarios = 'qtdeFuncionarios',
    QtdFuncionariosGrupo = 'qtdeFuncionariosGrupo',
    FaturamentoEstimado = 'faturamentoEstimado',
    CEP = 'cep',
    Telefone = 'telefone',
    TelefoneRF = 'telefoneRf',
    Email = 'email',
    EmailRF = 'emailRf',
    Matriz = 'matriz'
}

export class SivveTipoFiltro {
    value: SivveTipoFiltroEnum;
    nome: string;
}

export enum SivveConectorGrupoEnum {
    AND = 'AND',
    OR = 'OR'
}

export class SivveConectorGrupo {
    value: SivveConectorGrupoEnum;
    nome: string;
}

export enum SivveConectorFiltroEnum {
    AND = 'AND',
    OR = 'OR'
}

export class SivveConectorFiltro {
    value: SivveConectorFiltroEnum;
    nome: string;
}

export enum SivveOperadorLogicoEnum {
    ComecaoCom = 'comecaCom',
    NaoContem = 'naoContem',
    Contem = 'contem',
    Diferente = 'diferente',
    Igual = 'igual',
    MaiorQue = 'maiorQue',
    MenorQue = 'menorQue',
    MaiorIgualQue = 'maiorIgualQue',
    MenorIgualQue = 'menorIgualQue',
    Entre = 'entre',
}

export class SivveOperadorLogico {
    value: SivveOperadorLogicoEnum;
    nome: string;
}

export class SivvePesquisaResultadoConsulta {
    content: {
        sucesso: boolean;
        msg: string;
        retorno: SivvePesquisaRetornoConsulta;
    }
    saldo: string;
    protocolo: string;
    codretorno: string;
    mensagem: string;
}

export class SivvePesquisaRetornoConsulta {
    id: number;
    amostra: SivvePesquisaAmostra[];
    contagem: {
        totalAtivas: number;
        totalAtivasExterior?: number;
        totalBaixadas: number;
        totalBaixadasExterior?:number;
        totalEmpresas: number;
        totalFuncionarios: number;
        totalInaptas?: number;
        totalNulas?: number;
        totalNulasExterior?: number;
        totalSuspensas: number;
        totalSuspensasExterior?: number;
    }
    contagemFaixaFaturamento: {
        faixas: SivvePesquisaFaixa[];
    }
    contagemFaixaFuncionarios: {
        faixas: SivvePesquisaFaixa[];
    }
    cnpjsNaoEncontrados:string[];

    constructor() {
        this.amostra = [];
        this.contagem = {
            totalAtivas: 0,
            totalBaixadas: 0,
            totalEmpresas: 0,
            totalFuncionarios:0,
            totalSuspensas:0
        }
        this.contagemFaixaFaturamento = {
            faixas: []
        }
        this.contagemFaixaFuncionarios = {
            faixas: []
        }
    }
}

export class SivvePesquisaAmostra {
    cnpj: string;
    dataAbertura: string;
    municipio: string;
    razao: string;
    uf: string;
}

export class SivvePesquisaFaixa {
    nome: string;
    quantidade: number;
}

export class SivveAtributoExportacao {
    value: SivveAtributoExportacaoEnum;
    nome: string;
    selected?: boolean;
}

export enum SivveAtributoExportacaoEnum {
    Dados = 'dados',
    Natureza = 'natureza',
    Situacao = 'situacao',
    Enderecos = 'enderecos',
    Matriz = 'matriz',
    CnaePrincipal = 'cnaePrincipal',
    Funcionarios = 'funcionarios',
    ExFuncionarios = 'exfuncionarios',
    DebitosPagamento = 'debitosPgfnDau',
    Sintegra = 'sintegra',
    SimplesNacional = 'simplesNacional',
    Cnaes = 'cnaes',
    Socios = 'socios',
    SociosJunta = 'sociosJunta',
    FaturamentoPresumido = 'faturamentoPresumido',
    QSAUnificado = 'qsaUnificado',
    ANTT = 'antt',
    EmpresasColigadas = 'empresasColigadas',
    Filiais = 'filiais',
    Telefones = 'telefones',
    TelefonesRF = 'telefonesRF',
    Emails = 'emails',
    EmailsRF = 'emailsRF',
    CrescimentoPorAno = 'crescimentoPorAno',
    DistribuicaoSalarial = 'distribuicaoSalarial',
    DistribuicaoGrauInstrucao = 'distribuicaoGrauInstrucao',
    DistribuicaoFaixaEtaria = 'distribuicaoFaixaEtaria',
    DistribuicaoFaixaSexo = 'distribuicaoFaixaSexo',
    CrescimentoPorAnoRais = 'crescimentoPorAnoRais',
    ResultadoUltimoExercicio = 'resultadoUltimoExercicio'
}

export class SivveExportarLote {
    idConsulta: number;
    qtdeRegistros: number;
    atributos: string[];
}

export class SivveConsultarStatusLote {
    idLote: number;
}

export class SivveResultadoConsultarLote {
    content: {
        msg: string;
        retorno: { idLote: number, status: SivveStatusLoteEnum, urlDownload: string }
        sucesso: boolean;
    }
    protocolo: string;
    saldo: string;
}

export enum SivveStatusLoteEnum {
    Processado = "Processado",
    Erro = "Error"
}

export class SivvePesquisaSalvar {
    id?: number;
    nome: string;
    json: {
        gruposFiltros: SivveGrupoPesquisa[];
    }
}

export class HistoricoSivvePesquisa {
    dataConsulta: Date;
    dataCriacaoLote: Date;
    id: number;
    idConsulta: number;
    idLote: number;
    idUsuario: number;
    requestConsulta: any;
    requestLote: any;
    teveDownload: boolean;
}
