export class ConfirmSinobProcessamentoDialogData{
    message:string;
    action:string;
    tempoProcessamento:number;
}

export interface NumerosTempoProcessamento {
  nome: string;
  valor: number;
}

export enum TempoProcessamentoEnum {
  Um = 1,
  Dois = 2,
  Tres = 3,
  Quatro = 4,
  Cinco = 5
}
