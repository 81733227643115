import { Injectable } from '@angular/core';
import * as html2canvas from 'html2canvas/dist/html2canvas.js'
import { LoaderService } from '@shared/components/loader/loader.service';
import { NotificationService } from './notification.service';
window['html2canvas'] = html2canvas
import * as FileSaver from 'file-saver';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private loaderService: LoaderService,
    private notificationService: NotificationService) { }

  htmlToPDF(elementId: string, pdfName: string, onClone?: (clone) => any) {
    this.loaderService.show('Gerando PDF...');
    this.beforeCanvas(elementId);
    html2canvas(document.getElementById(elementId), {
      onclone: function (clone) {
        clone.getElementById('headerPDF').style.display = 'block';
        clone.getElementById('footerPDF').style.display = 'block';
        if (onClone)
          onClone(clone);
        return true;
      }
    }).then(canvas => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      var doc = new jsPDF('p', 'mm', 'a4', true);
      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      doc.save(pdfName);
      this.afterCanvas(elementId);
      this.loaderService.hide();
    }).catch((error) => {
      console.log(error);
      this.afterCanvas(elementId);
      this.loaderService.hide();
      this.notificationService.error('Ocorreu um erro inesperado ao gerar o PDF. Você pode acionar o imprimir e salvar como PDF ou tentar novamente mais tarde.', 6000);
    });
  }

  beforeCanvas(elementId: string) {
    let shadowItens = document.getElementById(elementId).getElementsByClassName('box-shadow');
    if (shadowItens)
      for (let i = 0; i < shadowItens.length; i++) {
        shadowItens[i].classList.add('container-border-pdf');
      }

    let expandItens = document.getElementById(elementId).getElementsByClassName('expand');
    if (expandItens)
      for (let i = 0; i < expandItens.length; i++) {
        expandItens[i].classList.add('expanded-pdf')
      }
  }

  afterCanvas(elementId: string) {
    let shadowItens = document.getElementById(elementId).getElementsByClassName('box-shadow');
    if (shadowItens)
      for (let i = 0; i < shadowItens.length; i++) {
        shadowItens[i].classList.toggle('container-border-pdf');
      }

    let expandItens = document.getElementById(elementId).getElementsByClassName('expand');
    if (expandItens)
      for (let i = 0; i < expandItens.length; i++) {
        expandItens[i].classList.toggle('expanded-pdf')
      }
  }

  downloadImage(elementId: string, fileName: string, onFilter?: (node) => any) {

    domtoimage.toBlob(document.getElementById(elementId), {
      filter: (node) => {
        if (onFilter)
          return onFilter(node);
        return true;
      }
    }).then(blob => {
      FileSaver.saveAs(blob, `${fileName}.png`);
    }).catch((error) => {
      console.log(error);
      this.notificationService.error('Ocorreu um erro inesperado ao gerar o PDF. Você pode acionar o imprimir e salvar como PDF ou tentar novamente mais tarde.', 6000);
    });
  }
}
