import { ApoliceStatusEnum } from '../enumerators';
import { TipoCadastroEnum, TipoPropostaApoliceEnum } from '../enumerators/types';
import { IUsuarioCriacao, ResponsavelPagamento, Segurado, Cobertura, Beneficiario, Empresa } from './proposta';
import { IRest } from './rest.interface';

export class ApoliceVidaIndividual implements IRest {
    className = 'Classe de Apolice VI';

    id: string;
    associadaId: string;
    numeroApolice: string;
    numeroPropostaOrigem: string;
    status: ApoliceStatusEnum;
    dataEmissao: Date;
    dataAceiteProposta: Date;
    segurado: Segurado;
    coberturas: Cobertura[];
    beneficiarios: Beneficiario[];
    responsavelPagamento: ResponsavelPagamento;
    tipoProdutoId: string
    motivoRecusaId: string
    tipoCadastro: TipoCadastroEnum;
    tipoPropostaApolice: TipoPropostaApoliceEnum;
    usuarioCriacao: IUsuarioCriacao
    dataCriacao: Date
    dataVigencia: Date
    dataCancelamento: Date

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            numeroApolice: this.numeroApolice,
            numeroPropostaOrigem: this.numeroPropostaOrigem ? this.numeroPropostaOrigem : undefined,
            status: this.status,
            dataEmissao: new Date(this.dataEmissao).toISOString().split('T')[0],
            dataCancelamento: new Date(this.dataCancelamento).toISOString().split('T')[0],
            coberturas: this.coberturas.map(v => v.Serialize()),
            dataAceiteProposta: new Date(this.dataAceiteProposta).toISOString().split('T')[0],
            segurado: this.segurado ? this.segurado.Serialize() : undefined,
            motivoRecusaId: this.motivoRecusaId,
            tipoCadastro: this.tipoCadastro,
            // Validacao de nao obrigatorios
            responsavelPagamento: (this.responsavelPagamento.documento || this.responsavelPagamento.nome) ? this.responsavelPagamento.Serialize() : undefined,
            beneficiarios: this.beneficiarios[0].nome || this.beneficiarios[0].percentualParticipacao || this.beneficiarios[0].dataNascimento ?
                this.beneficiarios.map(v => {
                    return (v.nome || v.percentualParticipacao || v.dataNascimento) ? v.Serialize() : undefined
                }) : undefined,
        };
        return out;
    }

    SerializePatch() {
        const out = {
            status: this.status,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.id = data['id'] || undefined;
            this.numeroApolice = data['numeroApolice'] || '';
            this.numeroPropostaOrigem = data['numeroPropostaOrigem'] || '';
            this.status = data['status'] || null;
            this.dataEmissao = data['dataEmissao'] || new Date();
            this.dataCancelamento = data['dataCancelamento'] || new Date();
            this.coberturas = (data['coberturas'] || []).map((cobertura: Cobertura) => new Cobertura(cobertura));
            this.beneficiarios = (data['beneficiarios'] || []).map((beneficiario: Beneficiario) => new Beneficiario(beneficiario));
            this.responsavelPagamento = new ResponsavelPagamento(data['responsavelPagamento'] || {});
            this.associadaId = data['associadaId'] || '123';
            this.dataAceiteProposta = data['dataAceiteProposta'] ? new Date(data['dataAceiteProposta']) : new Date();
            this.segurado = new Segurado(data['segurado'] || {});
            this.motivoRecusaId = data['motivoRecusaId'] || undefined;
            this.tipoCadastro = data['tipoCadastro'] || 'API_INDIVIDUAL';
            this.usuarioCriacao = data['usuarioCriacao'] || undefined;
            this.dataCriacao = data['dataCriacao'] ? new Date(data['dataCriacao']) : new Date();
            this.tipoPropostaApolice = data['tipoPropostaApolice'] || 'VI';
            this.dataVigencia = data['dataVigencia'] || new Date();
        }

        return data;
    }

}

export class ApoliceVidaGrupo implements IRest {
    className = 'Classe de Apolice VG';

    id: string;
    associadaId: string;
    numeroApolice: string;
    numeroPropostaOrigem: string;
    status: ApoliceStatusEnum;
    dataEmissao: Date;
    dataCancelamento: Date
    dataAceiteProposta: Date;
    empresa: Empresa;
    coberturas: Cobertura[];
    tipoProdutoId: string
    motivoRecusaId: string
    tipoCadastro: TipoCadastroEnum;
    tipoPropostaApolice: TipoPropostaApoliceEnum;
    usuarioCriacao: IUsuarioCriacao
    dataCriacao: Date

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            numeroApolice: this.numeroApolice,
            numeroPropostaOrigem: this.numeroPropostaOrigem ? this.numeroPropostaOrigem : undefined,
            status: this.status,
            dataEmissao: new Date(this.dataEmissao).toISOString().split('T')[0],
            dataCancelamento: new Date(this.dataCancelamento).toISOString().split('T')[0],
            coberturas: this.coberturas.map(v => v.Serialize()),
            dataAceiteProposta: new Date(this.dataAceiteProposta).toISOString().split('T')[0],
            empresa: this.empresa ? this.empresa.Serialize() : undefined,
            tipoProdutoId: this.tipoProdutoId,
            motivoRecusaId: this.motivoRecusaId,
            tipoCadastro: this.tipoCadastro,
        };
        return out;
    }

    SerializePatch() {
        const out = {
            status: this.status,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.id = data['id'] || undefined;
            this.numeroApolice = data['numeroApolice'] || '';
            this.numeroPropostaOrigem = data['numeroPropostaOrigem'] || null;
            this.status = data['status'] || null;
            this.dataEmissao = data['dataEmissao'] || new Date();
            this.dataCancelamento = data['dataCancelamento'] || new Date();
            this.coberturas = (data['coberturas'] || []).map((cobertura: Cobertura) => new Cobertura(cobertura));
            this.associadaId = data['associadaId'] || '123';
            this.dataAceiteProposta = data['dataAceiteProposta'] ? new Date(data['dataAceiteProposta']) : new Date();
            this.empresa = new Empresa(data['empresa'] || {});
            this.tipoProdutoId = data['tipoProdutoId'] || undefined;
            this.motivoRecusaId = data['motivoRecusaId'] || undefined;
            this.tipoCadastro = data['tipoCadastro'] || 'API_INDIVIDUAL';
            this.usuarioCriacao = data['usuarioCriacao'] || undefined;
            this.dataCriacao = data['dataCriacao'] ? new Date(data['dataCriacao']) : new Date();
            this.tipoPropostaApolice = data['tipoPropostaApolice'] || 'VG';
        }

        return data;
    }

}
