
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
@Injectable()
export class AppSharedDataService {

  private menuToogle = new Subject<boolean>();
  menuToogle$ = this.menuToogle.asObservable();

  private positionScroll = new Subject<any>();
  positionScroll$ = this.positionScroll.asObservable();

  private activeTour = new Subject<boolean>();
  activeTour$ = this.activeTour.asObservable();

  private stepsTour = new Subject<any>();
  stepsTour$ = this.stepsTour.asObservable();

  private stepTourJson = new Subject<any>();
  stepTourJson$ = this.stepTourJson.asObservable();

  private ramoAtivo = new Subject<any>();
  ramoAtivo$ = this.ramoAtivo.asObservable();

  private metodoAtivo = new Subject<any>();
  metodoAtivo$ = this.metodoAtivo.asObservable();

  private menuBootstrap = new Subject<any>();
  menuBootstrap$ = this.menuBootstrap.asObservable();

  private resetMenuBootstrap = new Subject<boolean>();
  resetMenuBootstrap$ = this.resetMenuBootstrap.asObservable();

  private configuracaoMenu = new Subject<boolean>();
  configuracaoMenu$ = this.configuracaoMenu.asObservable();

  private menuActive = new Subject<boolean>();
  menuActive$ = this.menuActive.asObservable();

  private paginaPbi = new Subject<any>();
  paginaPbi$ = this.paginaPbi.asObservable();

  private previousPagePbi = new Subject<boolean>();
  previousPagePbi$ = this.previousPagePbi.asObservable();

  private nextPagePbi = new Subject<boolean>();
  nextPagePbi$ = this.nextPagePbi.asObservable();

  private pagePbiDashboard = new Subject<any>();
  pagePbiDashboard$ = this.pagePbiDashboard.asObservable();

  private pagePbiChangedNext = new Subject<any>();
  pagePbiChangedNext$ = this.pagePbiChangedNext.asObservable();

  private pagePbiChangedPrevious = new Subject<any>();
  pagePbiChangedPrevious$ = this.pagePbiChangedPrevious.asObservable();



  constructor() { }

  setToogleMenu(): void {
    this.menuToogle.next(true);
  }

  setPositionScroll(data): void {
    this.positionScroll.next(data);
  }

  setActiveTour(data): void {
    this.activeTour.next(data);
  }

  setStepsTour(data): void {
    this.stepsTour.next(data);
  }

  setStepTourJson(data): void {
    this.stepTourJson.next(data);
  }

  setRamoAtivo(data) {
    this.ramoAtivo.next(data);
  }

  setMetodoAtivo(data) {
    this.metodoAtivo.next(data);
  }

  setMenuBootstrap(data) {
    this.menuBootstrap.next(data);
  }

  setResetMenuBootstrap(data) {
    this.resetMenuBootstrap.next(data);
  }

  setConfiguracaoMenu(data) {
    this.configuracaoMenu.next(data);
  }

  setMenuActive(data) {
    this.menuActive.next(data)
  }

  setPaginaPbi(data) {
    this.paginaPbi.next(data);
  }

  setPreviousPagePbi(data) {
    this.previousPagePbi.next(data);
  }

  setNextPagePbi(data) {
    this.nextPagePbi.next(data);
  }

  setpagePbiDashboard(data) {
    this.pagePbiDashboard.next(data);
  }

  setpagePbiChangedNext(data) {
    this.pagePbiChangedNext.next(data);
  }

  setpagePbiChangedPrevious(data) {
    this.pagePbiChangedPrevious.next(data);
  }
}
