import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class DashboardSharedDataService {

  constructor() { }

  private notaFiscaleditada = new Subject<boolean>();
  notaFiscaleditada$ = this.notaFiscaleditada.asObservable();

  private notasFiscaisCanceladas = new Subject<boolean>();
  notasFiscaisCanceladas$ = this.notasFiscaisCanceladas.asObservable();

  setNotaFiscalEditada(data: boolean) {
    this.notaFiscaleditada.next(data);
  }

  setNotasFiscaisCanceladas(data: boolean) {
    this.notasFiscaisCanceladas.next(data);
  }
}