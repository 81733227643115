import { Component, Input, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';

@Component({
  selector: 'ds-custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  verifiedInput: boolean = false
  
  @Input() options: string[] = [] // Opções do select
  @Input() placeholder: string = ''
  @Input() ngModel: any;
  @Input() mask: string = '';
  @Input() selectValue: string
  @Input() label: string = '' 
  @Input() value: any = null;
  @Input() required: boolean = false

  // validacao
  @Input() validateInputEvent?: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output() ngModelChangeValue = new EventEmitter<any>()

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.validateInput()
  }
  validateInput() {
    this.validateInputEvent.subscribe((res) => {
      this.verifiedInput = true
    })
  }

}
