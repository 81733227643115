import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'brDate' })
export class BrDate implements PipeTransform {
  transform(value: Date): string {
    if (value)
      return moment.utc(moment(value)).format("DD/MM/YYYY");

    return '';
  }
}

@Pipe({ name: 'brDateHoraMin' })
export class BrDateHoraMin implements PipeTransform {
  transform(value: string): string {
    if (value)
      return moment(value.slice(0,-6)).format("DD/MM/YYYY");
    return '';
  }
}
