import { HttpClient, HttpHeaders } from "@angular/common/http";

export abstract class ApiClass {

    constructor(protected httpClient: HttpClient) {}

    apiHeaders() {
        return new HttpHeaders({
            'rnpa_req': 'true'
        })
    }


}