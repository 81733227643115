import { Component, Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-consultar-proposta',
  templateUrl: './consultar-proposta.component.html',
  styleUrls: ['./consultar-proposta.component.scss', './../styles.scss']
})
export class ConsultarPropostaComponent implements OnInit {

  analiseBody: any

  // Obrigatorio em custom products
  cadastro: { nomeProduto: string, nomeMetodo: string } = {
    nomeProduto: 'RNPA Pessoas',
    nomeMetodo: 'Consultar Proposta'
  }

  pageControllerState: 'form' | 'output' = 'form' 
  pageControllerStateEmitter = new EventEmitter<'form' | 'output'>()

  @Input() idProduto: number = 89;
  @Input() idMetodo: number = 1937;
  @Input() dsServico: string;
  @Input() dsMetodo: string;
  @Input() disabled: any;
  @Input() consulta: any;
  

  constructor() { }

  ngOnInit() {
    this.listenPageControllerEvent()
  }

  handleSubmit(body: any){
    this.pageControllerState = 'output'
    this.analiseBody = body
  }

  listenPageControllerEvent(){
    this.pageControllerStateEmitter.subscribe((evt) => this.pageControllerState = evt)
  }


}
