import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'versao'
})
export class VersaoPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
   
    if (value) {
      if (value.length === 6) {
        let val = value.toString();
        return `${val.substr(0, 2)}/${val.substr(2, 6)}`
      }
      if (value == null || value == undefined)
        return '';

      return value;
    }
    return '';
  }
}