import { Injectable } from '@angular/core';
import { RetornoConsulta, ParametroDetalheConsulta } from '@core/models/consulta-metodo.models';
import { TipoDadoEnum } from '@core/models/tipo-dado.models';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { TipoServicoEnum } from '@core/models/tipo-servico.models';
import { NgxXml2jsonService } from 'ngx-xml2json';

@Injectable({
  providedIn: 'root'
})
export class ResultadoConsultaService {

  constructor(private ngxXml2jsonService: NgxXml2jsonService) { }

  montarResultado(data: RetornoConsulta, parametrosSaida: ParametroDetalheConsulta[], parametrosErro?: ParametroDetalheConsulta[], isAtob: boolean = true): any {
    let configResultado: FieldConfig[] = [];
    let configErro: FieldConfig[] = [];
    let validCountSuccess = [];
    let validCountError = [];
    let objRetornoServico = {};
    const parser = new DOMParser();

    if (data.resultado) {

      let obj;
      if (isAtob)
        obj = atob(data.resultado);
      else
        obj = data.resultado;

      if (obj) {
        if (obj.toString().startsWith('<'))
          data.tpServico = TipoServicoEnum.SOAP;
        else
          data.tpServico = TipoServicoEnum.REST;
      }
    }
    switch (data.tpServico) {
      case TipoServicoEnum.SOAP:

        let xml;
        if (isAtob)
          xml = parser.parseFromString(atob(data.resultado), 'text/xml');
        else
          xml = parser.parseFromString(data.resultado, 'text/xml');

        let obj = this.xmlToObject(xml);

        if (obj['soapenv:Envelope'] && obj['soapenv:Envelope']['soapenv:Body']) {
          console.log('XML', obj)
          console.log('XML', JSON.stringify(obj))
          objRetornoServico = obj['soapenv:Envelope']['soapenv:Body'];
          if (data.success) {
            console.log(parametrosSaida)
            this.navigateProperty(validCountSuccess, parametrosSaida, this.xmlToObject(xml)['soapenv:Envelope']['soapenv:Body'], configResultado);
            console.log('Parametros Saida', parametrosSaida)
            this.navigateProperty(validCountError, parametrosErro, this.xmlToObject(xml)['soapenv:Envelope']['soapenv:Body'], configErro);
            console.log('Parametros Erro', parametrosErro)
          }
        } else if (obj['env:Envelope'] && obj['env:Envelope']['env:Body']) {

          console.log('XML', obj)
          console.log('XML', JSON.stringify(obj))
          objRetornoServico = obj['env:Envelope']['env:Body']
          if (data.success) {
            this.navigateProperty(validCountSuccess, parametrosSaida, this.xmlToObject(xml)['env:Envelope']['env:Body'], configResultado);
            console.log('Parametros Saida', parametrosSaida)
            this.navigateProperty(validCountError, parametrosErro, this.xmlToObject(xml)['env:Envelope']['env:Body'], configErro);
            console.log('Parametros Erro', parametrosErro)
          }
        } else {
          console.log('XML com Erro ou Diferente do padr�o esperado', obj)
          console.log('XML', JSON.stringify(obj))
          this.navigateProperty(validCountSuccess, parametrosSaida, obj, configResultado);
          console.log('Parametros Saida', parametrosSaida)
          this.navigateProperty(validCountError, parametrosErro, obj, configErro);
          console.log('Parametros Erro', parametrosErro)
          objRetornoServico = this.xmlToObject(xml);
        }

        break;

      case TipoServicoEnum.REST:
        let json;
        if (isAtob){
          let jsonAtop
          jsonAtop = atob(data.resultado)     

          const processedJsonText = jsonAtop.replace(/("PROTOCOLO"\s*:\s*)(\d{16,})/g, '$1"$2"');
          json = JSON.parse(processedJsonText)
        }
        else
        json = JSON.parse(data.resultado);

        console.log('JSON', json);
        console.log('JSON', JSON.stringify(json));
        objRetornoServico = json;
        if (data.success) {
          if (isAtob) {
            this.navigateProperty(validCountSuccess, parametrosSaida, JSON.parse(atob(data.resultado)), configResultado);
            console.log(parametrosSaida)
            this.navigateProperty(validCountError, parametrosErro, JSON.parse(atob(data.resultado)), configErro);
          } else {
            this.navigateProperty(validCountSuccess, parametrosSaida, JSON.parse(data.resultado), configResultado);
            console.log(parametrosSaida)
            this.navigateProperty(validCountError, parametrosErro, JSON.parse(data.resultado), configErro);
          }
        }
        break;
    }

    let obj = {
      configs: validCountSuccess.length > validCountError.length ? configResultado : configErro,
      sucesso: validCountSuccess.length > validCountError.length,
      objRetornoServico
    }
    return obj;
  }

  navigateProperty(validCount: number[], parametros: ParametroDetalheConsulta[], objTemp: any, configResultado: FieldConfig[], configPai?: FieldConfig) {
    let objArr = this.toArray(objTemp);

    objArr.forEach((obj) => {
      parametros.forEach(x => {
        if (obj[x.parametroServico] === null)
          obj[x.parametroServico] = {};

        if (obj[x.parametroServico] != undefined) {
          if (x.idTipoDado == TipoDadoEnum.Array) {

            let config = <FieldConfig>{};
            config.configs = [];
            config.name = x.parametroServico;
            config.type = this.getTypeByTipoDadoResultado(x.idTipoDado);
            config.label = x.parametroPortal;
            config.visible = x.visivel;
            if (x.dominio)
              config.options = JSON.parse(x.dominio).data;

            if (x.filhos && x.filhos.filter(x => x.parametroServico != 'protocolo' && x.parametroServico != 'saldo').length) {
              config.limitBorder = x.filhos.filter(x => x.visivel && x.parametroServico != 'protocolo' && x.parametroServico != 'saldo').length;
            }
            if (configPai) {
              configPai.configs.push(config)
            }
            else
              configResultado.push(config);

            if (!Array.isArray(obj[x.parametroServico]))
              obj[x.parametroServico] = [obj[x.parametroServico]];


            obj[x.parametroServico].forEach(z => {
              if (x.filhos.length) {
                this.navigateProperty(validCount, x.filhos, z, configResultado, config)
              }
            })
          }
          else
            if (x.idTipoDado == TipoDadoEnum.Objeto) {
              if (obj[x.parametroServico]) {
                let config = <FieldConfig>{};
                config.configs = [];
                config.name = x.parametroServico;
                config.type = this.getTypeByTipoDadoResultado(x.idTipoDado);
                config.label = x.parametroPortal;
                config.visible = x.visivel;
                if (x.dominio)
                  config.options = JSON.parse(x.dominio).data;

                if (configPai) {
                  configPai.configs.push(config)
                } else
                  configResultado.push(config);

                if (x.filhos.length) {
                  this.navigateProperty(validCount, x.filhos, obj[x.parametroServico], configResultado, config)
                }
              }
            }
            else
              if (x.visivel) {
                let config = <FieldConfig>{};
                config.name = x.parametroServico;
                config.type = this.getTypeByTipoDadoResultado(x.idTipoDado);
                config.label = x.parametroPortal;
                config.configs = [];
                config.visible = x.visivel;
                if (x.dominio)
                  config.options = JSON.parse(x.dominio).data;

                validCount.push(1);
                if (typeof obj[x.parametroServico] == 'number')
                  config.value = obj[x.parametroServico];
                else
                  if (Object.keys(obj[x.parametroServico]).length === 0 && obj.constructor === Object)
                    config.value = '';
                  else
                    if (typeof obj[x.parametroServico] == 'string') {
                      try {
                        config.value = decodeURIComponent(escape(obj[x.parametroServico]));
                      } catch (error) {
                        console.log(`Erro no decode: Atributo ${x.parametroServico} Valor: ${obj[x.parametroServico]} Erro: ${error}`)
                      }
                    }
                    else
                      config.value = obj[x.parametroServico];

                if (config.name == 'saldo' || config.name == 'protocolo')
                  configResultado.push(config);
                else
                  if (configPai) {
                    configPai.configs.push(config)
                  }
                  else
                    configResultado.push(config);
              }
        }
      })
    })
  }

  toArray(obj) {
    if (Array.isArray(obj))
      return obj;

    if (Object.keys(obj).indexOf("0") != -1) {
      let arr = [];
      Object.keys(obj).forEach((key) => {
        arr.push(obj[key]);
      });
      return arr;
    }

    if (this.hasValue(obj))
      return [obj];

    return [];
  }

  hasValue(obj: any): boolean {
    if (!obj)
      return false;

    if (Object.keys(obj).length === 0 && obj.constructor === Object)
      return false;

    return obj;
  }

  getTypeByTipoDadoResultado(idTipoDado: number) {

    switch (idTipoDado) {
      case TipoDadoEnum.Texto:
        return 'label';
      case TipoDadoEnum.Array:
        return 'array';
      case TipoDadoEnum.Objeto:
        return 'objeto';
      case TipoDadoEnum.Data:
        return 'data';
      case TipoDadoEnum.Booleano:
        return 'boolean';
      case TipoDadoEnum.Hora:
        return 'hora';
      case TipoDadoEnum.Link:
        return 'link';
      case TipoDadoEnum.Moeda:
        return 'moeda';
    }

    return 'label';
  }

  xmlToObject(xml) {
    return this.ngxXml2jsonService.xmlToJson(xml)
  }
}
