import { NgModule } from '@angular/core';
import { DetalheProdutoComponent } from './detalhe-produto/detalhe-produto.component';
import { CardProdutoComponent } from './card-produto/card-produto.component';
import { SharedModule } from '@shared/shared.module';


@NgModule({
    declarations: [
        DetalheProdutoComponent,
        CardProdutoComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [CardProdutoComponent, DetalheProdutoComponent]
})
export class ComponentsProdutoModule { }
