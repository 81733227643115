import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';
import { Masks } from '@core/custom-validators/masks';
import { CustomValidators } from '@core/custom-validators/validators';

@Component({
  selector: 'app-form-cep',
  templateUrl: './form-cep.component.html',
  styleUrls: ['./form-cep.component.scss']
})
export class FormCepComponent implements Field, OnInit {
  formCEP: FormGroup;
  config: FieldConfig;
  group: FormGroup;
  mask = {
    mask: Masks.cep,
    guide: false,
    modelClean: true
  };
  firstSubmit = false;

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.formCEP = this.formBuilder.group({
      cep: ['']
    })

    if (this.config.required) {
      this.cep.setValidators(Validators.compose([Validators.required, CustomValidators.cep]))
    }
    else
      this.cep.setValidators(Validators.compose([CustomValidators.cep]))


    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.cep.setValue(this.cep.value);
      }
    })

    this.cep.valueChanges.subscribe((val) => {

      if (this.cep.valid) {
        this.control.setValue(this.clearMaskValue(val));
      }
      else {
        this.control.setValue(undefined);
      }
    })
  }

  get cep() {
    return this.formCEP.controls.cep;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  clearMaskValue(val): string {
    if (val)
      return val.replace(/[^\w\s]/gi, '');

    return val;
  }
}