import { Component, OnInit, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base-dialog',
  template: ''
})
export class BaseDialogComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {

  }

  close(): void {
    this.modal.close();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.close();
  }

  @HostListener('window:popstate', ['$event']) onPopState(event) {
    this.close();
  }
}
