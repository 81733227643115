import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatPageable } from '@core/table/mat-table';
import { NotificacaoElement } from '@core/models/notificacao.models';
import { HttpParams } from '@angular/common/http';


const routes = {
  buscarNotificacoes: (pagina: number, registros: number, idUsuario: number) => `notificacao/buscanotificacoes/${pagina}/${registros}/${idUsuario}`,
  excluir: `notificacao/excluirnotificacao`,
  excluirTodas: `notificacao/excluirtodasnotificacoes`,
}

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(private apiService: ApiService) { }

  buscarNotificacoes(pagination: MatPageable, idUsuario: number) {
    let params = new HttpParams();
    params = params.append('ignoreLoading', 'true')
    params = params.append('ignoreError', 'true');
    return this.apiService.get<NotificacaoElement[]>(routes.buscarNotificacoes(pagination.pagina, pagination.registros, idUsuario), params);
  }

  excluirNotificacao(notificacao: NotificacaoElement) {
    return this.apiService.put(routes.excluir, {
      "ExcluirNotificacao": [
        {
          "idUsuarioNotificacao": notificacao.idUsuarioNotificacao
        }
      ]
    });
  }

  excluirTodasNotificacoes(idUsuario: number) {
    return this.apiService.put(routes.excluirTodas, {
      "ExcluirTodasNotificacao": [
        {
          "idUsuario": idUsuario
        }
      ]
    });
  }
}
