import { Component, OnInit, Input } from '@angular/core';
import { TipoCadastroEnum } from '../../../enumerators';

@Component({
  selector: 'ds-tag-tipo-cadastro',
  templateUrl: './tag-tipo-cadastro.component.html',
  styleUrls: ['./tag-tipo-cadastro.component.scss']
})
export class TagTipoCadastroComponent implements OnInit {

  @Input() tipoCadastro: TipoCadastroEnum = TipoCadastroEnum.TelaIndividual

  tipoCadastroEnum = TipoCadastroEnum

  constructor() { }

  ngOnInit() {
  }

}
