import { NgModule } from '@angular/core';
import { TrocarSenhaComponent } from './trocar-senha.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [TrocarSenhaComponent],
    imports: [
        SharedModule
    ]
})
export class TrocarSenhaModule { }
