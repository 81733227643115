import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { Injectable } from "@angular/core";
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    pathToReturn = 'consulta';
    storedRouteHandles = new Map<string, DetachedRouteHandle>();
    allowRetriveCache = {
        'consulta': true
    };

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
     
        if (this.getPath(before).includes(this.pathToReturn) || this.getPath(before).includes('tab')) {
            this.allowRetriveCache[this.pathToReturn] = true;
        } else {
            this.allowRetriveCache[this.pathToReturn] = false;
        }

        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {

        if (route.data && route.data.reuse)
            return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
        else
            return null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
    
        if (route.data && route.data.reuse) {
            if (this.allowRetriveCache[this.pathToReturn]) {
                return this.storedRouteHandles.has(this.getPath(route));
            }
        }

        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
 
        if (route.data && route.data.reuse) {
            if (this.allowRetriveCache.hasOwnProperty(this.pathToReturn)) {
                return true;
            }
        }
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig !== null && route.routeConfig.path !== null) {
            return route.routeConfig.path;
        }
        return '';
    }
}