import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HistoricoSivvePesquisa, SivveConectorFiltroEnum, SivveConectorGrupoEnum, SivveConsultarStatusLote, SivveExportarLote, SivveOperadorLogicoEnum, SivvePesquisaResultadoConsulta, SivvePesquisaSalvar, SivveResultadoConsultarLote, SivveTipoFiltroEnum } from '@core/models/sivve-pesquisa-avancada';
import { ApiService } from '@core/services/api.service';
import { MatPageable, PageableResponse } from '@core/table/mat-table';
import { environment } from '@env/environment';
import { of } from 'rxjs';

const routes = {
  salvarPesquisa: `sivve-dinamico/filtro`,
  atualizarPesquisa: (idPesquisa: number) => `sivve-dinamico/filtro/${idPesquisa}`,
  buscarPequisa: (idPesquisa: number) => `sivve-dinamico/filtro/${idPesquisa}`,
  removerPesquisa: (idPesquisa: number) => `sivve-dinamico/filtro/${idPesquisa}`,
  downloadLote: (idLote: number) => `sivve-dinamico/download/${idLote}`,
  buscarPesquisas: `sivve-dinamico/filtro/listar`,
  sivveDinamico: `sivvedinamico/contagem`,
  enviarLote: `sivvedinamico/criarlote`,
  consultarstatus: `sivvedinamico/consultastatuslote`,
  buscarHistoricoLote: (page: number, size: number) => `sivve-dinamico/historico?page=${page}&size=${size}`,
  buscarQnaes: `sivve-dinamico/listacnaes`,
  buscarRamosAtividade: `sivve-dinamico/listaramosatividades`,
  buscarSituacoesCadastrais: `sivve-dinamico/listasituacoescadastrais`,
  buscarNaturezasJuridicas: `sivve-dinamico/listanaturezasjuridicas`,
  buscarUfs: `sivve-dinamico/listaufs`,
  buscarMunicipios: `sivve-dinamico/listamunicipios`,
  uploadDominio: `sivve-dinamico/upload`
}

@Injectable({
  providedIn: 'root'
})
export class SivveService {

  constructor(private apiService: ApiService) { }

  salvarPesquisa(obj: SivvePesquisaSalvar) {
    if (obj.id)
      return this.apiService.putCustomApi<SivvePesquisaSalvar>(`${environment.lote}/${routes.atualizarPesquisa(obj.id)}`, obj);

    return this.apiService.postCustomApi<SivvePesquisaSalvar>(`${environment.lote}/${routes.salvarPesquisa}`, obj);
  }

  buscarPesquisa(id: number) {
    return this.apiService.getCustomApi<SivvePesquisaSalvar>(`${environment.lote}/${routes.buscarPequisa(id)}`);
  }

  removerPesquisa(id: number) {
    return this.apiService.deleteCustomApi<SivvePesquisaSalvar>(`${environment.lote}/${routes.removerPesquisa(id)}`);
  }

  buscarPesquisas() {
    return this.apiService.getCustomApi<SivvePesquisaSalvar[]>(`${environment.lote}/${routes.buscarPesquisas}`);
  }

  pesquisar(obj) {
    return this.apiService.postCustomApi<SivvePesquisaResultadoConsulta>(`${environment.apiwso2}/${routes.sivveDinamico}`, obj);
  }

  enviarLote(obj: SivveExportarLote) {
    return this.apiService.postCustomApi<any>(`${environment.apiwso2}/${routes.enviarLote}`, obj);
  }

  consultarStatus(obj: SivveConsultarStatusLote, exibirLoading: boolean) {
    let params = new HttpParams();
    if (!exibirLoading)
      params = params.append('ignoreLoading', 'true');
    params = params.append('ignoreError', 'true');
    return this.apiService.postCustomApi<SivveResultadoConsultarLote>(`${environment.apiwso2}/${routes.consultarstatus}`, obj, undefined, params);
  }

  downloadLote(id: number, exibirLoading: boolean) {
    let params = new HttpParams();
    if (!exibirLoading)
      params = params.append('ignoreLoading', 'true');
    return this.apiService.downloadGetCustomApi(`${environment.lote}/${routes.downloadLote(id)}`, params);
  }

  buscarHistoricoLote(pageable: MatPageable) {
    return this.apiService.getCustomApi<PageableResponse<HistoricoSivvePesquisa>>(`${environment.lote}/${routes.buscarHistoricoLote(pageable.pagina - 1, pageable.registros)}`);
  }

  buscarQnaes() {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.buscarQnaes}`);
  }

  buscarRamosAtividade() {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.buscarRamosAtividade}`);
  }

  buscarSituacoesCadastrais() {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.buscarSituacoesCadastrais}`);
  }

  buscarNaturezasJuridicas() {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.buscarNaturezasJuridicas}`);
  }

  buscarUfs() {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.buscarUfs}`);
  }

  buscarMunicipios() {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.buscarMunicipios}`);
  }

  buscarFiltros() {
    return of([
      {
        nome: 'CNPJ',
        value: SivveTipoFiltroEnum.CNPJ
      },
      {
        nome: 'Razão Social',
        value: SivveTipoFiltroEnum.RazaoSocial
      },
      {
        nome: 'Data Abertura',
        value: SivveTipoFiltroEnum.DataAbertura
      },
      {
        nome: 'CNAE Principal',
        value: SivveTipoFiltroEnum.CNAEPrincipal
      },
      {
        nome: 'CNAE Secundário',
        value: SivveTipoFiltroEnum.CNAESecundario
      },
      {
        nome: 'Ramo Atividade',
        value: SivveTipoFiltroEnum.RamoAtividade
      },
      {
        nome: 'Natureza Jurídica',
        value: SivveTipoFiltroEnum.NaturezaJuridica
      },
      {
        nome: 'UF',
        value: SivveTipoFiltroEnum.UF
      },
      {
        nome: 'Município',
        value: SivveTipoFiltroEnum.Municipio
      },
      {
        nome: 'Situação Cadastral',
        value: SivveTipoFiltroEnum.SituacaoCadastral
      },
      {
        nome: 'Optante Simei',
        value: SivveTipoFiltroEnum.OptanteSimei
      },
      {
        nome: 'Optante Simples',
        value: SivveTipoFiltroEnum.OptanteSimples
      },
      {
        nome: 'Quantidade Funcionários',
        value: SivveTipoFiltroEnum.QtdFuncionarios
      },
      {
        nome: 'Quantidade de Funcionários Grupo',
        value: SivveTipoFiltroEnum.QtdFuncionariosGrupo
      },
      {
        nome: 'Faturamento Estimado',
        value: SivveTipoFiltroEnum.FaturamentoEstimado
      },
      {
        nome: 'CEP',
        value: SivveTipoFiltroEnum.CEP
      },
      {
        nome: 'Telefone',
        value: SivveTipoFiltroEnum.Telefone
      },
      {
        nome: 'Telefone RF',
        value: SivveTipoFiltroEnum.TelefoneRF
      },
      {
        nome: 'Email',
        value: SivveTipoFiltroEnum.Email
      },
      {
        nome: 'Email RF',
        value: SivveTipoFiltroEnum.EmailRF
      },
      {
        nome: 'Matriz',
        value: SivveTipoFiltroEnum.Matriz
      }
    ])
  }

  buscarConectorGrupo() {
    return of([
      {
        nome: 'E',
        value: SivveConectorGrupoEnum.AND
      },
      {
        nome: 'OU',
        value: SivveConectorGrupoEnum.OR
      }
    ])
  }

  buscarConectorFiltro() {
    return of([
      {
        nome: 'E',
        value: SivveConectorFiltroEnum.AND
      },
      {
        nome: 'OU',
        value: SivveConectorFiltroEnum.OR
      }
    ])
  }

  buscarOperadoresLogicos() {
    return of([
      {
        nome: 'Começa Com',
        value: SivveOperadorLogicoEnum.ComecaoCom
      },
      {
        nome: 'Não Contém',
        value: SivveOperadorLogicoEnum.NaoContem
      },
      {
        nome: 'Contém',
        value: SivveOperadorLogicoEnum.Contem
      },
      {
        nome: 'Diferente',
        value: SivveOperadorLogicoEnum.Diferente
      },
      {
        nome: 'Igual',
        value: SivveOperadorLogicoEnum.Igual
      },
      {
        nome: 'Maior Que',
        value: SivveOperadorLogicoEnum.MaiorQue
      },
      {
        nome: 'Menor Que',
        value: SivveOperadorLogicoEnum.MenorQue
      },
      {
        nome: 'Maior ou Igual que',
        value: SivveOperadorLogicoEnum.MaiorIgualQue
      },
      {
        nome: 'Menor ou Igual que',
        value: SivveOperadorLogicoEnum.MenorIgualQue
      }
    ])
  }

  uploadDominio(arquivo: string) {
    return this.apiService.postCustomApi<any>(`${environment.lote}/${routes.uploadDominio}`, { arquivo });
  }
}
