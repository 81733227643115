import { HttpResponse } from '@angular/common/http';
import { Masks } from './../../../../../core/custom-validators/masks';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProposalStatusEnum } from '../enumerators/status';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { PropostaService } from '../services/proposta.service';
import { TipoCadastroEnum, TipoPropostaApoliceEnum } from '../enumerators/types';
import { Beneficiario, Cobertura, PropostaVidaGrupo, PropostaVidaIndividual } from '../models';
import { CoberturaResponse } from '../models/ramos-coberturas';
import { ToastService } from '../services/toast.service';
import { TiposProdutoService } from '../services/tipos-produto.service';
import { RamosCoberturasService } from '../services/ramos-coberturas.service';
import { CnaeService } from '../services/cnae.service';

interface ISearchParams {
  numeroDocumento: string,
  numeroProposta: string,
  motivoRecusaId: string,
  tipoCadastro: string,
  dataCriacaoInicio: Date,
  dataCriacaoFim: Date,
  status: ProposalStatusEnum,
  page: number,
  limit: number,
  sort: string,
  sortType: 'ASC' | 'DESC'
}

@Component({
  selector: 'app-alterar-proposta',
  templateUrl: './alterar-proposta.component.html',
  styleUrls: ['./alterar-proposta.component.scss', './../styles.scss',]
})

export class AlterarPropostaComponent implements OnInit {

  propostas: (PropostaVidaIndividual | PropostaVidaGrupo)[] = []
  propostaSelecionada: PropostaVidaIndividual | PropostaVidaGrupo = new PropostaVidaGrupo({ numeroProposta: 'P654879645', dataEmissao: new Date(), coberturas: [new Cobertura({})], beneficiarios: [new Beneficiario({})] })

  closeResult = '';
  // Obrigatorio em custom products
  cadastro: { nomeProduto: string, nomeMetodo: string } = {
    nomeProduto: 'RNPA Pessoas',
    nomeMetodo: 'Alterar'
  }

  // Enums
  proposalStatusEnum = ProposalStatusEnum
  tipoPropostaApoliceEnum = TipoPropostaApoliceEnum

  // Opcoes de filtros no header
  motivosRecusa: any[] = []
  statusProposta = Object.values(ProposalStatusEnum)
  tiposCadastro = Object.values(TipoCadastroEnum)
  coberturas: CoberturaResponse[]
  tiposProdutos: any[]
  divisoesCnae: any[]

  // Dicionario de Ids
  idDictionary = {
    coberturas: {},
    motivosRecusa: {},
    tiposProduto: {},
    divisoesCnae: {},
  }

  searchParams: ISearchParams = {
    numeroDocumento: null,
    numeroProposta: null,
    motivoRecusaId: null,
    tipoCadastro: null,
    dataCriacaoInicio: null,
    dataCriacaoFim: null,
    status: null,
    page: 1,
    limit: 10,
    sort: 'dataCriacao',
    sortType: 'DESC',
  }

  clearDateInputEvent = new EventEmitter<boolean>()

  paginationCollectionSize: number = 10

  // Masks
  inputMasks = {
    cnpj: {
      mask: Masks.cnpj,
      guide: false,
      modelClean: true
    },
    cpf: {
      mask: Masks.cpf,
      guide: false,
      modelClean: true
    },
    cpfCnpj: {
      mask: Masks.cpfCnpj,
      guide: false,
      modelClean: true
    },
    telefone: {
      mask: Masks.telefone,
      guide: false,
      modelClean: true
    },
    number: {
      mask: Masks.integer,
      guide: false,
      modelClean: true
    },
    decimal: {
      mask: Masks.decimal,
      guide: false,
      modelClean: true
    },
    currency: {
      mask: Masks.currency,
      guide: false,
      modelClean: true
    }
  }

  @Input() idProduto: number = 89;
  @Input() idMetodo: number = 1937;
  @Input() dsServico: string;
  @Input() dsMetodo: string;
  @Input() disabled: any;
  @Input() consulta: any;

  constructor(
    public authenticationService: AuthenticationService,
    private nbgModalService: NgbModal,
    private _propostaService: PropostaService,
    private _toastService: ToastService,
    private _tiposProdutoService: TiposProdutoService,
    private _ramosCoberturasService: RamosCoberturasService,
    private _cnaeService: CnaeService,
  ) {
  }

  ngOnInit() {
    this.listarPropostas()
    this.fillSelectOptions()
  }

  listarPropostas() {
    this._propostaService.listarPropostas(this.searchParams).subscribe(
      (res: HttpResponse<any>) => {
        this.propostas = res.body as Array<PropostaVidaIndividual | PropostaVidaGrupo>
        this.paginationCollectionSize = parseInt(res.headers.get('Total-Pages')) * (this.searchParams.limit ? this.searchParams.limit : 10)
      },
      (error) => {
        this._toastService.showError('Desculpe, não foi possível listar as propostas.')
      }
    )
  }

  // Seleção de proposta
  selecionarProposta(proposta: PropostaVidaIndividual | PropostaVidaGrupo, modalContent) {
    this.propostaSelecionada = proposta.tipoPropostaApolice == TipoPropostaApoliceEnum.Vi ? new PropostaVidaIndividual(proposta) : new PropostaVidaGrupo(proposta);

    this.openModal(modalContent)
  }

  // Atualiza a proposta do form no modal
  updateProposta() {
    this._propostaService.atualizarProposta(this.propostaSelecionada).subscribe((res) => {
      this._toastService.showSuccess('Proposta atualizada com sucesso.')
      this.listarPropostas()
      this.propostaSelecionada = null
      this.nbgModalService.dismissAll()
    }, (err) => {
      const errorMsg = err.status == 422 ? err.error.mensagem : "Não foi possível atualizar a proposta."
      this._toastService.showError(errorMsg)
    })
  }

  openModal(content) {
    this.nbgModalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl', windowClass: 'custom-modal' })
  }

  fillSelectOptions() {
    this._propostaService.listarMotivoRecusa().subscribe((res: any[]) => {
      this.motivosRecusa = res
      this.idDictionary.motivosRecusa = res.reduce((obj, motivo) => {
        obj[motivo.id] = motivo.motivo;
        return obj;
      }, {});

    })

    this._tiposProdutoService.listarTiposProduto().subscribe((res: any[]) => {
      this.tiposProdutos = res
      this.idDictionary.tiposProduto = res.reduce((obj, motivo) => {
        obj[motivo.id] = motivo.descricao;
        return obj;
      }, {});
    })

    this._ramosCoberturasService.listCoberturas().subscribe((res: CoberturaResponse[]) => {
      this.coberturas = res.sort((a,b) => {
        return a.cobertura > b.cobertura ? 1 : a.cobertura < b.cobertura ? -1 : 0 
      })
      this.idDictionary.coberturas = res.reduce((obj, cobertura) => {
        obj[cobertura.id] = cobertura;
        return obj;
      }, {});
    })

    this._cnaeService.listarDivisoesCnae().subscribe((res: any[]) => {
      this.divisoesCnae = res.sort((a, b) => {
        return parseInt(a.divisaoId) > parseInt(b.divisaoId) ? 1 : parseInt(a.divisaoId) < parseInt(b.divisaoId) ? -1 : 0
      });
      
      this.idDictionary.divisoesCnae = res.reduce((obj, cnae) => {
        obj[cnae.divisaoId] = cnae.descricao;
        return obj;
      }, {});
    })
  }

  // Lida com o evento do componente de paginacao
  setPage(page: number) {
    this.searchParams.page = page
    this.listarPropostas()
  }

  // Limpa os campos de filtros
  clearFilter() {
    this.searchParams = {
      numeroDocumento: null,
      numeroProposta: null,
      motivoRecusaId: null,
      tipoCadastro: null,
      dataCriacaoInicio: null,
      dataCriacaoFim: null,
      status: null,
      page: this.searchParams.page,
      limit: this.searchParams.limit,
      sort: null,
      sortType: null,
    }
    // Emite evento para limpar datepicker-range
    this.clearDateInputEvent.emit(true)
    this.listarPropostas()
  }

  // Adiciona cobertura na proposta
  addCoverage() {
    this.propostaSelecionada.coberturas.push(new Cobertura({}))
  }

  // Remove cobertura na proposta
  removeCoverage(index: number) {
    this.propostaSelecionada.coberturas.splice(index, 1)
  }

  // Adiciona beneficiario na proposta
  addRecipient() {
    if (!this.propostaSelecionada['beneficiarios']) this.propostaSelecionada['beneficiarios'] = []
    this.propostaSelecionada['beneficiarios'].push(new Beneficiario({}))
  }

  // Ordenação da lista
  sortBy(field: string) {
    this.searchParams.sort = field
    this.searchParams.sortType = (this.searchParams.sortType == 'ASC') ? 'DESC' : 'ASC'

    this.listarPropostas()
  }

  handlePeriodoEmit(dateEvent: { dataInicio: Date, dataFinal: Date }) {
    this.searchParams.dataCriacaoInicio = dateEvent.dataInicio
    this.searchParams.dataCriacaoFim = dateEvent.dataFinal
  }

}
