import { Component, OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { Masks } from '@core/custom-validators/masks';
import { faCalendar, faEraser } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

@Component({
  selector: 'app-data-form-com-clear',
  templateUrl: './data-form-com-clear.component.html',
  styleUrls: ['./data-form-com-clear.component.scss']
})
export class DataFormComClearComponent implements OnInit {

  formDatePicker: FormGroup;
  group: FormGroup;
  faCalendar = faCalendar;
  faEraser = faEraser;
  mask = {
    mask: Masks.data,
    guide: false
  };
  showClearDate: boolean = false;
  firstSubmit = false;

  @Output() dataRetorno = new EventEmitter();
  @Input() dataEnvio: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() controlDisable: any;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.formDatePicker = this.formBuilder.group({
      data: [],
      inputData: []
    });

    if (this.dataEnvio) {
      this.showClearDate = true;
      if (moment(this.dataEnvio, 'YYYY-MM-DD', true)) {
        this.inputData.setValue(moment(this.dataEnvio, 'YYYY-MM-DD', true).format('DD/MM/YYYY'));
      }
      if (moment(this.dataEnvio, 'DD/MM/YYYY', true)) {
        this.inputData.setValue(moment(this.dataEnvio, 'DD/MM/YYYY', true).format('DD/MM/YYYY'));
      }
    }

    this.data.valueChanges.subscribe((val) => {
      if (val) {
        this.inputData.setValue(moment(val, 'YYYY-MM-DD', true).format('DD/MM/YYYY'));
        this.showClearDate = true;
      } else {
        this.showClearDate = false;
        this.inputData.setValue(undefined);
      }
    });

    this.inputData.valueChanges.subscribe((val) => {
      if (val) {
        this.showClearDate = true;
        if (this.inputData.value.length === 10) {
          this.inputData.setValidators(this.setRequired(this.required));
          this.dataRetorno.emit(this.inputData.value);
        }
      } else {
        this.showClearDate = false;
      }
    });
  }

  get data() {
    return this.formDatePicker.controls.data;
  }

  get inputData() {
    return this.formDatePicker.controls.inputData;
  }

  get disableControl() {
    if (this.controlDisable) {
      this.formDatePicker.controls.inputData.disable();
    } else {
      this.formDatePicker.controls.inputData.enable();
    }
    return this.controlDisable;
  }

  clearDate(): void {
    this.data.setValue(undefined);
    this.inputData.setValue(undefined);
    this.dataRetorno.emit(undefined);
  }

  setRequired(obrigatorio) {
    if (obrigatorio) {
      return [Validators.required];
    } else {
      return [];
    }
  }

}
