import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RamoCardComponent } from './ramo-card/ramo-card.component';


@NgModule({
    declarations: [RamoCardComponent],
    imports: [
        SharedModule
    ],
    exports: [RamoCardComponent]
})
export class ComponentsRamoModule { }
