import { Component, OnInit, OnDestroy } from '@angular/core';
import { RamoCombo, Ramo } from '@core/models/ramo.models';
import { ProdutoCard } from '@core/models/produto.models';
import { MatPageable } from '@core/table/mat-table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RamoService } from '@core/services/ramo.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProdutoService } from '@core/services/produto.service';
import { ConsultaService } from '@core/services/consulta.service';
import { FiltroProdutosUsuario } from '@core/models/consulta-metodo.models';
import { HttpStatusEnum } from '@core/http-interceptors/http-status';
import { StatusRamoEnum } from '@core/models/status-ramo.models';
import { HeaderSharedDataService } from '@shared/components/header/header-shared-data.service';
import { Subscription } from 'rxjs';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-favorito',
  templateUrl: './favorito.component.html',
  styleUrls: ['./favorito.component.scss']
})
export class FavoritoComponent implements OnInit, OnDestroy {

  ramos: RamoCombo[] = [];
  ramo: Ramo = {};
  idRamo: number;
  produtos: ProdutoCard[] = [];
  produtosFull: ProdutoCard[] = [];
  pageableProduto = new MatPageable(0, 12);
  pesquisaForm: FormGroup;
  totalPaginas: number = 0;
  filtro: FiltroProdutosUsuario;
  subScribeSearch: Subscription;

  constructor(private ramoService: RamoService,
    private activeRoute: ActivatedRoute,
    private produtoService: ProdutoService,
    private formBuilder: FormBuilder,
    private router: Router,
    private consultaService: ConsultaService,
    private headerSharedDataService: HeaderSharedDataService,
    private appSharedDataService: AppSharedDataService) { }

  ngOnInit() {

    this.filtro = { favorito: 1 };
    this.activeRoute.params.subscribe((params) => {
      this.idRamo = params.idRamo;
    })

    this.pesquisaForm = this.formBuilder.group({
      ramo: ['undefined']
    })

    this.ramoService.buscarTodosCombo(new MatPageable()).subscribe((data) => this.ramos = this.ramos.concat(data.filter(x => x.ativo == StatusRamoEnum.Ativo)))
    if (this.idRamo) {
      this.buscarDados(this.idRamo);
    }

    this.ramoCombo.valueChanges.subscribe(val => {
      if (val != undefined && val != "undefined" && val != this.idRamo) {
        this.pageableProduto.pagina = 0;
        this.pageableProduto.total = 0;
        this.buscarDados(val);
      }
      else {
        this.idRamo = undefined;
        this.filtro.idRamo = undefined;
        this.buscarDados();
      }
    })

    this.buscarDados();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        if (this.router.url.startsWith('/favorito'))
          this.buscarDados();
    })

    this.subScribeSearch = this.headerSharedDataService.search$.subscribe((val) => {
      if (this.router.url.indexOf('favorito') != -1)
        if (this.filtro.texto != val) {
          this.filtro.texto = val;
          this.pageableProduto.pagina = 0;
          this.pageableProduto.total = 0;
          this.buscarDados();
        }
    })

    this.headerSharedDataService.showSearchText(true);
  }

  ngOnDestroy(): void {
    this.subScribeSearch.unsubscribe();
  }

  disableRamo(ramo: RamoCombo): boolean {
    return this.idRamo && ramo && ramo.id == this.idRamo.toString();
  }

  get ramoCombo() {
    return this.pesquisaForm.controls.ramo;
  }

  buscarDados(idRamo?: number) {
    if (idRamo) {
      this.filtro.idRamo = idRamo;
      this.ramoService.getRamo(idRamo).subscribe((data) => this.ramo = data);
    }

    this.consultaService.getProdutosConsultaCrud(this.pageableProduto, this.filtro).subscribe((data) => {
      this.produtosFull = data;
      this.totalPaginas = Math.ceil(this.produtosFull.length / this.pageableProduto.registros);
      this.paginate();
    }, (error) => {
      if (error.status == HttpStatusEnum.NotFound) {
        this.produtos = [];
        this.produtosFull = [];
        this.totalPaginas = 0;
      }
    })
    if (this.idRamo != idRamo && idRamo != undefined)
      this.idRamo = idRamo;
  }

  nextPage(): void {
    if (!this.disableNextPage()) {
      this.pageableProduto.pagina++;
      this.paginate();
    }
  }

  backPage(): void {
    if (!this.disableBackPage()) {
      this.pageableProduto.pagina--;
      this.paginate();
    }
  }

  disableNextPage(): boolean {
    return this.pageableProduto.pagina == (this.totalPaginas - 1);
  }

  disableBackPage(): boolean {
    return this.pageableProduto.pagina == 0;
  }

  showNotFoundProdutos(): boolean {
    return this.produtosFull.length == 0;
  }

  paginate(): void {
    let start = this.pageableProduto.pagina * this.pageableProduto.registros;
    this.produtos = this.produtosFull.slice(start, start + this.pageableProduto.registros);
  }

  onDesFavorite(idProduto: number): void {
    this.consultaService.desFavoritarProduto(idProduto).subscribe((data) => this.buscarDados());
  }

  onFavorite(idProduto: number): void {
    this.consultaService.favoritarProduto(idProduto).subscribe((data) => this.buscarDados());
  }

  btnHome(): void {
    this.appSharedDataService.setResetMenuBootstrap(true);
    this.router.navigate(['/dashboard']);
  }
}
