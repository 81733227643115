import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alerta-apos-login',
  templateUrl: './modal-alerta-apos-login.component.html',
  styleUrls: ['./modal-alerta-apos-login.component.scss']
})
export class ModalAlertaAposLoginComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
  }

  fechar():void{
    this.activeModal.close();
  }

}
