import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { AuthenticationService } from '@core/auth/authentication.service';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService,
        private permissionsService: NgxPermissionsService) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authenticationService.isLoggedIn
            .pipe(
                take(1),
                map((isLoggedIn: boolean) => {
                    if (!isLoggedIn) {
                        this.router.navigate(['/login']);
                        return false;
                    }

                    if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.funcionalidades && Array.isArray(this.authenticationService.currentUserValue.funcionalidades))
                        this.permissionsService.loadPermissions(this.authenticationService.currentUserValue.funcionalidades);
                    let can = true;
                    if (route.data && route.data.roles && this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.funcionalidades && Array.isArray(this.authenticationService.currentUserValue.funcionalidades)) {
                        can = route.data.roles.some(x => this.authenticationService.currentUserValue.funcionalidades.some(z => z == x));
                        if (!can) {
                            this.router.navigate(['acessoNaoPermitido']);
                        }
                    }
                    return can;
                })
            );
    }
}