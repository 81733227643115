import { ProposalStatusEnum } from '../enumerators';
import { TipoCadastroEnum, TipoPropostaApoliceEnum } from '../enumerators/types';
import { IRest } from './index'

export interface IUsuarioCriacao {
    id: string,
    nome: string
}

export interface ICnae {
    divisaoId: number
}

export class PropostaVidaIndividual implements IRest {
    className = 'Classe de Proposta VI';

    id: string;
    associadaId: string;
    numeroProposta: string;
    status: ProposalStatusEnum;
    dataEmissao: Date;
    dataAceiteProposta: Date;
    segurado: Segurado;
    coberturas: Cobertura[];
    beneficiarios: Beneficiario[];
    responsavelPagamento: ResponsavelPagamento;
    tipoProdutoId: string
    motivoRecusaId: string
    tipoCadastro: TipoCadastroEnum;
    usuarioCriacao: IUsuarioCriacao;
    dataCriacao: Date;
    tipoPropostaApolice: TipoPropostaApoliceEnum;


    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            numeroProposta: this.numeroProposta,
            status: this.status,
            dataEmissao: new Date(this.dataEmissao).toISOString().split('T')[0],
            coberturas: this.coberturas.map(v => v.Serialize()),
            dataAceiteProposta: new Date(this.dataAceiteProposta).toISOString().split('T')[0],
            segurado: this.segurado ? this.segurado.Serialize() : undefined,
            tipoProdutoId: this.tipoProdutoId,
            motivoRecusaId: this.motivoRecusaId,
            tipoCadastro: this.tipoCadastro,
            // Validacao de nao obrigatorios
            responsavelPagamento: (this.responsavelPagamento.documento || this.responsavelPagamento.nome) ? this.responsavelPagamento.Serialize() : undefined,
            beneficiarios: this.beneficiarios[0].nome || this.beneficiarios[0].percentualParticipacao || this.beneficiarios[0].dataNascimento ?
                this.beneficiarios.map(v => {
                    return (v.nome || v.percentualParticipacao || v.dataNascimento) ? v.Serialize() : undefined
                }) : undefined,
        };
        return out;
    }

    SerializePatch() {
        const out = {
            status: this.status,
            dataEmissao: new Date(this.dataEmissao).toISOString().split('T')[0],
            coberturas: this.coberturas.map(v => v.Serialize()),
            dataAceiteProposta: new Date(this.dataAceiteProposta).toISOString().split('T')[0],
            segurado: this.segurado ? this.segurado.SerializePatch() : undefined,
            motivoRecusaId: this.motivoRecusaId,
            responsavelPagamento: (this.responsavelPagamento.documento || this.responsavelPagamento.nome) ? this.responsavelPagamento.Serialize() : undefined,
            beneficiarios: this.beneficiarios[0].nome || this.beneficiarios[0].percentualParticipacao || this.beneficiarios[0].dataNascimento ?
                this.beneficiarios.map(v => {
                    return (v.nome || v.percentualParticipacao || v.dataNascimento) ? v.Serialize() : undefined
                }) : undefined,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.id = data['id'] || undefined;
            this.numeroProposta = data['numeroProposta'] || '';
            this.status = data['status'] || null;
            this.dataEmissao = data['dataEmissao'] || new Date();
            this.coberturas = (data['coberturas'] || []).map((cobertura: Cobertura) => new Cobertura(cobertura));
            this.beneficiarios = (data['beneficiarios'] || [{}]).map((beneficiario: Beneficiario) => new Beneficiario(beneficiario));
            this.responsavelPagamento = new ResponsavelPagamento(data['responsavelPagamento'] || {});
            this.associadaId = data['associadaId'] || '123';
            this.dataAceiteProposta = data['dataAceiteProposta'] ? new Date(data['dataAceiteProposta']) : new Date();
            this.segurado = new Segurado(data['segurado'] || {});
            this.motivoRecusaId = data['motivoRecusaId'] || undefined;
            this.tipoCadastro = data['tipoCadastro'] || 'API_INDIVIDUAL';
            this.usuarioCriacao = data['usuarioCriacao'] || undefined;
            this.dataCriacao = data['dataCriacao'] ? new Date(data['dataCriacao']) : new Date();
            this.tipoPropostaApolice = data['tipoPropostaApolice'] || 'VI';
        }

        return this;
    }

}

export class PropostaVidaGrupo implements IRest {
    className = 'Classe de Proposta VG';

    id: string;
    associadaId: string;
    numeroProposta: string;
    status: ProposalStatusEnum;
    dataEmissao: Date;
    dataAceiteProposta: Date;
    empresa: Empresa;
    coberturas: Cobertura[];
    tipoProdutoId: string;
    motivoRecusaId: string;
    tipoCadastro: TipoCadastroEnum;
    usuarioCriacao: IUsuarioCriacao;
    dataCriacao: Date;
    tipoPropostaApolice: TipoPropostaApoliceEnum;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            numeroProposta: this.numeroProposta,
            status: this.status,
            dataEmissao: new Date(this.dataEmissao).toISOString().split('T')[0],
            coberturas: this.coberturas.map(v => v.Serialize()),
            dataAceiteProposta: new Date(this.dataAceiteProposta).toISOString().split('T')[0],
            empresa: this.empresa ? this.empresa.Serialize() : undefined,
            tipoProdutoId: this.tipoProdutoId,
            motivoRecusaId: this.motivoRecusaId,
            tipoCadastro: this.tipoCadastro,
            usuarioCriacao: this.usuarioCriacao,
        };
        return out;
    }

    SerializePatch() {
        const out = {
            status: this.status,
            dataEmissao: new Date(this.dataEmissao).toISOString().split('T')[0],
            coberturas: this.coberturas.map(v => v.Serialize()),
            dataAceiteProposta: new Date(this.dataAceiteProposta).toISOString().split('T')[0],
            empresa: this.empresa ? this.empresa.SerializePatch() : undefined,
            motivoRecusaId: this.motivoRecusaId,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.id = data['id'] || undefined;
            this.numeroProposta = data['numeroProposta'] || '';
            this.status = data['status'] || null;
            this.dataEmissao = data['dataEmissao'] || new Date();
            this.coberturas = (data['coberturas'] || []).map((cobertura: Cobertura) => new Cobertura(cobertura));
            this.associadaId = data['associadaId'] || '123';
            this.dataAceiteProposta = data['dataAceiteProposta'] ? new Date(data['dataAceiteProposta']) : new Date();
            this.empresa = new Empresa(data['empresa'] || {});
            this.tipoProdutoId = data['tipoProdutoId'] || undefined;
            this.motivoRecusaId = data['motivoRecusaId'] || undefined;
            this.tipoCadastro = data['tipoCadastro'] || 'API_INDIVIDUAL';
            this.usuarioCriacao = data['usuarioCriacao'] || undefined;
            this.dataCriacao = data['dataCriacao'] ? new Date(data['dataCriacao']) : new Date();
            this.tipoPropostaApolice = data['tipoPropostaApolice'] || 'VG';
        }

        return this;
    }

}

export class Segurado implements IRest {
    className = 'Classe de Segurado';

    nome: string;
    dataNascimento: Date;
    cpf: string;
    email: string;
    telefone: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            nome: this.nome,
            dataNascimento: new Date(this.dataNascimento).toISOString().split('T')[0],
            cpf: this.cpf ? this.cpf.toCPF() : null,
            email: this.email ? this.email : undefined,
            telefone: this.telefone ? this.telefone.removerCaracteresEspeciais() : undefined,
        };
        return out;
    }

    SerializePatch() {
        const out = {
            nome: this.nome,
            dataNascimento: new Date(this.dataNascimento).toISOString().split('T')[0],
            email: this.email ? this.email : undefined,
            telefone: this.telefone ? this.telefone.removerCaracteresEspeciais() : undefined,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.nome = data['nome'] || '';
            this.dataNascimento = data['dataNascimento'] ? new Date(data['dataNascimento']) : new Date();
            this.cpf = data['cpf'] || '';
            this.email = data['email'] || '';
            this.telefone = data['telefone'] || null;

        }
        return this;
    }
}

export class Empresa implements IRest {
    className = 'Classe de Empresa';

    razaoSocial: string;
    numeroColaboradores: string;
    quantidadeFiliais: number;
    cnae: ICnae
    cnpj: string


    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            razaoSocial: this.razaoSocial,
            numeroColaboradores: this.numeroColaboradores,
            quantidadeFiliais: this.quantidadeFiliais,
            cnae: this.cnae,
            cnpj: this.cnpj.toCNPJ(),
        };
        return out;
    }


    SerializePatch() {
        const out = {
            razaoSocial: this.razaoSocial,
            numeroColaboradores: this.numeroColaboradores,
            quantidadeFiliais: this.quantidadeFiliais,
            cnae: this.cnae,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.razaoSocial = data['razaoSocial'] || '';
            this.numeroColaboradores = data['numeroColaboradores'] || '';
            this.quantidadeFiliais = data['quantidadeFiliais'] || null;
            this.cnae = data['cnae'] || { divisaoId: null };
            this.cnpj = data['cnpj'] || '';
        }
        return this;
    }
}

export class ResponsavelPagamento implements IRest {
    className = 'Classe de Responsavel pelo Pagamento';

    nome: string;
    documento: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            nome: this.nome ? this.nome : undefined,
            documento: this.documento ? this.documento : undefined,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.nome = data['nome'] || '';
            this.documento = data['documento'] || '';
        }
        return this;
    }
}

export class Cobertura implements IRest {
    className = 'Classe de Cobertura';

    coberturaId: string; // adicionar enum de coberturaId
    capitalSegurado: string;
    vigenciaCobertura: Date;
    totalGrupoSegurado: number

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            coberturaId: this.coberturaId,
            capitalSegurado: this.capitalSegurado ? this.capitalSegurado.toString().removerCaracteresEspeciais() : null,
            vigenciaCobertura: new Date(this.vigenciaCobertura).toISOString().split('T')[0],
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.coberturaId = data['coberturaId'] || null;
            this.capitalSegurado = data['capitalSegurado'] || null;
            this.totalGrupoSegurado = data['totalGrupoSegurado'] || 1000;
            this.vigenciaCobertura = data['vigenciaCobertura'] ? new Date(data['vigenciaCobertura']) : new Date()
        }
        return this;
    }

}

export class Beneficiario implements IRest {
    className = 'Classe de Beneficiario';

    nome: string;
    dataNascimento: Date;
    percentualParticipacao: string;

    constructor(data?: any) {
        if (data) this.Deserialize(data);
    }

    Serialize() {
        const out = {
            nome: this.nome,
            dataNascimento: new Date(this.dataNascimento).toISOString().split('T')[0],
            percentualParticipacao: this.percentualParticipacao ? String(this.percentualParticipacao).replace(',', '.') : null,
        };
        return out;
    }

    Deserialize(data: any) {
        if (data) {
            this.nome = data['nome'] || '';
            this.dataNascimento = data['dataNascimento'] ? new Date(data['dataNascimento']) : null;
            this.percentualParticipacao = data['percentualParticipacao'] ? String(data['percentualParticipacao']).replace('.', ',') : '';
        }
        return this;
    }

}