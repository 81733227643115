import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly defaultAction: string = 'Ok';
  private readonly config: MatSnackBarConfig = {
    horizontalPosition: "right",
    verticalPosition: "top",
    duration: 3000
  }
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) { }

  default(message: string) {
    if(message)
      this.show(message, {
        ...this.config,
        panelClass: 'default-notification-overlay'
      })
  }

  info(message: string) {
    if(message)
      this.show(message, {
        ...this.config,
        panelClass: 'info-notification-overlay'
      })
  }

  success(message: string) {
    if(message)
      this.show(message, {
        ...this.config,
        panelClass: 'success-notification-overlay'
      })
  }

  warn(message: string) {
    if(message)
      this.show(message, {
        ...this.config,
        duration: 2500,
        panelClass: 'warning-notification-overlay'
      })
  }

  error(message: string, duration?: number) {
    if(message)
      this.show(message, {
        ...this.config,
        duration: duration ? duration : 3000,
        panelClass: 'error-notification-overlay',
      })
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    this.zone.run(() => this.snackBar.open(message, this.defaultAction, configuration))
  }
}
