import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ds-custom-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {


  @Input() collectionSize: number
  @Input() page: number = 1
  @Input() pageSize: number = 10


  @Output() pageEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  emitPage(page:number){
    this.pageEvent.emit(page)
  }

}
