import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[ngInit]'
})
export class NgInit {

  @Input() ngInit: () => any;
  ngOnInit() {
    if(typeof this.ngInit === 'function') { 
        this.ngInit(); 
    }
  }  
}
