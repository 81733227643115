import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'ds-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
  ],
})
export class MonthPickerComponent implements OnInit {

  date = new FormControl(moment());

  @Input() placeholder: string = ''
  @Input() ngModel: any;
  @Input() name: string = '';
  @Input() mask: string = '';
  @Input() id: string = ''
  @Input() label: string = '';
  @Input() value: string | Date = ''
  @Input() todayAsMaxDate: any = ''
  @Input() readonly: boolean = false
  @Input() opcional: any
  @Input() required: boolean = false

  @Output() ngModelChangeValue = new EventEmitter<any>()

  // Evento de validacao
  @Input() validateInputEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  dateInputValue: any // valor do input
  wasInputFocused: boolean = false; // foco do input
  wrongInput: boolean = false // formato invalido de data

  dateMask = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    guide: false,
    modelClean: true
  };

  constructor(
  ) { }

  ngOnInit() {
    if (this.todayAsMaxDate) {
      this.todayAsMaxDate = new Date()
    }

    this.validateInput()
  }

  formatDateInputValue(value: string | Date) {
    if(typeof value == 'string'){
      const month = parseInt(value.toString().split('/')[0])
      const year = parseInt(value.toString().split('/')[1])

      return new Date(year, month - 1)
    }

    return value
  }

  validateInput() {
    this.validateInputEvent.subscribe((res) => {
      this.wasInputFocused = true
    })
  }


  emitDate(dateEvent) {
    const dateValue = (dateEvent.value) ? (dateEvent.value['_d']) : null
    const formatedDate = `${dateValue.getMonth()}/${dateValue.getFullYear()}`
    this.ngModelChangeValue.emit(formatedDate)
  }

  setInputValue(value: string) {
    this.dateInputValue = value ? value : null
    this.wrongInput = this.dateInputValue == null ? false : this.wrongInput
  }

  validateFormat(dateValue: Date) {
    this.wrongInput = !dateValue ? true : false
  }


  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const dateValue = new Date(normalizedMonth['_d'])
    let month = dateValue.getMonth()
    month = month + 1
    const year = dateValue.getFullYear()
    const formatedDate = `${month < 10 ? '0' + month : month}/${year}`

    this.value = dateValue

    this.ngModelChangeValue.emit(formatedDate)

    datepicker.close();
  }

}