import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '@core/services/pdf.service';

@Component({
  selector: 'app-cnseg-updates',
  templateUrl: './cnseg-updates.component.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class CnsegUpdatesComponent implements OnInit {

  updateTemplates: { title: string, imgPath: string, date: string, description: string }[] = [
    {
      title: 'Atualização nos Alertas de Coincidências',
      description: 'Os alertas de coincidência enviados por e-mail não incluirão mais os dados de CPF/CNPJ.',
      date: '14 de novembro de 2024',
      imgPath: 'assets/images/dashboard/imagens/alertas-coicidencias.jpg',
    },
    {
      title: 'Novo Regulamento Soluções Tecnológicas.',
      description: 'Mudança foi na alínea "j", do item 4.1 - Cláusula 4ª.',
      date: '15 de agosto de 2024',
      imgPath: 'assets/images/dashboard/imagens/novo-regulamento.jpg',
    },
    {
      title: 'Nova Tabela de Valores CNseg 2024',
      description: 'Tabela com valores atualizados por solução.',
      date: '26 de julho de 2024',
      imgPath: 'assets/images/dashboard/imagens/tabela-de-valores-cnseg.jpg',
    },
    {
      title: 'Melhoria na tela de envio de lotes',
      description: 'Implementamos uma melhoria significativa na tela de envio de lotes do nosso Portal de Soluções.',
      date: '23 de julho de 2024',
      imgPath: 'assets/images/dashboard/imagens/envio-lotes.jpg',
    },
    {
      title: 'Migração do EV para o Portal de Soluções',
      description: 'Solução Enquadramento de Veículos (EV) será totalmente integrada ao nosso Portal de Soluções.',
      date: '28 de maio de 2024',
      imgPath: 'assets/images/dashboard/imagens/migracao-ev.jpg',
    },
    {
      title: 'Nova Tabela de Valores FENASEG 2024',
      description: 'Tabela com valores atualizados e com duas novas soluções disponíveis.',
      date: '15 de maio de 2024',
      imgPath: 'assets/images/dashboard/imagens/tabela-de-valores-fenaseg.jpg',
    },
    {
      title: 'Alteração nas consultas do SINOB',
      description: 'Estamos desativando o método Consulta por CPF na solução Sistema Nacional de Óbitos (SINOB).',
      date: '04 de março de 2024',
      imgPath: 'assets/images/dashboard/imagens/alteracao-sinob.jpg',
    },
    {
      title: 'Novidades - Dashboard de Seguros Auto',
      description: 'Confira o novo menu de navegação do Dashboard de Seguros Auto.',
      date: '26 de fevereiro de 2024',
      imgPath: 'assets/images/dashboard/imagens/dashboard-fev-2024.jpg',
    },
    {
      title: 'Nova Tabela de Valores de Reembolso 2024',
      description: 'Confira a nova tabela e integre as nossas soluções ao seu planejamento.',
      date: '26 de janeiro de 2024',
      imgPath: 'assets/images/dashboard/imagens/tabela-de-valores-reembolso.jpg',
    },
    {
      title: 'Novidades - Dashboard de Seguros Auto',
      description: 'Nova página Mercado Potencial implementada ao Dashboard de Seguros Auto.',
      date: '22 de janeiro de 2024',
      imgPath: 'assets/images/dashboard/imagens/dashboard-jan-2024.jpg',
    },
    {
      title: 'Novidades - Dashboard de Seguros Auto',
      description: 'Foram implementados novos filtros ao produto Dashboard de Seguros Auto.',
      date: '06 de dezembro de 2023',
      imgPath: 'assets/images/dashboard/imagens/dashboard-dez-2023.jpg',
    },
  ];

  currentUpdateIndex: number

  constructor(private _pdfService: PdfService, private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.currentUpdateIndex = parseInt(params.id)
      }
    })
  }

  exportPDF() {
    this._pdfService.beforeCanvas('updateContainer');
    window.onafterprint = () => {
      this._pdfService.afterCanvas('updateContainer');
    }
    window.print();
  }

}



// Componentes HTML

@Component({
  selector: 'app-update-1',
  templateUrl: './templates/1-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateOneComponent {
}

@Component({
  selector: 'app-update-2',
  templateUrl: './templates/2-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateTwoComponent {
}

@Component({
  selector: 'app-update-3',
  templateUrl: './templates/3-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateThreeComponent {
}

@Component({
  selector: 'app-update-4',
  templateUrl: './templates/4-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateFourComponent {
}

@Component({
  selector: 'app-update-5',
  templateUrl: './templates/5-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateFiveComponent {
}

@Component({
  selector: 'app-update-6',
  templateUrl: './templates/6-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateSixComponent {
}

@Component({
  selector: 'app-update-7',
  templateUrl: './templates/7-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateSevenComponent {
}

@Component({
  selector: 'app-update-8',
  templateUrl: './templates/8-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateEightComponent {
}

@Component({
  selector: 'app-update-9',
  templateUrl: './templates/9-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateNineComponent {
}

@Component({
  selector: 'app-update-10',
  templateUrl: './templates/10-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateTenComponent {
}

@Component({
  selector: 'app-update-11',
  templateUrl: './templates/11-update.html',
  styleUrls: ['./cnseg-updates.component.scss']
})
export class UpdateElevenComponent {
}
