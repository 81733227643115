import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { PageableResponse } from '@core/table/mat-table';
import { environment } from '@env/environment';
import { DadosUsuario } from '@core/models/dados-usuario';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

    constructor() { }

    private isAuthenticated(request: HttpRequest<any>): boolean {
        return request.headers.get('Authorization') === 'Bearer fake-jwt-token';
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



        return of(null).pipe(() => {

            if (!environment.mock) {
                return next.handle(request);
            }

            if (request.url.endsWith('/oauth/token') && request.method === 'POST') {

                let body = {
                    access_token: 'fake-jwt-token'
                };

                return of(new HttpResponse({ status: 200, body: body }));
            }

            if (!this.isAuthenticated(request)) {
                return throwError(new HttpErrorResponse({ status: 401 }));
            }

            if (request.url.endsWith('/api/meusDados') && request.method === 'GET') {


                let body = new DadosUsuario();
                body.id = 1;
                body.nome = 'Desenvolvedor';
                body.perfil = 'Admin';
                body.acessos = ['Admin'];

                return of(new HttpResponse({ status: 200, body: body }));
            }
   
            return next.handle(request);

        })
    }
}

export let fakeBackendProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};