import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private _toastr: ToastrService) {}

  showSuccess(text) {
    this._toastr.show(
      `<div class="d-flex align-items-center justify-content-between h-100" style="padding-left: 16px;">
        <i class="uil uil-check-circle" style="font-size: 20px !important; color: #00A955 !important"></i>
        <div class="col d-flex flex-column justify-content-center">
          <div class="toast-title">
            SUCESSO!
          </div>
          <div class="toast-text">
            ${text}
          </div>
        </div>
      </div>`,
      "",
      {
        toastClass: "toast success",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }

  showError(text) {
    this._toastr.show(
      `<div class="d-flex align-items-center justify-content-between h-100" style="padding-left: 16px;">
        <i class="uil uil-exclamation-circle"></i>
        <div class="col d-flex flex-column justify-content-center">
          <div class="toast-title">
            Ops! Não foi possível cadastrar.
          </div>
          <div class="toast-text">
            ${text}
          </div>
        </div>
      </div>`,
      "",
      {
        toastClass: "toast error",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }

  showDelete(text) {
    this._toastr.show(
      "<i class='fas fa-fw fa-triangle-exclamation toast-icon'></i>",
      text,
      {
        toastClass: "toast error",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }

  showInfo(text) {
    this._toastr.show(
      "<i class='fas fa-fw fa-circle-info toast-icon'></i>",
      text,
      {
        toastClass: "toast info",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }

  showConsultaError(text) {
    this._toastr.show(
      `<div class="d-flex align-items-center justify-content-between h-100" style="padding-left: 16px;">
        <i class="uil uil-exclamation-circle"></i>
        <div class="col d-flex flex-column justify-content-center">
          <div class="toast-title">
            Ops! Ocorreu algum erro durante a consulta.
          </div>
          <div class="toast-text">
            ${text}
          </div>
        </div>
      </div>`,
      "",
      {
        toastClass: "toast error",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }

  showCadastroError(text) {
    this._toastr.show(
      `<div class="d-flex align-items-center justify-content-between h-100" style="padding-left: 16px;">
        <i class="uil uil-exclamation-circle"></i>
        <div class="col d-flex flex-column justify-content-center">
          <div class="toast-title">
            Ops! Não foi possível cadastrar.
          </div>
          <div class="toast-text">
            ${text}
          </div>
        </div>
      </div>`,
      "",
      {
        toastClass: "toast error",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }

  showGetError(text) {
    this._toastr.show(
      `<div class="d-flex align-items-center justify-content-between h-100" style="padding-left: 16px">
        <i class="uil uil-exclamation-circle"></i>
        <div class="col d-flex flex-column justify-content-center">
          <div class="toast-title">
            Ops! Não foi encontrado nenhum registro com esses dados.
          </div>
          <div class="toast-text">
            ${text}
          </div>
        </div>
      </div>`,
      "",
      {
        toastClass: "toast error",
        positionClass: "toast-top-right",
        enableHtml: true,
        timeOut: 3000,
      }
    );
  }
}
