import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CustomNgbDateAdapter {
    fromModel(value: Date | string): NgbDateStruct {


        if (value) {

            if (value instanceof Date) {
                let model = {
                    year: value.getFullYear(),
                    month: value.getMonth(),
                    day: value.getDay()
                }

                return model;
            } else if (moment(value,'YYYY-MM-DD',true).isValid()) {
                let dt =moment(moment(value).format('DD/MM/YYYY'),'DD/MM/YYYY',true).toDate();
                return {
                    year: dt.getFullYear(),
                    month: dt.getMonth() + 1,
                    day: dt.getDate()
                }
            }
            else if (moment(value,'DD/MM/YYYY',true).isValid()) {
                let dt =moment(moment(value).format('DD/MM/YYYY'),'DD/MM/YYYY',true).toDate();
                return {
                    year: dt.getFullYear(),
                    month: dt.getMonth() + 1,
                    day: dt.getDate()
                }
            }
            else if (moment(value,'DD-MM-YYYY',true).isValid()) {
                let dt = moment(moment(value).format('DD-MM-YYYY'),'DD-MM-YYYY',true).toDate();
                return {
                    year: dt.getFullYear(),
                    month: dt.getMonth() + 1,
                    day: dt.getDate()
                }
            }
        }


        return null;
    }
    toModel(date: NgbDateStruct): any {

        if (date)
            return `${date.year}-${this.padNumber(date.month)}-${this.padNumber(date.day)}`;


        return '';
    }
    padNumber(num: number) {
        if (num < 10)
            return `0${num}`;

        return num;
    }
}