import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ParametroPortal } from '@core/models/parametro-portal.models';
import { ParametroPortalService } from '@core/services/parametro-portal.service';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { ConfirmSinobProcessamentoDialogData, NumerosTempoProcessamento, TempoProcessamentoEnum } from './confirm-sinob-processamento-dialog';

@Component({
  selector: 'app-confirm-sinob-processamento-dialog',
  templateUrl: './confirm-sinob-processamento-dialog.component.html',
  styleUrls: ['./confirm-sinob-processamento-dialog.component.scss']
})
export class ConfirmSinobProcessamentoDialogComponent extends BaseDialogComponent implements OnInit {

  confirmSinobProcessamentoDialogForm: FormGroup;
  data: any;
  numeros: NumerosTempoProcessamento[] = [];
  parametroPortal: ParametroPortal;
  idParametro = 24;
  formSubmitted: boolean = false;

  constructor(public dialog: NgbActiveModal,
    private formBuilder: FormBuilder,
    private parametroPortalService: ParametroPortalService) {
    super(dialog)
  }

  ngOnInit() {
    this.confirmSinobProcessamentoDialogForm = this.formBuilder.group({
      tempoProcessamento: [TempoProcessamentoEnum.Cinco, Validators.required],
    });

    this.numerosDias();
    this.parametroPortalService.getParametroPortal(this.idParametro).subscribe((data) => this.parametroPortal = data);
  }

  ok(): void {
    if (this.confirmSinobProcessamentoDialogForm.valid) {
      this.formSubmitted = !this.formSubmitted;
      let data = <ConfirmSinobProcessamentoDialogData>this.confirmSinobProcessamentoDialogForm.value;
      this.dialog.close(data);
    }
  }

  close(): void {
    this.cancel();
  }

  cancel(): void {
    this.dialog.close(false);
  }

  @HostListener('blur') onBlur(event: KeyboardEvent) {
    this.cancel();
  }

  numerosDias() {
    for (let i = 1; i <= 5; i++) {
      let dia = i > 1 ? 'dias' : 'dia';
      let obj = { nome: `${i} ${dia}`, valor: i };
      this.numeros.push(obj);
    }
  }
}
