import { FormControl } from "@angular/forms";
import { validarRegraChassi } from "@core/config/regras-chassi-config";


export function chassiValidator(control: FormControl) {  
    
    if(control.value != undefined && control.value != null && control.value != ''){      
        let validacao = validarRegraChassi(control.value);
        const erros = validacao.erros[0] 
        const objErro = {
            chassi: erros ? erros : true
        }
        const msgErro = objErro.chassi == true ? null : objErro
        return msgErro;
    } 
    return null
}