import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-container-prop',
  templateUrl: './dash-container-prop.component.html',
  styleUrls: ['./dash-container-prop.component.scss']
})
export class DashContainerPropComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
