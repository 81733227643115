import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export interface IEmailBody {
  interest: string,
  subject: string,
  segment: string,
  areaOfActivity: string,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class EmailMktService {

  consultaUrl = environment.apiGatewayDns

  private _emailUrl = '/lote/dashboard/cotacao'

  constructor(private _httpClient: HttpClient) { }

  sendEmail(emailBody: IEmailBody){
    const url = this.consultaUrl + this._emailUrl
    return this._httpClient.post(url, emailBody)
  }
}
