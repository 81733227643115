import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

export enum CustomHeaderEnum {
  Default = 'application/json',
  Text = 'Text',
  FormData = 'FormData',
}

@Injectable({
  providedIn: 'root'
})
export class RnpaRestInterface {

  constructor(
    public http: HttpClient
  ) { }

  post(url: string, body: any, params?: HttpParams) {
    return this.http.post(url, body, { headers: this.getRnpaHeaders(), params: params });
  }

  postTextCsv(url: string, body: any, params?: HttpParams) {
    return this.http.post(url, body, { headers: this.getRnpaHeaders(CustomHeaderEnum.Text), params: params, responseType: 'text' });
  }

  postTextBlob(url: string, body: any, params?: HttpParams) {
    return this.http.post(url, body, { headers: this.getRnpaHeaders(CustomHeaderEnum.Text), params: params, responseType: 'blob', observe: 'response' });
  }

  postFormData(url: string, body: FormData) {
    return this.http.post(url, body, { headers: this.getRnpaHeaders(CustomHeaderEnum.FormData) });
  }

  get(url: string, params?: HttpParams) {
    return this.http.get(url, { headers: this.getRnpaHeaders(), params: params });
  }

  getTextCsv(url: string, params?: HttpParams) {
    return this.http.get(url, { headers: this.getRnpaHeaders(CustomHeaderEnum.Text), params: params, responseType: 'text' });
  }

  getFullResponse(url: string, params?: HttpParams) {
    return this.http.get(url, { headers: this.getRnpaHeaders(), params: params, observe: 'response' });
  }

  put(url: string, body: any, params?: HttpParams) {
    return this.http.put(url, body, { headers: this.getRnpaHeaders(), params: params });
  }

  patch(url: string, body: any, params?: HttpParams) {
    return this.http.patch(url, body, { headers: this.getRnpaHeaders(), params: params });
  }

  del(url: string, params?: HttpParams) {
    return this.http.delete(url, { headers: this.getRnpaHeaders(), params: params });
  }

  getRnpaHeaders(type?: CustomHeaderEnum) {
    if (!type) {
      return new HttpHeaders({ 
        'rnpa_req': 'true' 
      })
    }

    if (type == CustomHeaderEnum.FormData) {
      return new HttpHeaders({
        'rnpa_req': 'true',
        FormData: 'true',
      });
    }

    return new HttpHeaders({
      'rnpa_req': 'true',
      Text: 'true',
    });
  }

}