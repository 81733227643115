import { FormControl } from "@angular/forms";
import { validarRegraPlaca } from "@core/config/regras-placa-config";


export function placaValidator(control: FormControl) {

    if(control.value != undefined && control.value != null && control.value != ''){      
        let validacao = validarRegraPlaca(control.value);
        const erros = validacao.erros[0] 
        const objErro = {
            placa: erros ? erros : true
        }
        const msgErro = objErro.placa == true ? null : objErro
        return msgErro;
    } 
    return null
}
