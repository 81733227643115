import { number } from 'ng-brazil/number/validator';
import { MatPageable } from '@core/table/mat-table';
import { ParametroMetodoCrud } from './parametro-metodo.models';

export class ConsultaMetodo {
    nomeProduto: string;
    descricaoResumidaProduto: string;
    nomeMetodo: string;
    outrosMetodos: OutroMetodo[];
    linkManualOperacional: string;
    ramos: any;
}

export class OutroMetodo {
    dsMetodo: string;
    id: number;
    nome: string;
    permitePortal:boolean;
}


export class AgrupamentoProdutoUsuario {
    produto: ProdutoUsuario[]
}

export class ProdutoUsuario {
    idProduto: number;
    nomeProduto: string;
    dsServico: null;
    descricaoResumida: string;
    descricaoDetalhada: string;
    indFavorito: number;
    metodos: DetalheMetodoProduto[] | DetalheMetodoProduto;;
    imgProduto: string;
    paginacao?: MatPageable;
}


export class DetalheMetodoProduto {
    idMetodo: number;
    nomeMetodo: string;
    dsMetodo: string;
}


export class AgrupamentoDetalheConsulta {
    parametrosMetodo: DetalheConsulta;
    template?:string;
}

export class DetalheConsulta {
    idProduto?: number;
    idMetodo?: number;
    idUsuario?: number;
    parametrosEntrada: {
        parametros: ParametroDetalheConsulta[]
    }
    parametrosSaida: {
        parametros: ParametroDetalheConsulta[]
    }
    parametrosErro: {
        parametros: ParametroDetalheConsulta[]
    }
}

export class ParametroDetalheConsulta {
    parametroPortal: string;
    parametroServico: string;
    parametroServicoPai: string;
    idTipoDado: number;
    indObrigatorio: number;
    visivel: number;
    ordemExibicao: string;
    filhos?: ParametroDetalheConsulta[] = [];
    dominio:string;
}


export class ConsultaEnviar {
    idUsuario: number;
    dsServico: string;
    dsMetodo: string;
    parametros: ParametroConsultaEnviar[] = [];
}

export class ParametroConsultaEnviar {
    nome: string;
    valor: string;
}

export class RetornoConsulta {
    hasError: boolean;
    msg: string;
    success: true
    resultado: any;
    tpServico: string;
    protocolo:string;
}


export class FiltroProdutosUsuario {
    idRamo?: number;
    favorito?: number;
    texto?: string;
}


export class AgrupamentoParametros{
    parametrosEntrada:  ParametroMetodoCrud[];
    parametrosSaida: ParametroMetodoCrud[];
    parametrosErro:  ParametroMetodoCrud[];
}