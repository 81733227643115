import { MatPageable } from '@core/table/mat-table';

export class EmpresaCombo {
    id: number;
    nome: string;
    ativo:number;
    idEmpresa:string;
}

export class EmpresaElement {
    cnpj: string;
    cnseg: number;
    codigo: number;
    endereco: string;
    idEmpresa: number;
    idGrupoEconomico: number;
    idStatus: string;
    idTipoEmpresa: number;
    nome: string;
    nomeFantasia: string;
    nomeGrupoEconomico: string;
    nomeStatus: string;
    tipoEmpresa: string;
    paginacao?:MatPageable;
}


export class Empresa {
    id: number;
    nome: string;
}

export class FiltroEmpresa {
    nome: string;
    cnpj: string;
    endereco: string;
    idTipoEmpresa: number;
    idGrupoEconomico: number;
    idStatus: number;
}

export class EmpresaCadastro{
    codigo: string;
    nome:string;
    nomeFantasia: string;
    idTipoEmpresa: number;
    idStatus: number;
    idGrupoEconomico: number;
    cnpj: string;
    endereco: string;
    idUsuarioCadastro: number;
    idEmpresa: any;
    cnseg:number;
    centrosCusto:any[];
    ips:any[];
    chavePowerBI: any;
}


export enum EmpresaEnum{
    CNSeg = '1'
}

export class EmpresaIP{
    ip:string;
    idEmpresa:number;;
}
