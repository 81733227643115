import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { BaseComponent } from '@core/component/base.component';
import { Masks } from '@core/custom-validators/masks';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApoliceStatusEnum } from '../enumerators';
import { TipoPropostaApoliceEnum } from '../enumerators/types';
import { ApoliceVidaGrupo, ApoliceVidaIndividual, Beneficiario, Cobertura, Empresa, Segurado, TabHeader } from '../models';
import { CoberturaResponse } from '../models/ramos-coberturas';
import { ApoliceService } from '../services/apolice.service';
import { RamosCoberturasService } from '../services/ramos-coberturas.service';
import { TiposProdutoService } from '../services/tipos-produto.service';
import { ToastService } from '../services/toast.service';
import { CnaeService } from '../services/cnae.service';

@Component({
  selector: 'app-cadastrar-apolice',
  templateUrl: './cadastrar-apolice.component.html',
  styleUrls: ['./cadastrar-apolice.component.scss', './../styles.scss']
})
export class CadastrarApoliceComponent extends BaseComponent implements OnInit {

  // Obrigatorio em custom products
  cadastro: { nomeProduto: string, nomeMetodo: string } = {
    nomeProduto: 'RNPA Pessoas',
    nomeMetodo: 'Cadastrar Apolice'
  }
  // Main body de apolice
  apolice: ApoliceVidaIndividual | ApoliceVidaGrupo = new ApoliceVidaIndividual({ coberturas: [new Cobertura({})], beneficiarios: [new Beneficiario({})] })

  // Listagem de coberturas, tipos de produtos e status de apolice
  coberturas: CoberturaResponse[]
  tiposProdutos: any[]
  motivosRecusa: any[]
  apoliceStatusOptions = Object.values(ApoliceStatusEnum)
  divisoesCnae: any[]


  // Seta os Tabs que trocam o formulario entre VI e VG
  tabHeaders: TabHeader[] = [
    { name: 'Cadastrar apólice de CPF', active: true, type: TipoPropostaApoliceEnum.Vi },
    { name: 'Cadastrar apólice de CNPJ', active: false, type: TipoPropostaApoliceEnum.Vg },
  ]

  // enums para HTML
  apoliceStatusEnum = ApoliceStatusEnum

  // Masks
  inputMasks = {
    cnpj: {
      mask: Masks.cnpj,
      guide: false,
      modelClean: true
    },
    cpf: {
      mask: Masks.cpf,
      guide: false,
      modelClean: true
    },
    cpfCnpj: {
      mask: Masks.cpfCnpj,
      guide: false,
      modelClean: true
    },
    telefone: {
      mask: Masks.telefone,
      guide: false,
      modelClean: true
    },
    number: {
      mask: Masks.integer,
      guide: false,
      modelClean: true
    },
    decimal: {
      mask: Masks.decimal,
      guide: false,
      modelClean: true
    },
    currency: {
      mask: Masks.currency,
      guide: false,
      modelClean: true
    },
    cnae: {
      mask: Masks.cnae,
      guide: false,
      modelClean: true
    },
    char: {
      mask: Masks.char,
      guide: false,
      modelClean: true
    },
  }

  // Dicionario de Ids
  idDictionary = {
    coberturas: {},
    motivosRecusa: {},
    tiposProduto: {},
    divisoesCnae: {},

  }

  @Input() idProduto: number = 89;
  @Input() idMetodo: number = 1937;
  @Input() dsServico: string;
  @Input() dsMetodo: string;
  @Input() disabled: any;
  @Input() consulta: any;

  // Icons
  faTrash = faTrash

  validateInputEvent = new EventEmitter<boolean>()

  constructor(
    public authenticationService: AuthenticationService,
    private _toastService: ToastService,
    private _router: Router,
    private _ramosCoberturasService: RamosCoberturasService,
    private _apoliceService: ApoliceService,
    private _tiposProdutoService: TiposProdutoService,
    private _cnaeService: CnaeService

  ) {
    super(authenticationService)
  }

  ngOnInit() {
    this.fillSelectOptions()
    this.handleTabState(this.tabHeaders)
  }

  fillSelectOptions() {
    this._ramosCoberturasService.listCoberturas().subscribe((res: CoberturaResponse[]) => {
      this.coberturas = res.sort((a,b) => {
        return a.cobertura > b.cobertura ? 1 : a.cobertura < b.cobertura ? -1 : 0
      })
      this.idDictionary.coberturas = res.reduce((obj, motivo) => {
        obj[motivo.id] = motivo.cobertura;
        return obj;
      }, {});
    })

    this._tiposProdutoService.listarTiposProduto().subscribe((res: any[]) => {
      this.tiposProdutos = res
    })

    this._apoliceService.listarMotivoRecusa().subscribe((res: any[]) => {
      this.motivosRecusa = res
    })

    this._cnaeService.listarDivisoesCnae().subscribe((res: any[]) => {
      this.divisoesCnae = res.sort((a, b) => {
        return parseInt(a.divisaoId) > parseInt(b.divisaoId) ? 1 : parseInt(a.divisaoId) < parseInt(b.divisaoId) ? -1 : 0
      });

      this.idDictionary.divisoesCnae = res.reduce((obj, cnae) => {
        obj[cnae.divisaoId] = cnae.descricao;
        return obj;
      }, {});
    })
  }

  handleTabState(event: TabHeader[]) {
    const eventTypeValue = event.find(i => i.active).type
    this.tabHeaders = event
    if (eventTypeValue == TipoPropostaApoliceEnum.Vi) {
      this.apolice = new ApoliceVidaIndividual({ tipoApoliceApolice: eventTypeValue, coberturas: [new Cobertura({})], beneficiarios: [new Beneficiario({})], segurado: new Segurado({}) })
    } else {
      this.apolice = new ApoliceVidaGrupo({ tipoApoliceApolice: eventTypeValue, coberturas: [new Cobertura({})], empresa: new Empresa({}) })
    }

  }

  // Adiciona cobertura na apolice
  addCoverage() {
    this.apolice.coberturas.push(new Cobertura({}))
  }

  // Adiciona beneficiario na apolice
  addRecipient() {
    this.apolice['beneficiarios'].push(new Beneficiario({}))
  }

  // Remove cobertura na apolice
  removeCoverage(index: number) {
    this.apolice.coberturas.splice(index, 1)
  }

  // Remove beneficiario na apolice
  removeRecipient(index: number) {
    this.apolice['beneficiarios'].splice(index, 1)
  }

  // Envia cadastro
  submitApolice() {
    this.validateInputEvent.emit(true)

    const tipoProduto = this.tabHeaders.find(i => i.active).type
    this._apoliceService.createApolice(this.apolice, tipoProduto).subscribe((res) => {
      this._toastService.showSuccess(`A apolice foi cadastrada com sucesso.`);
      this._router.navigate([('/consulta/89/1939/consultaRNPAPessoas/Consultar%20Apolice?favorito=false')])
    },
      (err) => {
        const errorMsg = err.status == 422 ?
          (err.error.mensagem) :
          (err.status == 400 && err.error[0].mensagem.includes("`beneficiarios[0].percentualParticipacao`") ?
            String(err.error[0].mensagem).replace("`beneficiarios[0].percentualParticipacao`", "porcentagem de participação") :
            "Verifique os campos inválidos");

        this._toastService.showError(errorMsg)
      })
  }

  calcTotalSegurado(colaboradores: string, valorSegurado: string) {
    return parseInt(colaboradores) * parseInt(valorSegurado.replace(/\D/g, ''))
  }

  goToLote(){
    this._router.navigate([('lote/cadastrar')])
  }

}
