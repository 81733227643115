import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { FavoritoComponent } from './pages/favorito/favorito.component';

const routes: Routes = [
  {
    path: 'favorito',
    canActivate: [AuthGuardService],
    component: FavoritoComponent,
    data: {
      reuse: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FavoritoRoutingModule { }
