import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { Field } from '@shared/components/dynamic-form/models/field.interface';

@Component({
  selector: 'app-label-result',
  styleUrls: ['./label-result.component.scss'],
  template: `
  <div>
     <span>{{ config.label }}:</span>
     <label>{{ value }}</label>
  </div>
`
})
export class LabelResultComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  get value() {
    if (this.config.options) {
      let val = this.config.options.find(x => x.valor == undefined || x.valor == this.config.value);
      if (val)
        return val.nome;
    }
    return this.config.value;
  }
}

