import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';

@Component({
  selector: 'form-select',
  styleUrls: ['form-select.component.scss'],
  template: `
    <div class="form-group col-md" [formGroup]="group"  [style.display]="config.visible?'block':'none'">
          <label for="{{config.name}}" [class.required]="config.required">{{ config.label }}</label>
          <ng-select class="input-sm" notFoundText="Nenhum registro encontrado"
            placeholder="{{ config.placeholder }}" [formControlName]="config.name">
            <ng-option *ngFor="let option of config.options" [value]="option.valor">
              {{option.nome}}
            </ng-option>
          </ng-select>
        </div>
  `
})
export class FormSelectComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}