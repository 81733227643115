// validar regra placa Brasil e Mercosul // KPG9W47
const validacaoRegraPlaca = (value) => value.match('[a-zA-Z]{3}[0-9][a-jA-J0-9][0-9]{2}');

// Validar se tem espaco
const verificaEspaco = (value) => /\s/g.test(value);

let erros: any[]=[];
let infos: any[]=[];
let mensagemErro = (string) => erros.push(string);
let mensagemInfo = (string) => infos.push(string);

//Validacao Geral
export const validarRegraPlaca = (value) => {
  erros = [];
  infos = [];
  let placaEnviada = value;
  let totalLetras = placaEnviada.length;

  // verifica se possui erro
  let validacaoComErroPlaca = !value || value.includes('_') || !validacaoRegraPlaca(value) || verificaEspaco(value);

  if(value.includes('_')){
    value = value.replace('_', "")
  }

  // validações
  if (validacaoComErroPlaca) {
    value.slice(0,totalLetras).length < 7 ? mensagemErro('Placa inválida, menor que 7 caracteres!') : value;

    value.slice(0,totalLetras).length > 7 ? mensagemErro('Placa inválida, maior que 7 caracteres!') : value;

    verificaEspaco(value) ? mensagemErro('Placa inválida pois possui espaço!') : value;

    !validacaoRegraPlaca(value)? mensagemErro('Placa inválida, para padrão mercosul, quinto caractere apenas de A a J!') : value

    placaEnviada === value ? placaEnviada = undefined : placaEnviada = value;
  }
  let isValid;
  (erros.length) ? isValid = false : isValid = true;

  let objRetono = {
    erros: erros,
    infos: infos,
    valida: isValid
  }

  return objRetono;
}

export class RegraPlaca { }
