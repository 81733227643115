import { Component, OnInit, Inject } from '@angular/core';
import { HttpResponseValidation } from '@core/http-interceptors/http-error';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bad-request-dialog',
  templateUrl: './bad-request-dialog.component.html',
  styleUrls: ['./bad-request-dialog.component.scss']
})
export class BadRequestDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: HttpResponseValidation[],
    private matDialogRef: MatDialogRef<BadRequestDialogComponent>) { }

  ngOnInit() {
  }

  close(): void {
    this.matDialogRef.close();
  }
}
