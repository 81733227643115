import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RnpaRestInterface } from './rnpa-rest-interface.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CnaeService extends RnpaRestInterface  {

  consultaUrl = environment.apiGatewayDns

  private _cnaeDivisoesUrl = '/rnpa-pessoas/v1/cnae/divisoes?page=1&limit=100'

  constructor(
    public http: HttpClient
  ) { 
    super(http)
  }
  
  // GET coberturas
  listarDivisoesCnae(){
    const url = this.consultaUrl + this._cnaeDivisoesUrl
    return this.get(url)
  }
}