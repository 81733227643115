import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { MatPageable } from '@core/table/mat-table';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, AfterViewInit {

  paginaAtual: number = 1;
  paginas: Pagina[] = [];
  paginasVisiveis: Pagina[];
  @Input()
  totalVisiveis: number = 5;

  private _totalItens;
  get totalItens(): any {
    return this._totalItens;
  }
  @Input()
  set totalItens(val: any) {
    this._totalItens = val;
    this.atualizarPaginacao();
  }

  @Input()
  itemsPorPagina: number[] = [5, 10, 20, 50,100];

  @Input()
  itemPorPagina: number;

  totalPaginas: number;
  @Input()
  exibirPaginacao: boolean = true;

  @Output() change = new EventEmitter<MatPageable>();


  constructor(private autenticationService: AuthenticationService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {


    if (this.itemsPorPagina && this.itemsPorPagina.length && !this.itemPorPagina) {
      this.itemPorPagina = this.itemsPorPagina[0];
    }

    this.calcularPaginas();
    this.atualizarPaginasVisiveis();
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  atualizarPaginacao(): void {
    this.calcularPaginas();
    this.atualizarPaginasVisiveis();
  }

  calcularPaginas(): void {

    let totalPaginas = Math.ceil(this.totalItens / this.itemPorPagina);
    this.totalPaginas = totalPaginas;

    this.paginas.length = 0;

    for (let i = 1; i <= totalPaginas; i++) {
      let pagina = new Pagina();
      pagina.numero = i;
      pagina.ativa = i == this.paginaAtual;
      pagina.primeira = i == 1;
      pagina.ultima = i == totalPaginas;
      this.paginas.push(pagina);
    }
  }

  atualizarPaginasVisiveis(): void {

    if (this.totalVisiveis >= this.paginas.length) {
      this.paginasVisiveis = this.paginas;
    } else {
      let start = 0;
      let end = 0;

      if (this.totalVisiveis / 2 == 1) {
        start = end = this.totalVisiveis / 2;
      }
      else {
        start = Math.round(this.totalVisiveis / 2);
        end = this.totalVisiveis - start
      }

      start = this.paginaAtual - start;
      if (start < 0)
        start = 0;

      if (start == 0)
        end = this.totalVisiveis;
      else
        end = this.paginaAtual + end;

      if (end > this.paginas.length) {
        end = this.paginas.length;
        start = end - this.totalVisiveis;
      }
      this.paginasVisiveis = this.paginas.slice(start, end);
    }
  }

  alterarPagina(pagina: Pagina): void {
    this.inativarPaginas();
    this.paginaAtual = pagina.numero;
    pagina.ativa = true;
    this.change.emit(this.pagination);
  }

  inativarPaginas(): void {
    this.paginas.forEach(x => x.ativa = false);
  }

  proximaPagina(): void {
    let pagina = this.paginas.find(x => x.numero == this.paginaAtual + 1);
    if (pagina) {
      this.paginaAtual = pagina.numero;
      this.inativarPaginas();
      pagina.ativa = true;
      this.atualizarPaginasVisiveis();
      this.change.emit(this.pagination);
    }
  }

  paginaAnterior(): void {
    let pagina = this.paginas.find(x => x.numero == this.paginaAtual - 1);
    if (pagina) {
      this.paginaAtual = pagina.numero;
      this.inativarPaginas();
      pagina.ativa = true;
      this.atualizarPaginasVisiveis();
      this.change.emit(this.pagination);
    }
  }

  primeiraPagina(): void {
    let pagina = this.paginas[0];
    if (pagina) {
      this.inativarPaginas();
      this.paginaAtual = pagina.numero;
      pagina.ativa = true;
      this.atualizarPaginasVisiveis();
      this.change.emit(this.pagination);
    }
  }

  ultimaPagina(): void {
    let pagina = this.paginas[this.paginas.length - 1];
    if (pagina) {
      this.inativarPaginas();
      this.paginaAtual = pagina.numero;
      pagina.ativa = true;
      this.atualizarPaginasVisiveis();
      this.change.emit(this.pagination);
    }
  }

  get isPrimeiraPagina(): boolean {
    return this.paginaAtual == 1;
  }

  get isUltimaPagina(): boolean {
    return this.paginaAtual == this.totalPaginas;
  }

  trocarItensPagina(): void {
    this.calcularPaginas();
    this.atualizarPaginasVisiveis();
    if (!this.paginas.some(x => x.numero == this.paginaAtual)) {
      this.paginaAtual = this.paginas.length;
      let pagina = this.paginas[this.paginas.length - 1];
      if (pagina)
        pagina.ativa = true;
    }
    this.change.emit(this.pagination);
  }

  get pagination(): MatPageable {
    return new MatPageable(this.paginaAtual - 1, this.itemPorPagina);
  }

  paginarData(array) {
    return array.slice((this.paginaAtual - 1) * this.itemPorPagina, this.paginaAtual * this.itemPorPagina);
  }

  reset(): void {
    this.paginaAtual = 1;
  }
}

class Pagina {
  primeira?: boolean;
  numero: number;
  ativa?: boolean;
  ultima?: boolean;
}
