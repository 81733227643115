import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appInfinityScroll]',
  exportAs: 'custominfinitscroll'
})
export class InfinityScrollDirective {

  @Input()
  set appInfinityScroll(value: any[]) {
    if (value)
      this.scrollData = value.slice(0, 10);
  }

  scrollData: any[];
  constructor() {

  }
}
