import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { MatPageable } from '@core/table/mat-table';
import { AuthenticationService } from '@core/auth/authentication.service';
import { AgrupamentoProdutoUsuario, AgrupamentoDetalheConsulta, ConsultaEnviar, RetornoConsulta, FiltroProdutosUsuario, OutroMetodo, AgrupamentoParametros } from '@core/models/consulta-metodo.models';
import { environment } from '@env/environment';
import { HttpParams } from '@angular/common/http';
import { ProdutoCard } from '@core/models/produto.models';
import { map } from 'rxjs/operators';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { TipoDadoEnum } from '@core/models/tipo-dado.models';
import { Validators } from '@angular/forms';
import { MetodoService } from './metodo.service';
import { TipoParametroMetodo, TipoParametroMetodoEnum } from '@core/models/parametro-metodo.models';
import { Masks } from '@core/custom-validators/masks';

const routes = {
  buscarProdutosUsuario: (pagina: number, registros: number, idUsuario: number, idRamo: number) => `consultaportal/buscaprodutosporusuario/${pagina}/${registros}/${idUsuario}/${idRamo}`,
  detalhesMetodo: (idMetodo: number) => `consultaportal/buscaparametrospormetodo/${idMetodo}`,
  consultaservico: `consultaservico`,
  buscarProdutosUsuarioCrud: `crud/produtos/listar`,
  favoritarProduto: (idProduto: number) => `crud/produtos/${idProduto}/favoritar`,
  desFavoritarProduto: (idProduto: number) => `crud/produtos/${idProduto}/desfavoritar`,
  outrasConsultas: (idProduto: number) => `crud/produto/${idProduto}/metodos`,
  popularMenu: `menu`
}


@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  constructor(private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private metodoService: MetodoService) { }

  getProdutosConsulta(pageable: MatPageable, idRamo: any) {
    return this.apiService.get<AgrupamentoProdutoUsuario>(routes.buscarProdutosUsuario(pageable.pagina, pageable.registros, this.authenticationService.currentUserValue.id_usuario, idRamo));
  }

  getProdutosConsultaCrud(pageable: MatPageable, filtro: FiltroProdutosUsuario) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.getCustomApi<ProdutoCard[]>(`${environment.lote}/${routes.buscarProdutosUsuarioCrud}`, params);
  }

  getDetalheMetodo(idMetodo: number) {
    return this.apiService.get<AgrupamentoDetalheConsulta>(routes.detalhesMetodo(idMetodo)).pipe(map((data) => {

      if (!data.parametrosMetodo.parametrosEntrada)
        data.parametrosMetodo.parametrosEntrada = { parametros: [] };

      if (!Array.isArray(data.parametrosMetodo.parametrosEntrada.parametros))
        data.parametrosMetodo.parametrosEntrada.parametros = [data.parametrosMetodo.parametrosEntrada.parametros];

      if (!data.parametrosMetodo.parametrosSaida)
        data.parametrosMetodo.parametrosSaida = { parametros: [] };

      if (!Array.isArray(data.parametrosMetodo.parametrosSaida.parametros))
        data.parametrosMetodo.parametrosSaida.parametros = [data.parametrosMetodo.parametrosSaida.parametros];

      if (!data.parametrosMetodo.parametrosErro)
        data.parametrosMetodo.parametrosErro = { parametros: [] };

      if (!Array.isArray(data.parametrosMetodo.parametrosErro.parametros))
        data.parametrosMetodo.parametrosErro.parametros = [data.parametrosMetodo.parametrosErro.parametros]


      data.parametrosMetodo.parametrosEntrada.parametros.sort((a, b) => {
        let orderA: string | number = a.ordemExibicao.toString();
        let orderB: string | number = b.ordemExibicao.toString();

        let orderASplit = orderA.split('.')
        let orderBSplit = orderB.split('.')

        if (orderASplit.length && orderBSplit.length && orderASplit.length == orderBSplit.length) {
          orderA = parseFloat(orderASplit[orderASplit.length - 1]);
          orderB = parseFloat(orderBSplit[orderBSplit.length - 1]);
        }
        else {
          if (orderASplit.length < orderBSplit.length) {
            orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
            orderA = parseFloat(orderASplit[0] + '.' + orderA.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
          }
          else
            if (orderASplit.length > orderBSplit.length) {
              orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
              orderA = parseFloat(orderBSplit[0] + '.' + orderA.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
            }
        }

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      data.parametrosMetodo.parametrosEntrada.parametros.forEach(x => {
        x.ordemExibicao = x.ordemExibicao.toString();
      })

      data.parametrosMetodo.parametrosEntrada.parametros.forEach(x => {
        x.filhos = data.parametrosMetodo.parametrosEntrada.parametros.filter(z => z.ordemExibicao != x.ordemExibicao && (x.ordemExibicao.split('.').length + 1) == z.ordemExibicao.split('.').length && z.ordemExibicao.startsWith(`${x.ordemExibicao}.`))
      })

      data.parametrosMetodo.parametrosSaida.parametros.sort((a, b) => {
        let orderA: string | number = a.ordemExibicao.toString();
        let orderB: string | number = b.ordemExibicao.toString();

        let orderASplit = orderA.split('.')
        let orderBSplit = orderB.split('.')

        if (orderASplit.length && orderBSplit.length && orderASplit.length == orderBSplit.length) {
          orderA = parseFloat(orderASplit[orderASplit.length - 1]);
          orderB = parseFloat(orderBSplit[orderBSplit.length - 1]);
        }
        else {
          if (orderASplit.length < orderBSplit.length) {
            orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
            orderA = parseFloat(orderASplit[0] + '.' + orderA.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
          }
          else
            if (orderASplit.length > orderBSplit.length) {
              orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
              orderA = parseFloat(orderBSplit[0] + '.' + orderA.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
            }
        }

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      data.parametrosMetodo.parametrosSaida.parametros.forEach(x => {
        x.ordemExibicao = x.ordemExibicao.toString();
      })

      data.parametrosMetodo.parametrosSaida.parametros.forEach(x => {
        x.filhos = data.parametrosMetodo.parametrosSaida.parametros.filter(z => z.ordemExibicao != x.ordemExibicao && (x.ordemExibicao.split('.').length + 1) == z.ordemExibicao.split('.').length && z.ordemExibicao.startsWith(`${x.ordemExibicao}.`))
      })


      data.parametrosMetodo.parametrosErro.parametros.sort((a, b) => {
        let orderA: string | number = a.ordemExibicao.toString();
        let orderB: string | number = b.ordemExibicao.toString();

        let orderASplit = orderA.split('.')
        let orderBSplit = orderB.split('.')

        if (orderASplit.length && orderBSplit.length && orderASplit.length == orderBSplit.length) {
          orderA = parseFloat(orderASplit[orderASplit.length - 1]);
          orderB = parseFloat(orderBSplit[orderBSplit.length - 1]);
        }
        else {
          if (orderASplit.length < orderBSplit.length) {
            orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
            orderA = parseFloat(orderASplit[0] + '.' + orderA.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
          }
          else
            if (orderASplit.length > orderBSplit.length) {
              orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
              orderA = parseFloat(orderBSplit[0] + '.' + orderA.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
            }
        }

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      data.parametrosMetodo.parametrosErro.parametros.forEach(x => {
        x.ordemExibicao = x.ordemExibicao.toString();
      })

      data.parametrosMetodo.parametrosErro.parametros.forEach(x => {
        x.filhos = data.parametrosMetodo.parametrosErro.parametros.filter(z => z.ordemExibicao != x.ordemExibicao && (x.ordemExibicao.split('.').length + 1) == z.ordemExibicao.split('.').length && z.ordemExibicao.startsWith(`${x.ordemExibicao}.`))
      })

      return data;
    }));
  }

  getDetalheMetodoCrud(idMetodo: number) {
    return this.metodoService.getMetodoGrud(idMetodo).pipe(map((response) => {

      let data = new AgrupamentoDetalheConsulta();
      data.template = response.template;
      data.parametrosMetodo = {
        parametrosEntrada: {
          parametros: response.parametros.filter(x => x.idTipoParametro == TipoParametroMetodoEnum.Entrada).map((x) => {

            return {
              parametroPortal: x.nomeParametroPortal,
              parametroServico: x.nomeParametroServico,
              parametroServicoPai: x.nomeParametroServicoPai,
              idTipoDado: x.idTipoDado,
              indObrigatorio: x.obrigatorio ? 1 : 0,
              visivel: x.visivel ? 1 : 0,
              ordemExibicao: x.numOrdemExibicao,
              dominio: x.dominio
            }
          })
        },
        parametrosSaida: {
          parametros: response.parametros.filter(x => x.idTipoParametro == TipoParametroMetodoEnum.Saida).map((x) => {
            return {
              parametroPortal: x.nomeParametroPortal,
              parametroServico: x.nomeParametroServico,
              parametroServicoPai: x.nomeParametroServicoPai,
              idTipoDado: x.idTipoDado,
              indObrigatorio: x.indObrigatorio ? 1 : 0,
              visivel: x.visivel ? 1 : 0,
              ordemExibicao: x.numOrdemExibicao,
              dominio: x.dominio
            }
          })
        },
        parametrosErro: {
          parametros: response.parametros.filter(x => x.idTipoParametro == TipoParametroMetodoEnum.Erro).map((x) => {
            return {
              parametroPortal: x.nomeParametroPortal,
              parametroServico: x.nomeParametroServico,
              parametroServicoPai: x.nomeParametroServicoPai,
              idTipoDado: x.idTipoDado,
              indObrigatorio: x.indObrigatorio ? 1 : 0,
              visivel: x.visivel ? 1 : 0,
              ordemExibicao: x.numOrdemExibicao,
              dominio: x.dominio
            }
          })
        }
      }
      if (!data.parametrosMetodo.parametrosEntrada)
        data.parametrosMetodo.parametrosEntrada = { parametros: [] };

      if (!Array.isArray(data.parametrosMetodo.parametrosEntrada.parametros))
        data.parametrosMetodo.parametrosEntrada.parametros = [data.parametrosMetodo.parametrosEntrada.parametros];

      if (!data.parametrosMetodo.parametrosSaida)
        data.parametrosMetodo.parametrosSaida = { parametros: [] };

      if (!Array.isArray(data.parametrosMetodo.parametrosSaida.parametros))
        data.parametrosMetodo.parametrosSaida.parametros = [data.parametrosMetodo.parametrosSaida.parametros];

      if (!data.parametrosMetodo.parametrosErro)
        data.parametrosMetodo.parametrosErro = { parametros: [] };

      if (!Array.isArray(data.parametrosMetodo.parametrosErro.parametros))
        data.parametrosMetodo.parametrosErro.parametros = [data.parametrosMetodo.parametrosErro.parametros]


      data.parametrosMetodo.parametrosEntrada.parametros.sort((a, b) => {
        let orderA: string | number = a.ordemExibicao.toString();
        let orderB: string | number = b.ordemExibicao.toString();

        let orderASplit = orderA.split('.')
        let orderBSplit = orderB.split('.')

        if (orderASplit.length && orderBSplit.length && orderASplit.length == orderBSplit.length) {
          orderA = parseFloat(orderASplit[orderASplit.length - 1]);
          orderB = parseFloat(orderBSplit[orderBSplit.length - 1]);
        }
        else {
          if (orderASplit.length < orderBSplit.length) {
            orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
            orderA = parseFloat(orderASplit[0] + '.' + orderA.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
          }
          else
            if (orderASplit.length > orderBSplit.length) {
              orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
              orderA = parseFloat(orderBSplit[0] + '.' + orderA.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
            }
        }

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      data.parametrosMetodo.parametrosEntrada.parametros.forEach(x => {
        x.ordemExibicao = x.ordemExibicao.toString();
      })

      data.parametrosMetodo.parametrosEntrada.parametros.forEach(x => {
        x.filhos = data.parametrosMetodo.parametrosEntrada.parametros.filter(z => z.ordemExibicao != x.ordemExibicao && (x.ordemExibicao.split('.').length + 1) == z.ordemExibicao.split('.').length && z.ordemExibicao.startsWith(`${x.ordemExibicao}.`))
      })

      data.parametrosMetodo.parametrosSaida.parametros.sort((a, b) => {
        let orderA: string | number = a.ordemExibicao.toString();
        let orderB: string | number = b.ordemExibicao.toString();

        let orderASplit = orderA.split('.')
        let orderBSplit = orderB.split('.')

        if (orderASplit.length && orderBSplit.length && orderASplit.length == orderBSplit.length) {
          orderA = parseFloat(orderASplit[orderASplit.length - 1]);
          orderB = parseFloat(orderBSplit[orderBSplit.length - 1]);
        }
        else {
          if (orderASplit.length < orderBSplit.length) {
            orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
            orderA = parseFloat(orderASplit[0] + '.' + orderA.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
          }
          else
            if (orderASplit.length > orderBSplit.length) {
              orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
              orderA = parseFloat(orderBSplit[0] + '.' + orderA.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
            }
        }

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      data.parametrosMetodo.parametrosSaida.parametros.forEach(x => {
        x.ordemExibicao = x.ordemExibicao.toString();
      })

      data.parametrosMetodo.parametrosSaida.parametros.forEach(x => {
        x.filhos = data.parametrosMetodo.parametrosSaida.parametros.filter(z => z.ordemExibicao != x.ordemExibicao && (x.ordemExibicao.split('.').length + 1) == z.ordemExibicao.split('.').length && z.ordemExibicao.startsWith(`${x.ordemExibicao}.`))
      })


      data.parametrosMetodo.parametrosErro.parametros.sort((a, b) => {
        let orderA: string | number = a.ordemExibicao.toString();
        let orderB: string | number = b.ordemExibicao.toString();

        let orderASplit = orderA.split('.')
        let orderBSplit = orderB.split('.')

        if (orderASplit.length && orderBSplit.length && orderASplit.length == orderBSplit.length) {
          orderA = parseFloat(orderASplit[orderASplit.length - 1]);
          orderB = parseFloat(orderBSplit[orderBSplit.length - 1]);
        }
        else {
          if (orderASplit.length < orderBSplit.length) {
            orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
            orderA = parseFloat(orderASplit[0] + '.' + orderA.slice(2, orderASplit.length + (orderASplit.length - 1)).toString().split('.').join(''));
          }
          else
            if (orderASplit.length > orderBSplit.length) {
              orderB = parseFloat(orderBSplit[0] + '.' + orderB.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
              orderA = parseFloat(orderBSplit[0] + '.' + orderA.slice(2, orderBSplit.length + (orderBSplit.length - 1)).toString().split('.').join(''));
            }
        }

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      data.parametrosMetodo.parametrosErro.parametros.forEach(x => {
        x.ordemExibicao = x.ordemExibicao.toString();
      })

      data.parametrosMetodo.parametrosErro.parametros.forEach(x => {
        x.filhos = data.parametrosMetodo.parametrosErro.parametros.filter(z => z.ordemExibicao != x.ordemExibicao && (x.ordemExibicao.split('.').length + 1) == z.ordemExibicao.split('.').length && z.ordemExibicao.startsWith(`${x.ordemExibicao}.`))
      })

      return data;
    }));
  }

  consultar(consulta: ConsultaEnviar, ignoreLoading?: boolean) {
    let params = new HttpParams();
    if (ignoreLoading)
      params = params.set('ignoreLoading', 'true')
    return this.apiService.postCustomApi<RetornoConsulta>(`${environment.consulta}/${routes.consultaservico}`, consulta, undefined, params);
  }

  favoritarProduto(idProduto: number) {
    return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.favoritarProduto(idProduto)}`);
  }

  desFavoritarProduto(idProduto: number) {
    return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.desFavoritarProduto(idProduto)}`);
  }

  getOutrasConsultas(idProduto: number) {
    return this.apiService.getCustomApi<OutroMetodo[]>(`${environment.lote}/${routes.outrasConsultas(idProduto)}`);
  }

  montarFormularioEntrada(configs: FieldConfig[], data: AgrupamentoDetalheConsulta) {
    data.parametrosMetodo.parametrosEntrada.parametros.forEach((x) => {
      if (!configs.some(z => z.name == x.parametroServico)) {
        let config = <FieldConfig>{};
        config.name = x.parametroServico;

        if (x.dominio) {
          config.options = JSON.parse(x.dominio).data;
          let padrao = config.options.find(x => x.padrao);
          if (padrao)
            config.value = padrao.valor;
        }

        if (config.options)
          config.type = 'select';
        else
          config.type = this.getTypeByTipoDado(x.idTipoDado);

        config.label = x.parametroPortal;
        config.visible = x.visivel == 1;


        config.required = x.visivel == 1 && x.indObrigatorio == 1;
        if (config.required)
          config.validation = [Validators.required];

        configs.push(config);
      }
    })

    if (configs.length > 0) {
      configs.push({
        type: 'button',
        label: 'Consultar',
        name: 'consultar'
      })
    }

    return configs;
  }


  getTypeByTipoDado(idTipoDado: number) {

    switch (idTipoDado) {
      case TipoDadoEnum.Texto: {
        return 'input';
      }
      case TipoDadoEnum.PeriodoAnoAAno:
        return 'periodoAno'
      case TipoDadoEnum.Data:
        return 'datepicker'
      case TipoDadoEnum.DataComTraco:
        return 'dataComTraco';
      case TipoDadoEnum.CPF:
        return 'cpf';
      case TipoDadoEnum.CNPJ:
        return 'cnpj';
      case TipoDadoEnum.CPFCNPJ:
        return 'cpfCnpj';
      case TipoDadoEnum.CEP:
        return 'cep';
      case TipoDadoEnum.Array:
        return 'select';
      case TipoDadoEnum.DataAAAAMMDD:
        return 'formDatepickerAAAAMMDD';
      case TipoDadoEnum.Chassi:
        return 'chassi';
      case TipoDadoEnum.Placa:
        return 'placa';
    }

    return 'input';
  }

  consultarGruposQuadroPessoal() {
    return this.apiService.getCustomApi<any[]>('assets/json/gruposQuadroPessoal.json');
  }
}
