import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatPageable } from '@core/table/mat-table';
import { RamoCombo, FiltroRamo, RamoElement, Ramo } from '@core/models/ramo.models';

const routes = {
  buscatodos: (pagina: number, registros: number) => `ramo/buscatodos/${pagina}/${registros}`,
  buscaPorParametros: `ramo/buscapornome`,
  inativar: `ramo/inativar`,
  reativar: `ramo/reativar`,
  buscaPorId: (id: number) => `ramo/buscaporid/${id}`,
  cadastrar: `ramo/cadastrar`,
  atualizar: `ramo/alterar`,
}


@Injectable({
  providedIn: 'root'
})
export class RamoService {


  constructor(private apiService: ApiService) { }

  buscarTodosCombo(pagination: MatPageable) {
    return this.apiService.get<RamoCombo[]>(routes.buscaPorParametros + pagination.query);
  }

  getRamos(pagination: MatPageable, filtro: FiltroRamo) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.get<RamoElement[]>(routes.buscaPorParametros + pagination.query, params);
  }

  inativar(idRamo: string) {
    return this.apiService.put(routes.inativar, {
      Ramo: {
        idRamo: idRamo
      }
    });
  }

  reativar(idRamo: string) {
    return this.apiService.put(routes.reativar, {
      Ramo: {
        idRamo: idRamo
      }
    });
  }

  salvar(ramo: Ramo) {
    if (ramo.idRamo)
      return this.apiService.put<any>(routes.atualizar, {
        Ramo: ramo
      });
    else
      return this.apiService.post<any>(routes.cadastrar, {
        Ramo: ramo
      });
  }

  getRamo(id: number) {
    return this.apiService.get<Ramo>(routes.buscaPorId(id));
  }
}
