import { Component, OnInit } from '@angular/core';
import { NotificacaoElement } from '@core/models/notificacao.models';
import { BaseDialogComponent } from '@shared/components/base-dialog/base-dialog.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notificacao-detalhe',
  templateUrl: './notificacao-detalhe.component.html',
  styleUrls: ['./notificacao-detalhe.component.scss']
})
export class NotificacaoDetalheComponent extends BaseDialogComponent implements OnInit {

  notificacao: NotificacaoElement;

  constructor(modal: NgbActiveModal) {
    super(modal)
  }

  ngOnInit() {
  }

}
