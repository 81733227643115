export class Perfil {
    id: string;
    nome: string;
}

export enum PerfilEnum {
    Administrador = '1',
    SuperUsuario = '2',
    Usuario = '3',
    Master = '4',
    Api = '5'
}