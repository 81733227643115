interface Pageable {
    linha?: number;
    pagina?: number;
    registros?: number;
    total?: number;
}

export class MatPageable implements Pageable {
    linha?: number;
    pagina?: number;
    registros?: number;
    total?: number;

    constructor(page?: number, size?: number) {
        this.pagina = page != undefined ? page : 1;
        this.registros = size != undefined ? size : 9999999;
    }


    public get query() {
        return `?pagina=${this.pagina}&registros=${this.registros}`;
    }
}

export class PageableResponse<T> {
    content: Array<T>;
    totalElements: number;
}
