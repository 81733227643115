import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pagination', pure: false })
export class PaginationPipe implements PipeTransform {
  transform(arrayData: any, page: number, itens: number): any {

    let array = [...arrayData];
    
    if (!Array.isArray(array)) {
      return;
    }

    if (!array.some(x => x && x._originalIndex && x instanceof Object))
      array.forEach((val, i) => {

        Object.defineProperty(val, "_originalIndex", {
          enumerable: false,
          writable: true
        });
        val._originalIndex = i;
      })

    return array.slice((page - 1) * itens, page * itens);
  }

  deepFind(obj, path) {
    var paths = path.split('.')
      , current = obj
      , i;

    for (i = 0; i < paths.length; ++i) {
      if (current[paths[i]] == undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }
}