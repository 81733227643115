import { MatPageable } from '@core/table/mat-table';
import { environment } from '@env/environment';

export class FuncionalidadeCombo {
    id: string;
    nome: string;
}

export class FuncionalidadeElement {
    id: string;
    ativo: string;
    tipo: string;
    paginacao?: MatPageable;
}

export class Funcionalidade {
    idFuncionalidade: number;
    nome: string;
    ativo: string;
    perfis: any[];
}

export class FiltroFuncionalidade {
    nome?: string;
    idStatus: number|any;
}


export enum FuncionalidadeEnum {
    ConsultaLote = '17',
    CadastraLote = '18',
    ManterContrato = '21',
    ManterEmpresa = '22',
    ManterFuncionalidade = '23',
    ManterGrupoEconomico = '24',
    ManterProduto = '26',
    ManterUsuario = '30',
    ConsultarProduto = '7',
    ManterTipoEmpresa = '70',
    ManterTipoProduto = '71',
    ManterTipoContrato = '72',
    ManterRamo = '73',
    ManterParametroPortal = '74',
    RelatorioContrato = '75',
    RelatorioConsulta = '76',
    ManterManual = '78',
    ManterManualHMG = '79',
    ManterControleEnquadramento = '77',
    ManterIP = '79',
    ManterIPHMG = '80',
}
