import { NgModule } from '@angular/core';
import { DynamicContentComponent } from './dynamic-content.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [
        DynamicContentComponent
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        DynamicContentComponent,
    ]
})
export class DynamicContentModule { }
