import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { faCalendar, faEraser } from '@fortawesome/free-solid-svg-icons';
import { Masks } from '@core/custom-validators/masks';
import { CustomValidators } from '@core/custom-validators/validators';
import * as moment from 'moment';

@Component({
  selector: 'form-datepicker-aaaammdd',
  styleUrls: ['form-datepicker-aaaammdd.component.scss'],
  template: `
  <div [formGroup]="formDatePicker" class="form-group col-md" [style.display]="config.visible?'block':'none'">
    <label for="{{config.name}}" [class.required]="config.required">{{ config.label }}</label>
    <div class="input-group" controlErrorContainer>
    <input class="form-control custom-datepicker" [textMask]="mask" placeholder="DD/MM/YYYY" 
    #inputDate formControlName="inputData">  
    <input class="form-control custom-datepicker" placeholder="DD/MM/YYYY" hidden
      ngbDatepicker #data="ngbDatepicker" formControlName="data" [minDate]="{year: 1900, month: 1, day: 1}">
     
      <div class="input-group-append">
           <button class="btn btn-icon btn-clear" matTooltip="Limpar" *ngIf="showClearDate" (click)="clearDate()" type="button">
             <fa-icon [icon]="faEraser"></fa-icon>
           </button>
           <button class="btn btn-icon"  matTooltip="Calendário"  (click)="data.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
           </button>
      </div>
    </div>
  </div>
  <div  [formGroup]="group" style='display:none'>
  <input type='hidden' [formControlName]="config.name" />
  </div>
  `
})
export class FormDatepickerAAAAMMDDComponent implements Field, OnInit {
  formDatePicker: FormGroup;
  config: FieldConfig;
  group: FormGroup;
  faCalendar = faCalendar;
  faEraser = faEraser;
  mask = {
    mask: Masks.data,
    guide: false
  };
  showClearDate: boolean;
  firstSubmit = false;

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.formDatePicker = this.formBuilder.group({
      data: [null],
      inputData:[null],
    })

    if (this.config.required) {
      this.inputData.setValidators(Validators.compose([Validators.required,CustomValidators.nullValue,CustomValidators.data]));
      this.data.setValidators(Validators.compose([Validators.required]))
      this.control.setValidators(Validators.compose([Validators.required]))
    }
    else{
      this.inputData.setValidators(Validators.compose([CustomValidators.data]))
      this.data.clearValidators();
      this.control.clearValidators();
    }

    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.inputData.updateValueAndValidity();
      }
    })

    this.data.valueChanges.subscribe((val) => {
      if (val) {
        this.control.setValue(moment(val, 'YYYY-MM-DD', true).format('YYYYMMDD'));
        this.inputData.setValue(moment(val, 'YYYY-MM-DD', true).format('DD/MM/YYYY'));
        this.showClearDate = true;
      }
      else {
        this.showClearDate = false;
        this.control.setValue(undefined);
        this.inputData.setValue(undefined);
      }
    })

    this.inputData.valueChanges.subscribe((val) => {
      if (val) {
        this.control.setValue(moment(val, 'DD/MM/YYYY', true).format('YYYYMMDD'));
        this.showClearDate = true;
      }
      else {
        this.showClearDate = false;
        this.control.setValue(undefined);
      }
    })
  }

  get data() {
    return this.formDatePicker.controls.data;
  }

  get inputData() {
    return this.formDatePicker.controls.inputData;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  clearDate(): void {
    this.data.setValue(undefined);
    this.inputData.setValue(undefined);
  }
}