import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { CnsegUpdatesComponent } from './pages/cnseg-updates/cnseg-updates.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProdutoRamoComponent } from './pages/produto-ramo/produto-ramo.component';


const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    component: DashboardComponent,
    children: [
      { path: ':id', component: ProdutoRamoComponent },
    ],
    data: {
      reuse: true
    }
  },
  {
    path: 'dashboard/:idRamo/produtos',
    canActivate: [AuthGuardService],
    component: ProdutoRamoComponent,
    data: {
      reuse: true
    }
  },
  {
    path: 'dashboard/updates/history',
    canActivate: [AuthGuardService],
    component: CnsegUpdatesComponent,
  },
  {
    path: 'dashboard/updates/:id',
    canActivate: [AuthGuardService],
    component: CnsegUpdatesComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }