import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RnpaRestInterface } from './rnpa-rest-interface.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TiposProdutoService extends RnpaRestInterface {

  consultaUrl = environment.apiGatewayDns

  private _produtosUrl = '/rnpa-pessoas/v1/tipos-produto'

  constructor(
    public http: HttpClient
  ) { 
    super(http)
  }
  
  // GET produtos
  listarTiposProduto(){
    const url = this.consultaUrl + this._produtosUrl
    return this.get(url)
  }
}