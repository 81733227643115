import { Component, OnInit, Input } from '@angular/core';
import { RamoCard } from '@core/models/ramo.models';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ramo-card',
  templateUrl: './ramo-card.component.html',
  styleUrls: ['./ramo-card.component.scss']
})
export class RamoCardComponent implements OnInit {
  
  @Input()
  ramo: RamoCard;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  getImage() {
    return this._sanitizer.bypassSecurityTrustStyle(`url('${this.ramo.imagem}')`);
  }
}
