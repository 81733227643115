import { Subject } from 'rxjs';
import { DadosPerfil, DadosUsuarioAlterar } from '@core/models/meu-perfil.models';
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderSharedDataService {


  private atualizarMeusDados = new Subject<DadosPerfil | DadosUsuarioAlterar>();
  atualizarMeusDados$ = this.atualizarMeusDados.asObservable();

  private search = new Subject<string>();
  search$ = this.search.asObservable();

  private showSearch = new Subject<boolean>();
  showSearch$ = this.showSearch.asObservable();

  private setSearch = new Subject<string>();
  setSearch$ = this.search.asObservable();

  private searchVisible = new Subject<boolean>();
  searchVisible$ = this.searchVisible.asObservable();

  private menuBoostrap = new Subject<boolean>();
  menuBoostrap$ = this.menuBoostrap.asObservable();

  constructor() { }

  setAtualizarMeusDados(dados: DadosPerfil | DadosUsuarioAlterar): void {
    this.atualizarMeusDados.next(dados);
  }

  onSearch(text: string) {
    this.search.next(text);
  }

  showSearchText(data: boolean) {
    this.showSearch.next(data);
  }

  setSearchText(text:string){
    this.setSearch.next(text);
  }

  activeMenuBoostrap(data: any){
    this.menuBoostrap.next(data);
  }
}
