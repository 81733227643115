import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appConfirmRefreshPage]'
})
export class ConfirmRefreshPageDirective {

  constructor() { }

  
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false;
  }
}
