import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { AuthenticationService } from '@core/auth/authentication.service';
import { HttpResponseValidation } from './http-error';
import { MessageService } from '@core/services/message.service';
import { environment } from '@env/environment';
import { HttpStatusEnum } from './http-status';

const ignoreErrors = [
    "Token is not valid",
    ""
]

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private notificationService: NotificationService,
        private authenticationService: AuthenticationService,
        private messageService: MessageService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(null, (err: any) => {
                if (request.url.indexOf(environment.auth) == -1)
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 500) {
                            let errorResponse = <HttpResponseValidation>err.error;
                            if (errorResponse.MSG) {
                                this.notificationService.error(errorResponse.MSG, 10000);
                            }
                        }
                        if (err.status === 400) {
                            let errorResponse = <HttpResponseValidation>err.error;
                            if (errorResponse.message) {
                                this.notificationService.error(errorResponse.message, 10000);
                            }else{
                                this.notificationService.error('Ocorreu um erro inesperado, entre em contato com o suporte', 10000);
                            }
                        }
                        if (err.status === 401) {
                            let errorResponse = <HttpResponseValidation>err.error;
                            if (errorResponse.MSG) {
                                this.notificationService.error(errorResponse.MSG, 10000);
                            }
                            this.authenticationService.logout(true);
                        } else if (err.status === 403) {
                            this.authenticationService.logout(true);
                        }
                        else {
                            if (request.params && request.params.has('ignoreError')) {

                            }
                            else
                                if (err.statusText == 'Unknown Error')
                                    this.notificationService.error('Ocorreu um erro inesperado durante esta ação.')
                                else {
                                    let errorObj = err.error;
                                    if (request.responseType == 'arraybuffer') {
                                        if ('TextDecoder' in window) {
                                            var dataView = new DataView(err.error);
                                            var decoder = new TextDecoder('utf8');
                                            errorObj = JSON.parse(decoder.decode(dataView));
                                        } else {
                                            var decodedString = String.fromCharCode.apply(null, new Uint8Array(err.error));
                                            errorObj = JSON.parse(decodedString);
                                            let errorResponse = <HttpResponseValidation>errorObj;
                                            if (ignoreErrors.indexOf(errorResponse.MSG) == -1)
                                                this.notificationService.error(errorResponse.MSG);
                                        }
                                    }
                                    else if (request.responseType == 'blob') {
                                        var reader = new FileReader();
                                        reader.onload = (ev: any) => {
                                            let errorResponse = <HttpResponseValidation>JSON.parse(ev.target.result);
                                            if (ignoreErrors.indexOf(errorResponse.MSG) == -1)
                                                this.notificationService.error(errorResponse.MSG);
                                        }
                                        reader.readAsText(errorObj);
                                    }
                                    else
                                        if (err.statusText == 'Unknown Error' || err.status == HttpStatusEnum.Accepted)
                                            this.notificationService.error('Ocorreu um erro inesperado durante esta ação.')
                                        else if (err.status != HttpStatusEnum.NotFound) {
                                            if (request.url.indexOf("sivvedinamico/contagem") != -1 && err.status == HttpStatusEnum.InternalServer) {
                                                this.notificationService.error('Ocorreu um erro inesperado durante esta ação. Considere utilizar mais filtros na sua consulta', 10000)
                                            } else {
                                                let errorResponse = <HttpResponseValidation>err.error;
                                                if (ignoreErrors.indexOf(errorResponse.MSG) == -1)
                                                    this.notificationService.error(errorResponse.MSG, 10000);
                                            }
                                        }
                                }
                        }
                    }
            })
        )
    }
}
