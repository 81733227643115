import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicResultComponent } from './containers/dynamic-result/dynamic-result.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { LabelResultComponent } from './components/label-result/label-result.component';
import { DynamicFieldResultDirective } from './components/dynamic-field-result/dynamic-field-result.directive';
import { ArrayResultComponent } from './components/array-result/array-result.component';
import { ObjetoResultComponent } from './components/objeto-result/objeto-result.component';
import { DataResultComponent } from './components/data-result/data-result.component';
import { BrDateResultPipe } from './pipes/br-date-result.pipe';
import { BooleanResultComponent } from './components/boolean-result/boolean-result.component';
import { HoraResultComponent } from './components/hora-result/hora-result.component';
import { LinkResultComponent } from './components/link-result/link-result.component';
import { MoedaResultComponent } from './components/moeda-result/moeda-result.component';
import { BrHoraResultPipe } from './pipes/br-hora-result.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    declarations: [
        LabelResultComponent,
        DynamicResultComponent,
        DynamicFieldResultDirective,
        ArrayResultComponent,
        ObjetoResultComponent,
        DataResultComponent,
        BrDateResultPipe,
        BooleanResultComponent,
        HoraResultComponent,
        LinkResultComponent,
        MoedaResultComponent,
        BrHoraResultPipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TextMaskModule,
        InfiniteScrollModule
    ],
    exports: [
        LabelResultComponent,
        DynamicResultComponent,
        DynamicFieldResultDirective,
        DataResultComponent,
        BooleanResultComponent,
        HoraResultComponent,
        LinkResultComponent,
        MoedaResultComponent,
        BrDateResultPipe,
        BrHoraResultPipe
    ]
})
export class DynamicResultModule { }
