import { Observable } from 'rxjs';
import { ParametroDetalheConsulta, RetornoConsulta } from '@core/models/consulta-metodo.models';
import { FieldConfig } from './../../shared/components/dynamic-form/models/field-config.interface';

export class Tab {
    nome: string;
    request: Observable<RetornoConsulta>;
    id: string;
    resultado: any;
    loading: boolean;
    success: boolean;
    error: boolean;
    parametrosSaida: ParametroDetalheConsulta[];
    parametrosErro: ParametroDetalheConsulta[];
    configsEntrada: FieldConfig[];
    configs: FieldConfig[];
    msg:string;
    nomeProduto:string;
    nomeMetodo:string;
    dataConsulta:Date;
    descricaoResumidaProduto:string;
    hasError:boolean;
    protocolo:string;
    saldo:string;
    template:string;
    objRetornoServico:any;
    isResultadoCustomizado:boolean;
}