import { Component } from '@angular/core';
import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-array-result',
  styleUrls: ['./array-result.component.scss'],
  template: `
  <div [class.container-array]="config.visible" [class.col]="config.visible">
      <label class='label-name' for="{{config.name}}" [style.display]="config.visible?'block':'none'">{{ config.label }}</label>
    <div [class.flex-wrap]="config.configs.length && config.visible" [class.d-flex]="config.configs.length && config.visible" [class.container-itens-array]="config.configs.length && config.visible">
       <div class='col-6 no-padding' [class.col-12]="isColDoze(field,i,config)"  *ngFor="let field of config.configs;let i = index">
  
          <div class='col-12 no-padding' [class.col-12]="isColDoze(field,i,config)">   
             <ng-container dynamicFieldResult [config]="field">
              </ng-container>
          </div>
          <div class='limitBorder' *ngIf="useBorder(i) && hasChildren"></div>
       </div>
    </div>
  </div>
`
})
export class ArrayResultComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  useBorder(index): boolean {
    return this.config.visible && this.config.limitBorder && (((index + 1) % this.config.limitBorder) == 0);
  }

  isColDoze(field: FieldConfig, index: number, config: FieldConfig): boolean {
    return (field.type == 'array' || field.type == 'objeto') || this.useBorder(index);
  }

  get hasChildren(): boolean {
    return this.config.configs && this.config.configs.length > 1;
  }
}
