import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseDialogComponent } from '@shared/components/base-dialog/base-dialog.component';
import { environment } from '@env/environment';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent extends BaseDialogComponent implements OnInit {

  centralAtendimento:string;

  constructor(modal: NgbActiveModal) {
    super(modal);
  }

  ngOnInit() {
    this.centralAtendimento = environment.urlCentralAtendimento;
  }

}
