import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ParametroPortalService } from '@core/services/parametro-portal.service';
import { UsuarioService } from '@core/services/usuario.service';
import { JoyrideService } from 'ngx-joyride';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-tooltip-tour',
  templateUrl: './tooltip-tour.component.html',
  styleUrls: ['./tooltip-tour.component.scss']
})
export class TooltipTourComponent implements OnInit {

  stepsJson: any;
  //stepsTour: any[]=[];
  @Input() stepTour;
  @Input() titleTour;
  @Input() descriptionTour;
  @Input() arrayStepTour;
  @Input() stepsTour;
  // <app-tooltip-tour stepTour="stepOne" titleTour="Teste Titulo" descriptionTour="Teste Descricao" arrayStepTour="0"></app-tooltip-tour>

  constructor(
    private joyrideTour: JoyrideService,
    private appSharedDataService: AppSharedDataService,
    private usuarioService: UsuarioService,
    private authenticationService: AuthenticationService,
    private parametroPortalService: ParametroPortalService
  ) { }

  ngOnInit() {
    console.log(this.stepsTour,
      this.titleTour,
      this.descriptionTour,
      this.arrayStepTour)

    let ativar = false;
    if(ativar){
      this.parametroPortalService.getParametroPortal(null).subscribe((res) => {
        if(res){
          //this.stepsJson = res.valor;
          //this.stepsJson.tour.forEach(x => this.stepsTour.push(x.step))

          this.stepsJson = {
            "tour": [
                {
                    step: "menuBootstrapStep@dashboard",
                    title: "NOVO MENU DE SOLUÇÕES",
                    description: "Menu de soluções completamente reformulado passando a concentrar todas as soluções organizadas por segmentos."
                },
                {
                    step: "headerStep@dashboard",
                    title: "NOVO MENU ADMINISTRATIVO",
                    description: "Menu administrativo passa a ficar na parte de cima do sistema. Concentrando todos os recursos que antes estavam na posição onde agora estão as soluções."
                },
                {
                    step: "cardDashboardStep@dashboard",
                    title: "QUADROS DA PÁGINA INICIAL",
                    description: "Na página inicial, as soluções serão organizadas por segmento, dando mais agilidade ao acesso dos produtos. Sendo possível também acessar diretamente o modo de consulta desejado."
                }
            ]
          }

          this.stepsJson.tour.forEach(x =>
            this.stepsTour.push(x.step)
          );
          this.stepTour = this.stepsTour[this.arrayStepTour].split("@")[0];
          this.titleTour = this.stepsJson.tour[this.arrayStepTour].title;
          this.descriptionTour = this.stepsJson.tour[this.arrayStepTour].description;

          this.appSharedDataService.activeTour$.subscribe((res) => {
            if (res) {
              this.joyrideTour.startTour({
                steps: this.stepsTour,
                showCounter: false
              });
            }
          });
        }
      });
    }
  }

  finalTour(): void {
    this.usuarioService.inactiveTour(this.authenticationService.currentUserValue.id_usuario).subscribe((res) => console.log(res));
  }

}
