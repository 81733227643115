import { FormControl } from "@angular/forms";

export function hostValidator(control: FormControl) {
    var re = new RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);

    if (control.value != undefined && control.value != null && control.value != '' && !re.test(control.value))
        return {
            host: true
        }
    return null;
}

