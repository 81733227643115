import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from './material.module';
import { TextMaskModule } from 'angular2-text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPermissionsModule } from 'ngx-permissions';

import { LoaderComponent } from '@shared/components/loader/loader.component';
import { LoaderInterceptorService } from '@shared/components/loader/loader-interceptor.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '@shared/components/input-dialog/input-dialog.component';
import { BadRequestDialogComponent } from './components/bad-request-dialog/bad-request-dialog.component';
import { HeaderSharedDataService } from './components/header/header-shared-data.service';
import { CustomAutoCompleteDirective } from './directives/custom-auto-complete/custom-auto-complete.directive';

import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbProgressbarModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrDate, BrDateHoraMin } from './pipes/br-date.pipe';
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component';
import { MenuBoostrapComponent } from './components/menu-boostrap/menu-boostrap.component';
import { DynamicFormModule } from './components/dynamic-form/dynamic-form.module';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ConfirmRefreshPageDirective } from './directives/confirm-refresh-page/confirm-refresh-page.directive';
import { NgBrazil } from 'ng-brazil'
import { DynamicResultModule } from './components/dynamic-result/dynamic-result.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgInit } from './directives/ng-init/ng-init.directive';
import { ScrollDirective } from './directives/scroll/scroll.directive';
import { ArraySortPipe } from './pipes/sort.pipe';
import { ArraySortDatePipe } from './pipes/sort-date.pipe';
import { CoreModule } from '@core/core.module';
import { httpInterceptorProviders } from '@core/http-interceptors';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { LoginGuardService } from '@core/auth/login-guard.service';
import { fakeBackendProvider } from '@core/http-interceptors/http-mock.interceptor';
import { customPaginatorIntl } from '@core/config/mat-table-config';
import { currencyMaskProviders } from '@core/config';
import { CustomNgbDateParserFormatter } from '@core/providers/custom-ngb-date-parser-formater';
import { CustomNgbDateAdapter } from '@core/providers/custom-ngb-date-adapter';
import { VersaoPipe } from './pipes/versao.pipe';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { PaginationPipe } from '@shared/pipes/pagination.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InfinityScrollDirective } from './directives/infinity-scroll/infinity-scroll.directive';
import { DataFormComClearComponent } from './components/data-form-com-clear/data-form-com-clear.component';
import { ConfirmSinobProcessamentoDialogComponent } from './components/confirm-sinob-processamento-dialog/confirm-sinob-processamento-dialog.component';
import { TooltipTourComponent } from './components/tooltip-tour/tooltip-tour.component';
import { JoyrideModule } from 'ngx-joyride';
import { FileUploadNewLayoutComponent } from './components/file-upload-new-layout/file-upload-new-layout.component';
import { RnpaAuthInterceptor } from '@modules/consulta/produtos/rnpa-pessoas/services/rnpa-auth.interceptor';

@NgModule({
    declarations: [
        LoaderComponent,
        ConfirmDialogComponent,
        InputDialogComponent,
        BadRequestDialogComponent,
        ConfirmSinobProcessamentoDialogComponent,
        CustomAutoCompleteDirective,
        BrDate,
        BrDateHoraMin,
        BaseDialogComponent,
        MenuBoostrapComponent,
        FileUploadComponent,
        ConfirmRefreshPageDirective,
        NgInit,
        ScrollDirective,
        ArraySortPipe,
        ArraySortDatePipe,
        VersaoPipe,
        PaginationComponent,
        PaginationPipe,
        InfinityScrollDirective,
        DataFormComClearComponent,
        TooltipTourComponent,
        FileUploadNewLayoutComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        TextMaskModule,
        NgbModule,
        FontAwesomeModule,
        NgSelectModule,
        NgxPermissionsModule.forRoot(),
        DynamicFormModule,
        NgBrazil,
        DynamicResultModule,
        NgIdleKeepaliveModule.forRoot(),
        InfiniteScrollModule,
        NgbProgressbarModule,
        NgbDatepickerModule,
        JoyrideModule.forRoot(),
    ],
    exports: [
        CoreModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        TextMaskModule,
        LoaderComponent,
        NgbModule,
        FontAwesomeModule,
        NgSelectModule,
        BrDate,
        BrDateHoraMin,
        NgxPermissionsModule,
        MenuBoostrapComponent,
        DynamicFormModule,
        FileUploadComponent,
        ConfirmRefreshPageDirective,
        NgBrazil,
        DynamicResultModule,
        NgInit,
        ScrollDirective,
        ArraySortPipe,
        ArraySortDatePipe,
        VersaoPipe,
        PaginationComponent,
        PaginationPipe,
        InfiniteScrollModule,
        InfinityScrollDirective,
        DataFormComClearComponent,
        TooltipTourComponent,
        NgbProgressbarModule,
        NgbDatepickerModule,
        FileUploadNewLayoutComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: MatDialogRef, useValue: {} }, { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatBottomSheetRef, useValue: {} }, { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
        HeaderSharedDataService,
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        httpInterceptorProviders,
        LocalStorageService,
        AuthGuardService,
        LoginGuardService,
        { provide: LOCALE_ID, useValue: 'pt' },
        fakeBackendProvider,
        { provide: MatPaginatorIntl, useValue: customPaginatorIntl() },
        currencyMaskProviders,
        CurrencyPipe,
        { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
        { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RnpaAuthInterceptor,
            multi: true,
        },
    ]
})
export class SharedModule { }
