import { MatPageable } from '@core/table/mat-table';

export class TipoProdutoCombo {
    id: string;
    ativo: number;
    tipo: string;
}

export class TipoProdutoElement {
    id: string;
    ativo: string;
    nome: string;
    paginacao?: MatPageable;
}

export class TipoProduto {
    id:string;
    idTipoProduto: string;
    nome: string;
    ativo: string;
}

export class FiltroTipoProduto {
    nome: string;
    idStatus: number;
}


export enum TipoProdutoEnum {
    LinkExterno = '1',
    Padrao = '2',
    Homologacao = '3'
}
