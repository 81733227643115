import { AuthenticationService } from '@core/auth/authentication.service';
import { UserAuth } from '@core/auth/user';
import { PerfilEnum } from '@core/models/perfil.models';
import { Directive, HostListener } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EmpresaEnum } from '@core/models/empresa.models';

@Directive()
export class BaseComponent {

    constructor(public authenticationService: AuthenticationService) {

    }

    get isMaster() {
        return this.usuarioLogado && this.usuarioLogado.id_perfil.toString() == PerfilEnum.Master;
    }

    get isUsuario() {
        return this.usuarioLogado && this.usuarioLogado.id_perfil.toString() == PerfilEnum.Usuario;
    }

    get isSuperUsuario() {
        return this.usuarioLogado && this.usuarioLogado.id_perfil.toString() == PerfilEnum.SuperUsuario;
    }

    get usuarioLogado(): UserAuth {
        return this.authenticationService.currentUserValue;
    }

    get isCNSEG(): boolean {
        return this.usuarioLogado.id_empresa == EmpresaEnum.CNSeg;
    }

    possuiPerfil(perfil: PerfilEnum[]): boolean {

        let possui: boolean = false;
        perfil.forEach((per) => {
            if (!possui)
                possui = this.usuarioLogado.id_perfil.toString().includes(per);
        })

        return possui;
    }

    close(): void {

    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.close();
    }

    @HostListener('window:popstate', ['$event']) onPopState(event) {
        this.close();
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.updateValueAndValidity({ emitEvent: true, onlySelf:true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
}
