import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { LabelResultComponent } from '../label-result/label-result.component';
import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { ArrayResultComponent } from '../array-result/array-result.component';
import { ObjetoResultComponent } from '../objeto-result/objeto-result.component';
import { DataResultComponent } from '../data-result/data-result.component';
import { BooleanResultComponent } from '../boolean-result/boolean-result.component';
import { HoraResultComponent } from '../hora-result/hora-result.component';
import { LinkResultComponent } from '../link-result/link-result.component';
import { MoedaResultComponent } from '../moeda-result/moeda-result.component';

const components: { [type: string]: Type<Field> } = {
  label: LabelResultComponent,
  array: ArrayResultComponent,
  objeto: ObjetoResultComponent,
  data: DataResultComponent,
  boolean: BooleanResultComponent,
  hora: HoraResultComponent,
  link: LinkResultComponent,
  moeda: MoedaResultComponent
};

@Directive({
  selector: '[dynamicFieldResult]'
})
export class DynamicFieldResultDirective implements Field, OnChanges, OnInit {
  @Input()
  config: FieldConfig;

  @Input()
  group: FormGroup;

  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
    }
  }

  ngOnInit() {

    if (this.config) {
      if (!components[this.config.type]) {
        const supportedTypes = Object.keys(components).join(', ');
        throw new Error(
          `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
        );
      }
      const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
      this.component = this.container.createComponent(component);
      this.component.instance.config = this.config;
    }
  }
}
