export enum TipoPropostaApoliceEnum {
    Vi = 'VI',
    Vg = 'VG'
}

export enum TipoCadastroEnum{
    ApiIndividual = 'API_INDIVIDUAL',
    ApiLote = 'API_LOTE',
    TelaIndividual = 'TELA_INDIVIDUAL',
    TelaLote = 'TELA_LOTE'
}