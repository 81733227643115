import { Component } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-table',
  styleUrls: ['./form-table.component.scss'],
  template: `
  <div class="form-group col-md" [formGroup]="group">
     <label for="{{config.name}}">{{ config.label }} :</label>
     <span>{{ config.value }}</span>
      <div>
        <input type="text" class="form-control input-sm" [formControlName]="config.name" [hidden]="true"/>
      </div>
</div>
`
})
export class FormTableComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
