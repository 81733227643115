import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormDatepickerComponent } from './components/form-datepicker/form-datepicker.component';
import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormSubmitDirective } from './directives/form-submit/form-submit.directive';
import { ControlErrorDirective } from './directives/control-error/control-error.directive';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { ControlErrorContainerDirective } from './directives/control-error/control-error-container.directive';
import { TextMaskModule } from 'angular2-text-mask';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { FormTableComponent } from './components/form-table/form-table.component';
import { FormPeriodoComponent } from './components/form-periodo/form-periodo.component';
import { FormDatepickerTracoComponent } from './components/form-datepicker-traco/form-datepicker-traco.component';
import { FormCpfComponent } from './components/form-cpf/form-cpf.component';
import { FormCnpjComponent } from './components/form-cnpj/form-cnpj.component';
import { FormCpfCnpjComponent } from './components/form-cpf-cnpj/form-cpf-cnpj.component';
import { FormCepComponent } from './components/form-cep/form-cep.component';
import { FormDatepickerAAAAMMDDComponent } from './components/form-datepicker-aaaammdd/form-datepicker-aaaammdd.component';
import { FormChassiComponent } from './components/form-chassi/form-chassi.component';
import { FormPlacaComponent } from './components/form-placa/form-placa.component';

@NgModule({
    declarations: [
        FormInputComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        DynamicFieldDirective,
        DynamicFormComponent,
        FormButtonComponent,
        FormSubmitDirective,
        ControlErrorDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,
        ControlErrorComponent,
        FormLabelComponent,
        FormTableComponent,
        FormPeriodoComponent,
        FormDatepickerTracoComponent,
        FormCpfComponent,
        FormCnpjComponent,
        FormCpfCnpjComponent,
        FormCepComponent,
        FormDatepickerAAAAMMDDComponent,
        FormChassiComponent,
        FormPlacaComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesomeModule,
        TextMaskModule,
        FormsModule
    ],
    exports: [
        DynamicFieldDirective,
        DynamicFormComponent,
        FormSubmitDirective,
        ControlErrorDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,
        ControlErrorComponent,
    ]
})
export class DynamicFormModule { }
