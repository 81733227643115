import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Masks } from '@core/custom-validators/masks';
import { PropostaService } from '../../services/proposta.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-formulario-consulta',
  templateUrl: './formulario-consulta.component.html',
  styleUrls: ['./formulario-consulta.component.scss']
})
export class FormularioConsultaComponent implements OnInit {

  consultaBody: {numeroProposta: string, documento: string} = {numeroProposta: null, documento: null}

  maskCpfCnpj = {
    mask: Masks.cpfCnpj,
    guide: false,
    modelClean: true
  };

  @Output() submitEvent = new EventEmitter<any>()

  constructor(
    private _propostaService: PropostaService,
    private _toastService: ToastService
  ) { }

  ngOnInit() {
  }

  consultarProposta(){
    const reqBody = {
      documento: this.consultaBody.documento.replace(/\D/g, ''),
      numeroProposta: this.consultaBody.numeroProposta
    }
    
    this._propostaService.consultarAnaliseRisco(reqBody).subscribe((res) => {
      this.submitEvent.emit(res)
    },
    error => {
      this._toastService.showError('Não foi possível concluir a análise com os dados informados.')
    })
  }

}
