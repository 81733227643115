import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';
import { Masks } from '@core/custom-validators/masks';
import { CustomValidators } from '@core/custom-validators/validators';

@Component({
  selector: 'app-form-cnpj',
  templateUrl: './form-cnpj.component.html',
  styleUrls: ['./form-cnpj.component.scss']
})
export class FormCnpjComponent implements Field, OnInit {
  formCNPJ: FormGroup;
  config: FieldConfig;
  group: FormGroup;
  mask = {
    mask: Masks.cnpj,
    guide: false,
    modelClean: true
  };
  firstSubmit = false;
  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.formCNPJ = this.formBuilder.group({
      cnpj: ['']
    })

    if (this.config.required) {
      this.cnpj.setValidators(Validators.compose([Validators.required, CustomValidators.cnpj]))
    }
    else
      this.cnpj.setValidators(Validators.compose([CustomValidators.cnpj]))

    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.cnpj.setValue(this.cnpj.value);
      }
    })

    this.cnpj.valueChanges.subscribe((val) => {
      if (this.cnpj.valid) {
        this.control.setValue(this.clearMaskValue(val));
      }
      else {
        this.control.setValue(undefined);
      }
    })
  }

  get cnpj() {
    return this.formCNPJ.controls.cnpj;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  clearMaskValue(val): string {
    if (val)
      return val.replace(/[^\w\s]/gi, '');

    return val;
  }
}