import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Tab } from '@core/models/tab.models';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsSharedDataService } from '@modules/tabs/tabs-shared-data.service';
import { faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons';
import { PdfService } from '@core/services/pdf.service';
import { BaseComponent } from '@core/component/base.component';
import { AuthenticationService } from '@core/auth/authentication.service';
import { Location } from '@angular/common';
import { DynamicContentComponent } from '@shared/components/dynamic-content/dynamic-content.component';
import { Subscription } from 'rxjs';
import { DynamicContentService } from '@core/services/dynamic-content.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Component({
  selector: 'app-tab-resultado',
  templateUrl: './tab-resultado.component.html',
  styleUrls: ['./tab-resultado.component.scss']
})
export class TabResultadoComponent extends BaseComponent implements OnInit, OnDestroy {


  tab: Tab;
  error: string;
  id: string;
  faFilePdf = faFilePdf;
  faPrint = faPrint;
  @ViewChild(DynamicContentComponent) dynamicContent: DynamicContentComponent;
  subScribeTab: Subscription;
  subScribeHasTab: Subscription;

  constructor(private tabsSharedDataService: TabsSharedDataService,
    private activeRouted: ActivatedRoute,
    private pdfService: PdfService,
    private router: Router,
    authenticationService: AuthenticationService,
    private location: Location,
    private dynamicContentService: DynamicContentService,
    private localStorageService: LocalStorageService) {
    super(authenticationService)
  }

  ngOnInit() {

    this.subScribeTab = this.tabsSharedDataService.returnTab$.subscribe((data) => {
      this.tab = data;
      if (!data) {
        this.router.navigate(['/dashboard']);
        return;
      }
      if (this.tab.isResultadoCustomizado) {
        setTimeout(() => {
          this.dynamicContentService.generateCustomContent(this.dynamicContent, this.tab.template, this.tab.objRetornoServico);
        }, 200)
      }
    })

    this.subScribeHasTab = this.tabsSharedDataService.noHasTab$.subscribe((data) => {
      if (data === true && this.router.url.startsWith('/tab')) {
        this.router.navigate(['/dashboard']);
        return;
      }
    })

    this.activeRouted.params.subscribe((params) => {
      this.id = params.id;
      this.tab = new Tab();
      this.tabsSharedDataService.setGetTab(this.id);
    })
  }

  ngOnDestroy(): void {
    this.subScribeTab.unsubscribe();
    this.subScribeHasTab.unsubscribe();
  }

  get showForm() {
    return this.tab && ((this.tab.configs && this.tab.configs.length > 0) || this.tab.hasError);
  }

  exportPDF() {
    this.pdfService.htmlToPDF('resultadoAba', `${this.tab.nomeMetodo}.pdf`);
  }

  voltar(): void {
    let ultima = this.localStorageService.getItem('ultimaConsulta');
    let last = this.localStorageService.getItem('lastUrl');
    console.log(last)
    if (ultima && last && last == ultima) {
      this.router.navigateByUrl(ultima);
    }
    else if (ultima && last && last != ultima) {
      this.router.navigateByUrl(last);
    }
    else if (ultima) {
      this.router.navigateByUrl(ultima);
    }
    else
      this.location.back();


  }

  print(): void {
    this.pdfService.beforeCanvas('resultadoAba');
    window.onafterprint = () => {
      this.pdfService.afterCanvas('resultadoAba');
    }
    window.print();
  }
}
