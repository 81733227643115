import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-news',
  templateUrl: './dash-news.component.html',
  styleUrls: ['./dash-news.component.scss']
})
export class DashNewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
