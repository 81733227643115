import {
  Component,
  ViewChild, ViewContainerRef, ComponentRef,
  Compiler, ComponentFactory, NgModule, ModuleWithComponentFactories, ComponentFactoryResolver, ViewEncapsulation
} from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { BrDateResultPipe } from '../dynamic-result/pipes/br-date-result.pipe';
import { CommonModule } from '@angular/common';
import { DynamicContentModule } from './dynamic-content.module';


@Component({
  selector: 'dynamic-content',
  styleUrls: ['./dynamic-content.component.scss'],
  template: `
  <div #container></div>
  `,
})
export class DynamicContentComponent {

  @ViewChild('container', { read: ViewContainerRef,static:true })
  container: ViewContainerRef;

  private componentRef: ComponentRef<{}>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private compiler: Compiler) {
  }

  compileTemplate(template: string,obj:any) {

    let metadata = {
      selector: `runtime-component`,
      template: template
    };

    let factory = this.createComponentFactorySync(this.compiler, metadata, obj);

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    this.componentRef = this.container.createComponent(factory);
  }

  private createComponentFactorySync(compiler: Compiler, metadata: Component, componentClass: any): ComponentFactory<any> {
    const decoratedCmp = Component(metadata)(componentClass);

    @NgModule({ imports: [SharedModule], declarations: [decoratedCmp] })
    class RuntimeComponentModule { }

    let module: ModuleWithComponentFactories<any> = compiler.compileModuleAndAllComponentsSync(RuntimeComponentModule);
    return module.componentFactories.find(f => f.componentType === decoratedCmp);
  }
}


export class RuntimeComponent {
  obj: any = {}
}
