import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';
import { Masks } from '@core/custom-validators/masks';
import { CustomValidators } from '@core/custom-validators/validators';

@Component({
  selector: 'app-form-cpf',
  templateUrl: './form-cpf.component.html',
  styleUrls: ['./form-cpf.component.scss']
})
export class FormCpfComponent implements Field, OnInit {
  formCPF: FormGroup;
  config: FieldConfig;
  group: FormGroup;
  mask = {
    mask: Masks.cpf,
    guide: false,
    modelClean: true
  };
  firstSubmit: boolean;

  constructor(private formBuilder: FormBuilder) {

  }
  ngOnInit(): void {
    this.formCPF = this.formBuilder.group({
      cpf: ['']
    })

    if (this.config.required) {
      this.cpf.setValidators(Validators.compose([Validators.required, CustomValidators.cpf]))
    }
    else
      this.cpf.setValidators(Validators.compose([CustomValidators.cpf]))


    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.cpf.setValue(this.cpf.value);
      }
    })

    this.cpf.valueChanges.subscribe((val) => {
      if (this.cpf.valid) {
        this.control.setValue(this.clearMaskValue(val));
      }
      else {
        this.control.setValue(undefined);
      }
    })
  }

  get cpf() {
    return this.formCPF.controls.cpf;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  clearMaskValue(val): string {
    if (val)
      return val.replace(/[^\w\s]/gi, '');

    return val;
  }
}