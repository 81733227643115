import { Injectable } from '@angular/core';

import { DadosUsuario } from '@models/dados-usuario';
import { UsuarioElement, UsuarioCadastro } from '@models/usuario';
import { ApiService } from './api.service';
import { MatPageable } from '@core/table/mat-table';
import { UsuarioFiltro, UsuarioCombo } from '@core/models/usuario';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@core/auth/authentication.service';
import { map } from 'rxjs/operators';
import { DadosPerfil, FuncionalidadePerfil, DadosUsuarioAlterar, SenhaAlterar } from '@core/models/meu-perfil.models';
import { environment } from '@env/environment';
import { of } from 'rxjs';

const routes = {
  meusDados: 'meusDados',
  buscaPorId: (id: number) => `usuario/buscaporid/${id}`,
  dadosPerfil: (id: number) => `acoesusuario/buscaporid/${id}`,
  buscarTodos: (pagina: number, registros: number) => `usuario/buscatodos/${pagina}/${registros}`,
  cadastrar: 'usuario/cadastrar',
  alterar: `usuario/alterar`,
  inativar: `usuario/inativar`,
  bloquear: `usuario/bloquear/`,
  desbloquear: `usuario/desbloquear`,
  reativar: `usuario/ativar`,
  buscaPorValores: `usuario/buscaporvalores/*`,
  esqueciSenha: `usuario/esquecisenha`,
  gravarSenha: `usuario/gravarsenha`,
  validarHash: `usuario/trocarsenha`,
  produtosServicos: (pagina: number, registros: number, idUsuario: number) => `acoesusuario/listaprodutoservicos/${pagina}/${registros}/${idUsuario}`,
  funcionalidades: (pagina: number, registros: number, idUsuario: number) => `acoesusuario/listafuncionalidade/${pagina}/${registros}/${idUsuario}`,
  alterarMeusDados: `acoesusuario/alterar/`,
  getPorIdCrud: (idUsuario: number) => `crud/usuario/${idUsuario}`,
  alterarUsuarioCrud: `crud/alterarUsuario`,
  trocarSenhaCrud: `crud/usuarios/trocarsenha`,
  servicosContratados: (idEmpresa: number, idPerfil: number) => `crud/servicoscontratados?idPerfil=${idPerfil}&idEmpresa=${idEmpresa}`,
  exportarPDF: `relatorios/usuario/pdf`,
  exportarExcel: `relatorios/usuario/xls`,
  ativarMultiplos: `usuario/ativarefetivos`,
  inativarMultiplos: `usuario/inativarefetivos`,
  bloquearMultiplos: `usuario/bloquearefetivos`,
  desbloquearMultiplos: `usuario/desbloquearefetivos`,
  tourUser: (idUsuario: number) => `acoesusuario/tour/${idUsuario}`
}

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private apiService: ApiService,
    private authenticationService: AuthenticationService) {
  }

  public getMeusDados() {
    return this.apiService.get<DadosUsuario>(routes.meusDados)
  }

  public getUsuarios(pagination: MatPageable, filtro: UsuarioFiltro) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.get<UsuarioElement[]>(routes.buscaPorValores + pagination.query, params);
  }

  public buscaTodosCombo(pagination: MatPageable) {
    return this.apiService.get<UsuarioCombo[]>(routes.buscarTodos(pagination.pagina, pagination.registros)).pipe(map((data) => {
      return data.sort((a, b) => {
        if (a.nomeCompleto < b.nomeCompleto) {
          return -1;
        }
        if (a.nomeCompleto > b.nomeCompleto) {
          return 1;
        }
        return 0;
      });
    }));
  }

  public getUsuario(idUsuario: number) {
    return this.apiService.getCustomApi<UsuarioCadastro>(`${environment.lote}/${routes.getPorIdCrud(idUsuario)}`)
  }

  public getDadosPerfil(idUsuario: number) {
    return this.apiService.get<DadosPerfil>(routes.dadosPerfil(idUsuario))
  }

  public salvarUsuario(obj: any) {

    obj.idUsuarioCadastro = this.authenticationService.currentUserValue.id_usuario;

    if (obj.id)
      return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.alterarUsuarioCrud}`, obj)
    else
      return this.apiService.post<any>(routes.cadastrar, { usuario: obj })
  }

  public alterarUsuario(obj: any) {
    obj.idUsuarioCadastro = this.authenticationService.currentUserValue.id_usuario;
    return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.alterarUsuarioCrud}`, obj)
  }

  public inativar(ids: number[], motivo: string) {

    let obj = {
      idUsuario: ids.join(','),
      motivo: motivo
    }
    return this.apiService.putCustomApi(`${environment.lote}/${routes.inativar}`, obj);
  }


  public reativar(ids: number[]) {

    let obj = {
      idUsuario: ids.join(',')
    }
    return this.apiService.putCustomApi(`${environment.lote}/${routes.reativar}`, obj);
  }

  public bloquear(ids: number[], justificativa: string) {

    let obj = {
      idUsuario: ids.join(','),
      motivo: justificativa
    }
    return this.apiService.putCustomApi(`${environment.lote}/${routes.bloquear}`, obj);
  }

  public desbloquear(ids: number[]) {
    let obj = {
      idUsuario: ids.join(',')
    }
    return this.apiService.putCustomApi(`${environment.lote}/${routes.desbloquear}`, obj);
  }

  public esqueciSenha(email: string) {

    let obj = {
      usuario: {
        email: email,
      }
    }
    return this.apiService.post<any>(routes.esqueciSenha, obj);
  }

  public gravarSenha(senha: string, hash: string) {

    let obj = {
      usuario: {
        senha,
        hash
      }
    }
    return this.apiService.put<any>(routes.gravarSenha, obj);
  }

  public validarHash(hash: string) {
    let obj = {
      usuario: {
        hash: hash
      }
    }
    return this.apiService.post<any>(routes.validarHash, obj);
  }

  getProdutosServicos(pagination: MatPageable, idUsuario: number) {
    return this.apiService.get<any[]>(routes.produtosServicos(pagination.pagina, pagination.registros, idUsuario));
  }

  getFuncionalidades(pagination: MatPageable, idUsuario: number) {
    return this.apiService.get<FuncionalidadePerfil[]>(routes.funcionalidades(pagination.pagina, pagination.registros, idUsuario));
  }

  public alterarMeusDados(obj: DadosUsuarioAlterar) {
    obj.idUsuario = this.authenticationService.currentUserValue.id_usuario;
    return this.apiService.put<any>(routes.alterarMeusDados, {
      AcoesUsuario: obj
    })
  }

  trocarSenha(obj: SenhaAlterar) {
    return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.trocarSenhaCrud}`, obj);
  }

  servicosContratados(idEmpresa: number, idPerfil: number) {
    return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.servicosContratados(idEmpresa, idPerfil)}`);
  }

  exportarPDF(filtro: UsuarioFiltro) {
    return this.apiService.downloadCustomApi(`${environment.lote}/${routes.exportarPDF}`, filtro);
  }

  exportarExcel(filtro: UsuarioFiltro) {
    return this.apiService.downloadCustomApi(`${environment.lote}/${routes.exportarExcel}`, filtro);
  }

  public ativarMultiplos(ids: number[]) {
    return this.apiService.put(routes.ativarMultiplos, ids);
  }

  public inativarMultiplos(ids: number[]) {
    return this.apiService.put(routes.inativarMultiplos, ids);
  }

  public bloquearMultiplos(ids: number[]) {
    return this.apiService.put(routes.bloquearMultiplos, ids);
  }

  public desbloquearMultiplos(ids: number[]) {
    return this.apiService.put(routes.desbloquearMultiplos, ids);
  }

  inactiveTour(idUsuario: number) {
    return this.apiService.post(routes.tourUser(idUsuario), null)
  }
}
