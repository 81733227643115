import { MatPageable } from '@core/table/mat-table';

export class RamoCombo {
    id?: string;
    nome?: string;
    ativo?: string;
}

export class RamoElement {
    id: string;
    ativo: string;
    tipo: string;
    paginacao?: MatPageable;
}

export class Ramo {
    nome?: string;
    idRamo?: number;
    tipo?: string;
    ativo?: string;
    imagem?: string;
}

export class FiltroRamo {
    nome?: string;
    idStatus?: string;
}

export class RamoCard {
    nome: string;
    imagem: string;
    id: string;
}
export enum RamosMenuEnum {
    Automovel = '1',
    Habitacional = '2',
    Multiramo = '3',
    Saude = '4',
    Previdencia = '5',
    Vida = '6'
}

export interface IRamos {
    id: string, //codigo do ramo
    ativo: string,
    nome: string, //nome do ramo
    produtos: IProdutos[]
}

export interface IProdutos {
    descricaoDetalhada: string,
    descricaoResumida: string,
    dsServico: string,
    idProduto: number,
    imgProduto: string,
    favorito: boolean,
    acesso: boolean,
    metodos: [
        {
            id: number,
            nome: string,
            dsMetodo: string,
            acesso: boolean,
            permitePortal: boolean
        }
    ],
    nomeProduto: string,
    linkExterno: string,
    idTipoProduto: number,
    prioridade: number
}