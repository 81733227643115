import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRamos } from '@core/models/ramo.models';
import { ConsultaService } from '@core/services/consulta.service';
import { DashboardService } from '@core/services/dashboard.service';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { StorageService } from '@shared/utils/services/storage.service';
import moment from 'moment';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-produto-ramo',
  templateUrl: './produto-ramo.component.html',
  styleUrls: ['./produto-ramo.component.scss']
})
export class ProdutoRamoComponent implements OnInit {

  ramos: IRamos[] = []
  idRamo: number;

  constructor(private activeRoute: ActivatedRoute,
    private storageService: StorageService,
    private router: Router,
    private consultaService: ConsultaService,
    private appSharedDataService: AppSharedDataService,
    private dashboardService: DashboardService) { }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.idRamo = params.idRamo;
      this.buscarDados();
    })
  }

  get ramo(){
    return this.ramos.find((ramo) => +ramo.id === +this.idRamo)
  }

  get produtos() {
    return this.ramo.produtos
  }

  get hasProdutos(): boolean {
    return !!this.produtos.length
  }

  buscarDados() {
   this.ramos = this.storageService.get({ storageKey: EStorage.MENU }).value

    if (!(this.ramos && this.ramos.length)) {
      this.dashboardService.getAllRamos().subscribe((ramoRes) => {
        this.ramos = ramoRes.body
        this.storageService.set({ storageKey: EStorage.MENU, data: ramoRes.body, expire: moment().add(1, 'hour').toDate() })
      })
    }

  }


  onDesFavorite(idProduto: number): void {
    this.consultaService.desFavoritarProduto(idProduto).subscribe((data) => this.buscarDados());
  }

  onFavorite(idProduto: number): void {
    this.consultaService.favoritarProduto(idProduto).subscribe((data) => this.buscarDados());
  }

  btnHome(): void {
    this.appSharedDataService.setResetMenuBootstrap(true);
    this.router.navigate(['/dashboard']);
  }
}