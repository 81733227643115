import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RnpaRestInterface } from './rnpa-rest-interface.service';

@Injectable({
  providedIn: 'root'
})
export class RamosCoberturasService extends RnpaRestInterface {

  consultaUrl = environment.apiGatewayDns

  private _coberturasUrl = '/rnpa-pessoas/v1/ramos-coberturas/coberturas?ativo=true&page=1&limit=100'

  constructor(
    public http: HttpClient
  ) { 
    super(http)
  }
  
  // GET coberturas
  listCoberturas(){
    const url = this.consultaUrl + this._coberturasUrl
    return this.get(url)
  }
}