import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';


@Component({
  exportAs: 'dynamicResult',
  selector: 'dynamic-result',
  styleUrls: ['dynamic-result.component.scss'],
  template: `
    <form
      [formGroup]="dynamicResult" id="dynamicResult"
      (submit)="handleSubmit($event)">
      <table>
        <tr  *ngFor="let field of config;">
         <td>
            <ng-container dynamicFieldResult [config]="field" [group]="dynamicResult">
            </ng-container>
         </td>
        </tr>
      </table>
    </form>
  `
})
export class DynamicResultComponent implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  dynamicResult: FormGroup;

  get controls() { return this.config.filter(({ type }) => type !== 'button'); }
  get changes() { return this.dynamicResult.valueChanges; }
  get valid() { return this.dynamicResult.valid; }
  get value() { return this.dynamicResult.value; }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.dynamicResult = this.createGroup();
  }

  ngOnChanges() {
    if (this.dynamicResult) {
      const controls = Object.keys(this.dynamicResult.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.dynamicResult.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.dynamicResult.addControl(name, this.createControl(config));
        });

    }
  }

  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.validateAllFormFields(this.dynamicResult);
    this.submit.emit(this.value);
  }

  setDisabled(name: string, disable: boolean) {
    if (this.dynamicResult.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.dynamicResult.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    this.dynamicResult.controls[name].setValue(value, { emitEvent: true });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}