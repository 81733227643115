import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { TabResultadoComponent } from '@modules/tabs/components/tab-resultado/tab-resultado.component';

const routes: Routes = [
    {
        path: 'tab/:id',
        canActivate: [AuthGuardService],
        component: TabResultadoComponent,
        data: {
            reuse: true
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TabsRoutingModule { }
