import { MatPageable } from '@core/table/mat-table';
import { environment } from '@env/environment';
import { ParametroMetodo, ParametroMetodoCrud } from './parametro-metodo.models';

export class Metodo {
    id: number;
    idProduto: number;
    nomeMetodo: string;
    ativo: number;
    indSincrono: number;
    idMetodoResultado: string;
    qtdMinBuscaResultado: string;
    idMetodo: number;
    nomeMetodoServico: string;
    urlMetodo: string;
    metodoHttp: string;
}

export class MetodoElement {
    expand: boolean;
    id: number;
    idProduto: number;
    nomeMetodo: string;
    ativo: number;
    indSincrono: number;
    idMetodoResultado: number;
    qtdMinBuscaResultado: number;
    paginacao?: MatPageable;
    nomeMetodoResultado: string;
    parametrosEntrada?: ParametroMetodo[];
    parametrosSaida?: ParametroMetodo[];
    parametrosErro?: ParametroMetodo[];
    nomeMetodoServico: string;
    urlMetodo: string;
    metodoHttp: string;
}

export class MetodoCombo {
    id: number;
    nomeMetodo: string;
}

export class FiltroMetodo {
    idProduto?: any;
    nomeMetodo?: string;
    indSincrono?: boolean;
    idMetodoResultado?: number;
    qtdMinBuscaResultado?: number;
    lote?: number;
}

export class MetodoCrud {
    id: number;
    idProduto: number;
    nomeMetodo: string;
    ativo: boolean;
    indSincrono: boolean;
    idMetodoResultado: string;
    qtdMinBuscaResultado: string;
    idMetodo: number;
    nomeMetodoServico: string;
    urlMetodo: string;
    metodoHttp: string;
    parametros: ParametroMetodoCrud[];
    template: string;
    obrigatorio: boolean;
    portal: boolean;
    lote: boolean;
}


export enum MetodoEnum {
    SivvePlusCNPJ = environment.production ? 877 : 877,
    SivvePlusCPF = environment.production ? 876 : 876,
    SivvePlusCPFV2 = environment.production ? 933 : 933,
    SivveCPF = environment.production ? 856 : 856,
    SivveQuadroPessoal = environment.production ? 886 : 886,
    SivveQuadroPessoalV2 = environment.production ? 890 : 890,
    ConsultaAvancada = environment.production ? 898 : 898,
    SivvePremiumCPF = environment.production ? 937 : 937,
    SivvePremiumCNPJ = environment.production ? 938 : 938

}