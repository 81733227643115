import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatPageable } from '@core/table/mat-table';
import { ParametroPortalCombo, FiltroParametroPortal, ParametroPortalElement, ParametroPortal } from '@core/models/parametro-portal.models';

const routes = {
  buscatodos: (pagina: number, registros: number) => `parametrosportal/buscatodos/${pagina}/${registros}`,
  buscaPorParametros: `parametrosportal/buscapornome`,
  inativar: `parametrosportal/inativar`,
  reativar: `parametrosportal/reativar`,
  buscaPorId: (id: number) => `parametrosportal/buscaporid/${id}`,
  cadastrar: `parametrosportal/cadastrar`,
  atualizar: `parametrosportal/alterar`,
}


@Injectable({
  providedIn: 'root'
})
export class ParametroPortalService {


  constructor(private apiService: ApiService) { }

  buscarTodosCombo(pagination: MatPageable) {
    return this.apiService.get<ParametroPortalCombo[]>(routes.buscatodos(pagination.pagina, pagination.registros));
  }

  getParametroPortais(pagination: MatPageable, filtro: FiltroParametroPortal) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.get<ParametroPortalElement[]>(routes.buscaPorParametros + pagination.query, params);
  }

  inativar(idParametroPortal: string) {
    return this.apiService.put(routes.inativar, {
      ParametrosPortal: {
        idParametrosPortal: idParametroPortal
      }
    });
  }

  reativar(idParametroPortal: string) {
    return this.apiService.put(routes.reativar, {
      ParametrosPortal: {
        idParametrosPortal: idParametroPortal
      }
    });
  }

  salvar(parametroPortal: ParametroPortal) {
    if (parametroPortal.idParametro)
      return this.apiService.put<any>(routes.atualizar, {
        ParametrosPortal: parametroPortal
      });
    else
      return this.apiService.post<any>(routes.cadastrar, {
        ParametrosPortal: parametroPortal
      });
  }

  getParametroPortal(id: number) {
    return this.apiService.get<ParametroPortal>(routes.buscaPorId(id));
  }
}
