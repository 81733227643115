import { Component, OnInit } from '@angular/core';
import { ToastService } from '@modules/consulta/produtos/rnpa-pessoas/services/toast.service';
import { EmailMktService, IEmailBody } from '@modules/dashboard/services/email-mkt.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dash-carrousel',
  templateUrl: './dash-carrousel.component.html',
  styleUrls: ['./dash-carrousel.component.scss']
})
export class DashCarrouselComponent implements OnInit {

  emailBody: IEmailBody = {
    interest: null,
    subject: null,
    segment: null,
    areaOfActivity: null,
    message: null
  }

  assuntos = ['Dúvida', 'Sugestão', 'Reclamação', 'Outro']
  segmentos = ['Automóvel', 'Diversos', 'Vida e Previdência', 'Patrimonial', 'Crédito e Garantia', 'Rural', 'Saúde', 'Capitalização']
  areasDeAtuacao = ['Subscrição', 'Precificação', 'Regulação de Sinistros', 'Prevenção à Fraude', 'Compliance', 'Tecnologia da Informação', 'Outra']
  
  constructor(private nbgModalService: NgbModal, private _emailMktService: EmailMktService, private _toastService: ToastService) { }

  ngOnInit(): void {
  }

  openModal(content) {
    this.nbgModalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl', windowClass: 'custom-modal email-modal' })
  }

  setEmailInterest(value: string){
    this.emailBody.interest = value
  }

  sendEmail(){
    this._emailMktService.sendEmail(this.emailBody).subscribe((res) => {
      this._toastService.showSuccess("Mensagem enviada! Em breve, um membro de nossa equipe entrará em contato.")
      this.nbgModalService.dismissAll('Success!')
      this.resetEmailBody()
    }, err => {
      this._toastService.showError("Não foi possível concluir o envio.")
    })
  }

  resetEmailBody(){
    this.emailBody = {
      interest: null,
      subject: null,
      segment: null,
      areaOfActivity: null,
      message: null
    }
  }

}
