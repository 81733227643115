import { AppSharedDataService } from 'src/app/app-shared-data.service';
import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Masks } from '@core/custom-validators/masks';
import { FieldConfig } from '../../models/field-config.interface';
import { CustomValidators } from '@core/custom-validators/validators';

@Component({
  selector: 'app-form-placa',
  templateUrl: './form-placa.component.html',
  styleUrls: ['./form-placa.component.scss']
})
export class FormPlacaComponent implements Field, OnInit {
  formPlaca: FormGroup;
  config: FieldConfig;
  group: FormGroup;
  mask = {
    mask: Masks.placa,
    guide: false,
    modelClean: true
  };
  firstSubmit: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formPlaca = this.formBuilder.group({
      placa: ['']
    })

    if (this.config.required) {
      this.placa.setValidators(Validators.compose([Validators.required, Validators.minLength(7), CustomValidators.placa]))
      this.placa.updateValueAndValidity()

    }
    else {
      this.placa.setValidators(Validators.compose([CustomValidators.placa]))
    }

    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.placa.setValue(this.placa.value);
      }
    })

    this.placa.valueChanges.subscribe((val) => {

      if (this.placa.valid) {
        this.control.setValue(this.clearMaskValue(val));
      }
      else {
        this.control.setValue(undefined);
      }

    })
  }

  get placa() {
    return this.formPlaca.controls.placa;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  clearMaskValue(val): string {
    if (val)
      return val.replace(/[^\w\s]/gi, '');

    return val;
  }
}
