import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormInputComponent } from '@shared/components/dynamic-form/components/form-input/form-input.component';
import { FormSelectComponent } from '@shared/components/dynamic-form/components/form-select/form-select.component';
import { FormDatepickerComponent } from '@shared/components/dynamic-form/components/form-datepicker/form-datepicker.component';
import { FormButtonComponent } from '@shared/components/dynamic-form/components/form-button/form-button.component';

import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { FormLabelComponent } from '../form-label/form-label.component';
import { FormPeriodoComponent } from '../form-periodo/form-periodo.component';
import { FormDatepickerTracoComponent } from '../form-datepicker-traco/form-datepicker-traco.component';
import { FormCpfComponent } from '../form-cpf/form-cpf.component';
import { FormCnpjComponent } from '../form-cnpj/form-cnpj.component';
import { FormCpfCnpjComponent } from '../form-cpf-cnpj/form-cpf-cnpj.component';
import { FormCepComponent } from '../form-cep/form-cep.component';
import { FormDatepickerAAAAMMDDComponent } from '../form-datepicker-aaaammdd/form-datepicker-aaaammdd.component';
import { FormChassiComponent } from '../form-chassi/form-chassi.component';
import { FormPlacaComponent } from '../form-placa/form-placa.component';

const components: { [type: string]: Type<Field> } = {
  input: FormInputComponent,
  select: FormSelectComponent,
  datepicker: FormDatepickerComponent,
  button: FormButtonComponent,
  label:FormLabelComponent,
  periodoAno:FormPeriodoComponent,
  dataComTraco:FormDatepickerTracoComponent,
  cpf:FormCpfComponent,
  cnpj:FormCnpjComponent,
  cpfCnpj:FormCpfCnpjComponent,
  cep:FormCepComponent,
  formDatepickerAAAAMMDD:FormDatepickerAAAAMMDDComponent,
  chassi:FormChassiComponent,
  placa:FormPlacaComponent
};

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input()
  config: FieldConfig;

  @Input()
  group: FormGroup;

  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}