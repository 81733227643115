import { Component } from '@angular/core';
import { Field } from '@shared/components/dynamic-form/models/field.interface';
import { FieldConfig } from '@shared/components/dynamic-form/models/field-config.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-moeda-result',
  templateUrl: './moeda-result.component.html',
  styleUrls: ['./moeda-result.component.scss']
})
export class MoedaResultComponent  implements Field {
  config: FieldConfig;
  group: FormGroup;
}
