import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scroll]'
})
export class ScrollDirective {
  @Output() onScrollEnd = new EventEmitter();
  scroll: number;

  constructor(private el: ElementRef) { }

  @HostListener('scroll', ['$event'])
  scrollIt() {

    if (event && event.srcElement['scrollTop']) {
      this.scroll = event.srcElement['scrollTop'];
      this.onScrollEnd.emit(this.scroll)
    }
  }
}
