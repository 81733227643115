import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tab } from '@core/models/tab.models';


@Injectable({
  providedIn: 'root'
})
export class TabsSharedDataService {

  limiteAbas = 4;

  private adicionarTab = new Subject<Tab>();
  adicionarTab$ = this.adicionarTab.asObservable();

  private limitTab = new Subject<boolean>();
  limitTab$ = this.limitTab.asObservable();

  private triggerGetTab = new Subject<string>();
  triggerGetTab$ = this.triggerGetTab.asObservable();

  private returnTab = new Subject<Tab>();
  returnTab$ = this.returnTab.asObservable();

  private noHasTab = new Subject<boolean>();
  noHasTab$ = this.noHasTab.asObservable();

  private triggerLimitTab = new Subject<boolean>();
  triggerLimitTab$ = this.triggerLimitTab.asObservable();


  constructor() { }

  setNovaTab(tab: Tab): void {
    this.adicionarTab.next(tab);
  }

  setLimitTab(data): void {
    this.limitTab.next(data);
  }

  setGetTab(id: string): void {
    this.triggerGetTab.next(id);
  }

  getTab(tab: Tab): void {
    this.returnTab.next(tab);
  }

  setNoHasTab(data: boolean): void {
    this.noHasTab.next(data);
  }

  setTriggerLimitTab(data: boolean): void {
    this.triggerLimitTab.next(data);
  }
}
