import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Masks } from '@core/custom-validators/masks';
import * as moment from 'moment';

@Component({
  selector: 'ds-custom-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss',]
})
export class RangeDatepickerComponent implements OnInit {
  @Input() placeholder: string = ""
  @Input() ngModel: any;
  @Input() name: string = "";
  @Input() mask: string = "";
  @Input() id: string = ""
  @Input() label: string = "";
  @Input() value: any = null;
  @Input() todayAsMaxDate: any = ""
  @Input() readonly: boolean = false
  @Input() placement: string = "bottom"

  @Input() clearDateInputEvent: EventEmitter<boolean>

  periodoForm: FormGroup;

  @Output() ngModelChangeValue = new EventEmitter<any>()

  dateMask = {
    mask: Masks.data,
    guide: false,
    modelClean: true
  };

  selectCalendarOne: Date | null;
  selectCalendarTwo: Date | null;
  dateSelectedStart: Date | null;
  dateSelectedEnd: Date | null;
  dateCalendarOne: Date | null;
  dateCalendarTwo = new Date(moment().add(1, 'month').format())

  calendarOne = { dia: undefined, mes: undefined, ano: undefined };
  calendarTwo = { dia: undefined, mes: undefined, ano: undefined };

  @ViewChild('calendarOne', { static: false }) calendarsOne;
  @ViewChild('calendarTwo', { static: false }) calendarsTwo;


  constructor() { }

  ngOnInit() {
    if (this.todayAsMaxDate) this.todayAsMaxDate = new Date()

    this.periodoForm = new FormBuilder().group(
      {
        dataInicio: [moment().format('YYYY-MM-DD'), Validators.required],
        dataFim: [moment().format('YYYY-MM-DD'), Validators.required],
        periodo: [],
      }
    );

    this.clearDateInputEvent.subscribe((event: boolean) => {
      if (event) this.clearDateCalendar()
    })
  }

  dateSelectOne(event) {
    let calendarOne = this.calendarOne.dia = event;
    let calendarTwo = this.calendarTwo.dia;

    if (calendarOne <= this.dateSelectedStart || this.dateSelectedStart == undefined) {
      this.removeToday();

      this.dateSelectedStart = calendarOne;

      if (this.dateSelectedStart != undefined)
        this.dateSelectedEnd = calendarTwo;
    }

    else if (this.calendarOne <= calendarTwo || calendarTwo == undefined) {
      this.dateSelectedStart = calendarOne;

      if (calendarOne < calendarTwo)
        this.dateSelectedEnd = calendarTwo;
    }

    else if (this.calendarOne > calendarTwo) {
      this.dateSelectedStart = calendarTwo;

      if (calendarOne > calendarTwo)
        this.dateSelectedEnd = calendarOne;
    }

    else {
      this.dateSelectedEnd = calendarOne;

      if (calendarOne > calendarTwo)
        this.dateSelectedStart = calendarTwo;
    }
  }

  dateSelectTwo(event) {
    let calendarTwo = this.calendarTwo.dia = event;
    let calendarOne = this.calendarOne.dia;

    if (calendarTwo <= this.dateSelectedStart || this.dateSelectedStart == undefined) {
      this.removeToday();

      this.dateSelectedStart = calendarTwo;

      if (this.dateSelectedStart != undefined)
        this.dateSelectedEnd = calendarOne;
    }

    else if (this.calendarTwo <= calendarOne || calendarOne == undefined) {
      this.dateSelectedStart = calendarTwo;

      if (calendarOne < calendarOne)
        this.dateSelectedEnd = calendarOne;
    }

    else if (this.calendarTwo > calendarOne) {
      this.dateSelectedStart = calendarOne;

      if (calendarTwo > calendarOne)
        this.dateSelectedEnd = calendarTwo;
    }

    else {
      this.dateSelectedEnd = calendarTwo;

      if (calendarTwo > calendarOne)
        this.dateSelectedStart = calendarOne;
    }
  }

  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  submitDateCalendar() {
    this.dataInicio.setValue(moment(this.selectCalendarOne).format('YYYY-MM-DD'));
    this.dataFinal.setValue(moment(this.selectCalendarTwo).format('YYYY-MM-DD'));
    this.periodo.setValue(`${moment(this.dataInicio.value).format('DD/MM/YYYY')} à ${moment(this.dataFinal.value).format('DD/MM/YYYY')}`)
    this.emitDate()
  }

  clearDateCalendar(): void {
    this.removeToday();
    this.dataInicio.setValue(undefined);
    this.dataFinal.setValue(undefined);
    this.periodo.setValue(undefined);
    this.removeToday();
    this.selectCalendarOne = this.selectCalendarTwo = this.dateSelectedStart = this.dateSelectedEnd = undefined;
    this.emitDate()
  }

  btnDay(): void {
    this.removeToday();
    let dateFix = moment().format()
    this.selectCalendarOne = new Date(dateFix);
    this.updateDate();
  }

  btnYesterday(): void {
    this.removeToday();
    let dateFix = moment().subtract(1, 'd').format();
    this.selectCalendarOne = new Date(dateFix);
    this.updateDate();
  }

  btnWeek(): void {
    this.removeToday();
    let dateFixStart = moment().subtract(7, 'days').format();
    let dateFixEnd = moment().format();
    this.removeToday();
    this.selectCalendarOne = new Date(dateFixStart);
    this.selectCalendarTwo = new Date(dateFixEnd);
    this.updateDate();
  }

  btnLastWeeek(): void {
    this.removeToday();
    let dateFixStart = moment().subtract(14, 'days').format();
    let dateFixEnd = moment().subtract(7, 'days').format();
    this.removeToday();
    this.selectCalendarOne = new Date(dateFixStart);
    this.selectCalendarTwo = new Date(dateFixEnd);
    this.updateDate();
  }

  btnMonth(): void {
    this.removeToday();
    let dateFixStart = moment().startOf('month').format();
    let dateFixEnd = moment().endOf('month').format();
    this.removeToday();
    this.selectCalendarOne = new Date(dateFixStart);
    this.selectCalendarTwo = new Date(dateFixEnd);
    this.updateDate();
  }

  btnLastMonth(): void {
    this.removeToday();
    let dateFixStart = moment().subtract(1, 'months').startOf('month').format();
    let dateFixEnd = moment().subtract(1, 'months').endOf('month').format();
    this.removeToday();
    this.selectCalendarOne = new Date(dateFixStart);
    this.selectCalendarTwo = new Date(dateFixEnd);
    this.updateDate();
  }

  removeToday(): void {
    let element = document.querySelector('.mat-calendar-body-today');
    if (element)
      element.classList.remove('mat-calendar-body-today');
  }

  dateClassOne = (dateOne) => {
    if (dateOne)
      this.dateClassFix(dateOne, '.calendar-one')
  }

  dateClassTwo = (dateTwo) => {
    if (dateTwo)
      this.dateClassFix(dateTwo, '.calendar-two')
  }

  dateClassFix(date, calendarClass) {
    if (date) {
      date.dia = moment(date).date();
      date.mes = moment(date).locale('pt-br').format('MMMM');
      date.ano = moment(date).year();

      let element = document.querySelector(`${calendarClass} .mat-button-wrapper`);
      if (element)
        element.innerHTML = `${date.mes} ${date.ano}`
    }
  }

  updateDate() {
    if (this.selectCalendarOne)
      this.calendarsOne.activeDate = this.selectCalendarOne;
    if (this.selectCalendarTwo)
      this.calendarsTwo.activeDate = this.selectCalendarTwo;
  }


  get dataInicio() {
    return this.periodoForm.controls.dataInicio;
  }

  get dataFinal() {
    return this.periodoForm.controls.dataFim;
  }

  get periodo() {
    return this.periodoForm.controls.periodo;
  }

  emitDate() {
    this.ngModelChangeValue.emit({ dataInicio: this.dataInicio.value, dataFinal: this.dataFinal.value })
  }
}
