import { Component, OnInit } from '@angular/core';
import { Masks } from '@core/custom-validators/masks';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-periodo',
  templateUrl: './form-periodo.component.html',
  styleUrls: ['./form-periodo.component.scss']
})
export class FormPeriodoComponent implements Field, OnInit {

  config: FieldConfig;
  group: FormGroup;
  mask = {
    mask: Masks.periodoAno,
    guide: false
  };
  formPeriodo: FormGroup;
  firstSubmit = false;

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.formPeriodo = this.formBuilder.group({
      inicio: ['', Validators.minLength(4)],
      fim: ['', Validators.minLength(4)]
    })

    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.inicio.setValue(undefined)
      }
    })

    this.inicio.valueChanges.subscribe((val) => {
      if (this.fim.valid && this.inicio.valid) {
        if (this.hasValue(this.fim.value)) {
          let inicio = parseInt(val);
          let fim = parseInt(this.fim.value);
          let periodo = this.parsePeriodo(inicio, fim);
          this.control.setValue(periodo);
        }
        else
          this.control.setValue(`${val}`);
      }

      else {
        this.control.setValue(val);
        this.control.setValidators(Validators.compose([Validators.required, Validators.minLength(4)]));
        this.control.updateValueAndValidity();
      }
    })

    this.fim.valueChanges.subscribe((val) => {

      if (!val) {
        this.inicio.clearValidators();
        this.inicio.setValidators(Validators.compose([Validators.minLength(4)]));
        this.inicio.updateValueAndValidity();
        this.control.clearValidators();
        this.control.updateValueAndValidity();
      }
      else {
        if (this.inicio.valid && !this.fim.valid) {
          this.control.setValue(`${this.inicio.value}`);
        }
        else
          if (this.inicio.valid && this.fim.valid) {
            let inicio = parseInt(this.inicio.value);
            let fim = parseInt(this.fim.value);
            let periodo = this.parsePeriodo(inicio, fim);
            this.control.setValue(periodo);
          }
          else {
            this.control.setValue(undefined);
          }
        this.control.setValidators(Validators.compose([Validators.required, Validators.minLength(4)]));
        this.control.updateValueAndValidity();
        this.inicio.setValidators(Validators.compose([Validators.required, Validators.minLength(4)]));
        this.inicio.updateValueAndValidity();
      }
    })
  }

  get inicio() {
    return this.formPeriodo.controls.inicio;
  }

  get fim() {
    return this.formPeriodo.controls.fim;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  hasValue(val): boolean {
    return val != undefined && val != '';
  }

  parsePeriodo(inicio, fim) {
    let periodo = ''
    if (inicio <= fim)
      for (let i = inicio; i <= fim; i++) {
        if (!periodo)
          periodo += `${i}`;
        else
          periodo += `|${i}`;
      }

    if (inicio >= fim)
      for (let i = fim; i <= inicio; i++) {
        if (!periodo)
          periodo += `${i}`;
        else
          periodo += `|${i}`;
      }

    return periodo;
  }
}
