import { FormControl } from "@angular/forms";

export function contratoValidator(control: FormControl) {
    var re = new RegExp(/[0-9]{6}\/?[0-9]{6}/g);

    if (control.value != undefined && control.value != null && control.value != '' && !re.test(control.value))
        return {
            contrato: true
        }
    return null;
}

