export class TipoDado {
    id: number;
    nome: string;
}

export enum TipoDadoEnum {
    Integer = 1,
    Decimal = 2,
    Texto = 3,
    Data = 4,
    Booleano = 5,
    Moeda = 6,
    Array = 7,
    Objeto = 8,
    Hora= 9,
    Link = 10,
    CPF = 11,
    CNPJ = 12,
    Telefone = 13,
    PeriodoAnoAAno = 14,
    CPFCNPJ = 15,
    DataComTraco = 16,
    CEP = 17,
    DataAAAAMMDD = 18,
    Placa = 19,
    Chassi = 20
}