import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContatoComponent } from './contato.component';


@NgModule({
    declarations: [ContatoComponent],
    imports: [
        CommonModule
    ]
})
export class ContatoModule { }
