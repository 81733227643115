import { NgModule } from '@angular/core';
import { NotificacaoDetalheComponent } from './components/notificacao-detalhe/notificacao-detalhe.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [NotificacaoDetalheComponent],
    imports: [
        SharedModule
    ],
    exports: [
        NotificacaoDetalheComponent
    ]
})
export class NotificacaoModule { }
