import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { AlterarPropostaComponent } from './alterar-proposta/alterar-proposta.component';
import { CadastrarApoliceComponent } from './cadastrar-apolice/cadastrar-apolice.component';
import { CadastrarPropostaComponent } from './cadastrar-proposta/cadastrar-proposta.component';
import { DatepickerComponent } from './components/atoms/datepicker/datepicker.component';
import { InputComponent } from './components/atoms/input/input.component';
import { SelectComponent } from './components/atoms/select/select.component';
import { StatusComponent } from './components/atoms/status/status.component';
import { HeaderTabMenuComponent } from './components/molecules/header-tab-menu/header-tab-menu.component';
import { PaginationComponent } from './components/molecules/pagination/pagination.component';
import { TitleWithBorderComponent } from './components/molecules/title-with-border/title-with-border.component';
import { ConsultarPropostaComponent } from './consultar-proposta/consultar-proposta.component';
import { FormularioConsultaComponent } from './consultar-proposta/formulario-consulta/formulario-consulta.component';
import { OutputTemplateConsultaComponent } from './consultar-proposta/output-template-consulta/output-template-consulta.component';
import { EnumTextPipe } from './pipes/enum-text.pipe';
import { AlterarApoliceComponent } from './alterar-apolice/alterar-apolice.component';
import { RangeDatepickerComponent } from './components/atoms/range-datepicker/range-datepicker.component';
import { MonthPickerComponent } from './components/atoms/month-picker/month-picker.component';
import { TagTipoCadastroComponent } from './components/atoms/tag-tipo-cadastro/tag-tipo-cadastro.component';

@NgModule({
    declarations: [
        CadastrarPropostaComponent,
        HeaderTabMenuComponent,
        TitleWithBorderComponent,
        InputComponent,
        SelectComponent,
        DatepickerComponent,
        CadastrarApoliceComponent,
        ConsultarPropostaComponent,
        FormularioConsultaComponent,
        OutputTemplateConsultaComponent,
        AlterarPropostaComponent,
        PaginationComponent,
        StatusComponent,
        EnumTextPipe,
        AlterarApoliceComponent,
        MonthPickerComponent,
        TagTipoCadastroComponent,
        RangeDatepickerComponent
    ],
    exports: [
        CadastrarPropostaComponent,
        HeaderTabMenuComponent,
        TitleWithBorderComponent,
        InputComponent,
        SelectComponent,
        DatepickerComponent,
        CadastrarApoliceComponent,
        ConsultarPropostaComponent,
        FormularioConsultaComponent,
        OutputTemplateConsultaComponent,
        AlterarPropostaComponent,
        PaginationComponent,
        StatusComponent,
        EnumTextPipe,
        AlterarApoliceComponent,
        MonthPickerComponent,
        TagTipoCadastroComponent,
        RangeDatepickerComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgbPaginationModule,
        NgbAlertModule,
    ]
})
export class RnpaPessoasModule { }
