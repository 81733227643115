import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Tab } from '@core/models/tab.models';
import { TabsSharedDataService } from '@modules/tabs/tabs-shared-data.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResultadoConsultaService } from '@core/services/resultado-consulta.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterViewInit {

  activeTabId: string;
  faTimes = faTimes;
  tabs: Tab[] = [];
  lastUrlConsulta: string;
  constructor(private tabsSharedDataService: TabsSharedDataService,
    private resultadoConsultaService: ResultadoConsultaService,
    private router: Router,
    private activatedRouted: ActivatedRoute,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.tabsSharedDataService.adicionarTab$.subscribe((data) => {

      data.id = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);

      this.tabs.push(data);
      data.loading = true;
      if (this.tabs.length == this.tabsSharedDataService.limiteAbas) {
        this.tabsSharedDataService.setLimitTab(true);
      }
      data.request.subscribe((response) => {
        data.protocolo = response.protocolo;
        if (response.hasError && !response.success) {
          data.loading = false;
          data.error = true;
          data.msg = response.msg;
          data.msg = response.msg == 'The "size" argument must be of type number. Received type undefined' ? 'Parâmetros do método incorretos.' : response.msg;
          data.hasError = true;
        }
        else {

          let result = this.resultadoConsultaService.montarResultado(response, data.parametrosSaida, data.parametrosErro);

          let protocolo = result.configs.find(x => x.name == 'protocolo');
          if (protocolo)
            data.protocolo = protocolo.value;

          let saldo = result.configs.find(x => x.name == 'saldo');
          if (saldo)
            data.saldo = saldo.value;

          data.configs = result.configs.filter(x => x.name != 'protocolo' && x.name != 'saldo');
          data.loading = false;
          data.objRetornoServico = result.objRetornoServico;
          if (!result.configs.length) {
            data.msg = 'Não foi possível montar o resultado para exibição. Tente novamente mais tarde.';
            data.error = true;
            data.hasError = true;
          }
          else {
            data.success = true;
            data.msg = response.msg;
            data.isResultadoCustomizado = result.sucesso && data.template != undefined;
          }
        }

        let index = this.tabs.findIndex(x => x.id == data.id);

        if (index != -1) {
          this.tabs[index] = data;
        }
      }, (error) => {
        data.resultado = error;
        data.loading = false;
        data.error = true;
      })
    })

    this.tabsSharedDataService.triggerGetTab$.subscribe((id) => {
      let tab = this.tabs.find(x => x.id == id);
      this.tabsSharedDataService.getTab(tab);
    })


    this.tabsSharedDataService.triggerLimitTab$.subscribe((data) => {
      this.tabsSharedDataService.setLimitTab(this.tabs.length == this.tabsSharedDataService.limiteAbas);
    })

  }

  abaAtiva(aba: Tab): boolean {
    return this.router.url.startsWith('/tab') && this.activeTabId == aba.id;
  }

  clickTab(aba: Tab, event: any): void {
    if (event.target.nodeName == 'SPAN' || event.target.nodeName == 'BUTTON')
      return;

    if (aba.loading)
      return;

    this.activeTabId = aba.id;
    aba.success = false;
    aba.error = false;

    this.router.navigate(['tab', aba.id], { relativeTo: this.activatedRouted });
  }

  removeTab(aba: Tab): void {
    let index = this.tabs.indexOf(aba);
    if (index != -1) {
      this.tabs.splice(index, 1);
      if (this.tabs.length < this.tabsSharedDataService.limiteAbas)
        this.tabsSharedDataService.setLimitTab(false);
      if (this.tabs.length == 0) {
        this.tabsSharedDataService.setNoHasTab(true);
      }
    }
  }

  ngAfterViewInit(): void {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {


        if (event.url.includes('/consulta/')) {
          this.localStorageService.setItem('ultimaConsulta', event.url);
        }

        if (!event.url.includes('/tab/')) {
          this.localStorageService.setItem('lastUrl', event.url);
        }

        if (!event.url.includes('/tab/') && !event.url.includes('/consulta/')) {
          this.localStorageService.setItem('lastUrlSemConsulta', event.url);
        }
      }
    })
  }
}
