import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Masks } from '@core/custom-validators/masks';
import { CustomValidators } from '@core/custom-validators/validators';

@Component({
  selector: 'ds-custom-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, AfterViewInit {

  inputFormControl  = new FormControl()

  @Input() placeholder: string = ''
  @Input() value: any = null;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() name: string = '';
  @Input() id: string = ''
  @Input() label: string = '' 
  @Input() opcional: any
  @Input() mask: any = {
    mask: Masks.any,
    guide: false,
    modelClean: true
  }

  @Input() customValidator: string
  @Input() type: string = "text"

  @Input() validateInputEvent?: EventEmitter<boolean> = new EventEmitter<boolean>()

  // Event Emitter
  @Output() ngModelChangeValue = new EventEmitter<any>()

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.validateInput()
    this.setCustomValidator()
  }
  
  ngOnInit() {}
  
  setCustomValidator(){
    if(this.customValidator)
    this.inputFormControl = new FormControl(this.value, CustomValidators[this.customValidator])
  }

  validateInput(){
    this.validateInputEvent.subscribe((res) => {
      var elementRef = this.elementRef.nativeElement.querySelector('#'+this.id)
      
      elementRef.focus()
      elementRef.blur()
    })
  }

}
