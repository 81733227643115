import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { FiltroProdutosUsuario } from '@core/models/consulta-metodo.models';
import { IProdutos, IRamos, RamosMenuEnum } from '@core/models/ramo.models';
import { ConsultaService } from '@core/services/consulta.service';
import { DashboardService } from '@core/services/dashboard.service';
import { NotificationService } from '@core/services/notification.service';
import { UsuarioService } from '@core/services/usuario.service';
import { faStar as starRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar as starSolid } from '@fortawesome/free-solid-svg-icons';
import { HeaderSharedDataService } from '@shared/components/header/header-shared-data.service';
import { EStorage } from '@shared/utils/enums/storage.enum';
import { StorageService } from '@shared/utils/services/storage.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AppSharedDataService } from 'src/app/app-shared-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit, OnDestroy {

  faStarRegular = starRegular;
  faStarSolid = starSolid;
  filtro: FiltroProdutosUsuario;
  hideRamos: boolean;
  subScribeSearch: Subscription;
  currentUserName: string;
  metodoAnterior: any;
  metodoAtual: any;

  arrayStepTour: number = 2;
  stepsTour: any[] = [];
  titleTour: string;
  descriptionTour: string;

  itensMenuPbi: any[] = [];

  // Ramos e produtos
  ramos: IRamos[] = []
  produtos: IProdutos[] = []

  @HostListener('document:click')
  clickOutside() {
    if (!document.getElementById('cards'))
      this.metodoAtual = null;
  }

  constructor(
    private router: Router,
    private consultaService: ConsultaService,
    private headerSharedDataService: HeaderSharedDataService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private appSharedDataService: AppSharedDataService,
    private usuarioService: UsuarioService,
    private dashboardService: DashboardService,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.listarRamos()

    this.filtro = {};

    this.activeRoute.queryParams.subscribe((params) => {
      this.filtro.texto = params.q;
    })

    this.subScribeSearch = this.headerSharedDataService.search$.subscribe((val) => {
      this.hideRamos = !!val
      if (this.router.url.endsWith('dashboard')) {
        if (this.filtro.texto != val) {
          this.filtro.texto = val;
          delete this.filtro.idRamo;
        }
      }
    })

    this.headerSharedDataService.showSearchText(true);

    const { nomeReduzido, nomeCompleto } = this.authenticationService.currentUserValue
    this.currentUserName = (nomeReduzido || nomeCompleto).split(' ')[0];

    if (document.querySelector(".grecaptcha-badge")) {
      document.querySelector(".grecaptcha-badge").remove();
    }

    this.createTour();

    this.itensMenuPbi = this.dashboardService.powerBiMenu
  }

  get _ramos() {
    return this.ramos
  }

  get _products(): IProdutos[] {
    return this.ramos.reduce((prev, current) => {
      return prev.concat(current.produtos)
    }, [] as IProdutos[])
  }

  get hasProdutos(): boolean {
    return !!this._products.length
  }

  get filteredProducts() {
    if(!this.filtro.texto) return []
    return this._products.filter((product) =>
      product.nomeProduto
        .normalize('NFD').toLowerCase()
        .includes(this.filtro.texto.normalize('NFD').toLowerCase()))
        .slice(0, 2)
  }

  listarRamos(){
    const storageMenuValue = this.storageService.get({storageKey: EStorage.MENU})

    if(storageMenuValue){
      this.ramos = storageMenuValue.value
      return
    }
  }

  ngOnDestroy() {
    this.subScribeSearch.unsubscribe();
  }

  goToProdutos(ramo): void {
    ramo.open = true;
    this.appSharedDataService.setRamoAtivo(ramo);
    this.router.navigate([`/dashboard/${ramo.id}/produtos`]);
  }

  onDesFavorite(idProduto: number): void {
    this.consultaService.desFavoritarProduto(idProduto).subscribe();
  }

  onFavorite(idProduto: number): void {
    this.consultaService.favoritarProduto(idProduto).subscribe();
  }

  showMetodo(produto) {
    return produto.metodos.length >= 2;
  }

  goToConsulta(ramo: any, produto: any, metodo: any): void {
    if (metodo) {
      let reportNamePBI = this.itensMenuPbi.find((x: any) => x.id == metodo.id);
      if (reportNamePBI) {
        sessionStorage.setItem('reportNamePBI', reportNamePBI.subItem[0].nameReport);
      }
    }

    if (produto.acesso && metodo.acesso) {
      this.appSharedDataService.setMetodoAtivo({ ramo: ramo, produto: produto, metodo: metodo });
      this.router.navigate(['/consulta', produto.idProduto, metodo.id, produto.dsServico, metodo.dsMetodo], { queryParamsHandling: 'merge', queryParams: { favorito: produto.favorito } });
    } else {
      this.notificationService.info('Você não possui permissão para realizar esta consulta!');
    }
      
  }

  goToLinkExterno(acesso: any): void {
    if (!acesso)
      this.notificationService.info('Você não possui permissão para acessar este produto!');
  }

  addIconsMenu(ramo) {
    switch (ramo.id) {
      case RamosMenuEnum.Automovel:
        return "automovel"
      case RamosMenuEnum.Habitacional:
        return "habitacional"
      case RamosMenuEnum.Multiramo:
        return "multiramo"
      case RamosMenuEnum.Saude:
        return "saude"
      case RamosMenuEnum.Previdencia:
        return "previdencia"
      case RamosMenuEnum.Vida:
        return "vida"
    }
  }

  fnMetodoChecked(item: any) {
    item.checked = !item.checked;

    if (this.metodoAtual)
      this.metodoAnterior = this.metodoAtual;

    this.metodoAtual = item;

    if (this.metodoAnterior == this.metodoAtual) {
      let classeItem;
      this.metodoAtual.id ? classeItem = document.getElementById(this.metodoAtual.id) : classeItem = document.getElementById(this.metodoAtual.idProduto)
      !this.metodoAtual.checked ? classeItem.classList.remove('checked') : classeItem.classList.add('checked')
    }
  }

  createTour(): void {
    this.appSharedDataService.stepsTour$.subscribe((res) => {
      this.stepsTour = res
    });

    this.appSharedDataService.stepTourJson$.subscribe((res) => {
      this.titleTour = res[this.arrayStepTour].title;
      this.descriptionTour = res[this.arrayStepTour].description;
    });
  }

  finalTour(): void {
    this.usuarioService.inactiveTour(this.authenticationService.currentUserValue.id_usuario).subscribe((res) => {
    });
  }
}