import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatPageable } from '@core/table/mat-table';
import { MetodoCombo, FiltroMetodo, MetodoElement, Metodo, MetodoCrud } from '@core/models/metodo.models';
import { environment } from '@env/environment';

const routes = {
  buscatodos: (pagina: number, registros: number) => `metodo/buscatodos/${pagina}/${registros}`,
  buscaPorParametros: `metodo/buscaporvalores`,
  inativar: `metodo/inativar`,
  reativar: `metodo/reativar`,
  buscaPorId: (id: number) => `metodo/buscaporid/${id}`,
  cadastrar: `metodo/cadastrar`,
  atualizar: `metodo/alterar`,
  buscaPorIdCrud: (id: number) => `crud/metodo/${id}`,
  alterarCrud: (id: number) => `crud/alterarMetodo/${id}`,
  salvarCrud: `crud/salvarMetodo`,
}

@Injectable({
  providedIn: 'root'
})
export class MetodoService {

  constructor(private apiService: ApiService) { }

  buscarTodosCombo(pagination: MatPageable) {
    return this.apiService.get<MetodoCombo[]>(routes.buscaPorParametros + pagination.query);
  }

  getMetodos(pagination: MatPageable, filtro: FiltroMetodo) {
    let params = this.apiService.convertObjectToParams(filtro);
    return this.apiService.get<MetodoElement[]>(routes.buscaPorParametros + pagination.query, params);
  }

  salvar(metodo: Metodo) {

    if (metodo.id)
      return this.apiService.put<any>(routes.atualizar, {
        Metodo: metodo
      });
    else
      return this.apiService.post<any>(routes.cadastrar, {
        Metodo: metodo
      });
  }

  getMetodo(id: number) {
    return this.apiService.get<Metodo>(routes.buscaPorId(id));
  }

  getMetodoGrud(id: number) {
    return this.apiService.getCustomApi<MetodoCrud>(`${environment.lote}/${routes.buscaPorIdCrud(id)}`);
  }

  salvarCrud(metodo: MetodoCrud) {

    if (metodo.idMetodo)
      return this.apiService.putCustomApi<any>(`${environment.lote}/${routes.alterarCrud(metodo.idMetodo)}`, metodo);
    else
      return this.apiService.postCustomApi<any>(`${environment.lote}/${routes.salvarCrud}`, metodo);
  }
}
