import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from '../../models/field-config.interface';
import { faCalendar, faEraser } from '@fortawesome/free-solid-svg-icons';
import { Masks } from '@core/custom-validators/masks';
import { CustomValidators } from '@core/custom-validators/validators';
import * as moment from 'moment';

@Component({
  selector: 'app-form-datepicker-traco',
  templateUrl: './form-datepicker-traco.component.html',
  styleUrls: ['./form-datepicker-traco.component.scss']
})
export class FormDatepickerTracoComponent implements Field, OnInit {
  formDatePicker: FormGroup;
  config: FieldConfig;
  group: FormGroup;
  faCalendar = faCalendar;
  faEraser = faEraser;
  mask = {
    mask: Masks.data,
    guide: false
  };
  showClearDate: boolean;
  firstSubmit = false;

  constructor(private formBuilder: FormBuilder) {

  }
  ngOnInit(): void {
    this.formDatePicker = this.formBuilder.group({
      data: [null],
      inputData: [null, Validators.compose([Validators.required, CustomValidators.data])],
    })
    this.inputData.setValue(undefined)
    this.group.statusChanges.subscribe((val) => {
      if (!this.firstSubmit) {
        this.firstSubmit = true;
        this.inputData.updateValueAndValidity();
      }
    })

    this.data.valueChanges.subscribe((val) => {
      if (val) {
        this.control.setValue(moment(val, 'YYYY-MM-DD', true).format('DD-MM-YYYY'));
        this.inputData.setValue(moment(val, 'YYYY-MM-DD', true).format('DD/MM/YYYY'));
        this.showClearDate = true;
      }
      else {
        this.showClearDate = false;
        this.control.setValue(undefined);
        this.inputData.setValue(undefined);
      }
    })

    this.inputData.valueChanges.subscribe((val) => {
      if (val) {
        if (moment(val, 'DD/MM/YYYY', true).isValid()) {
          this.control.setValue(moment(val, 'DD/MM/YYYY', true).format('DD-MM-YYYY'));
        }
        else
          this.control.setValue(undefined)
        this.showClearDate = true;
      }
      else {
        this.showClearDate = false;
        this.control.setValue(undefined);
      }
    })
  }

  get data() {
    return this.formDatePicker.controls.data;
  }

  get inputData() {
    return this.formDatePicker.controls.inputData;
  }

  get control() {
    return this.group.controls[this.config.name];
  }

  clearDate(): void {
    this.data.setValue(undefined);
    this.inputData.setValue(undefined);
  }
}