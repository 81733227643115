import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';
import { LocalStorageEnum } from '../enumerators';
import { AuthenticationService } from '@core/auth/authentication.service';

interface AuthRes {
  access_token: string,
  expires_in: number,
  token_type: string,
  refresh_token: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _authUrl = `${environment.apiGatewayDns}/oauth/v1/access-token`;
  private _refreshTokenUrl = `${environment.apiGatewayDns}/oauth/v1/access-token`;

  rnpaAuthHttpHeaders: HttpHeaders = new HttpHeaders({
    'rnpa_auth_request': 'true',
  });

  constructor(private _http: HttpClient, public injector: Injector) { }

  getToken(user: { username: string, password: string }) {
    const body = JSON.stringify({ grant_type: 'password', username: user.username, password: user.password });

    return this._http.post(this._authUrl, body, { headers: this.rnpaAuthHttpHeaders }).pipe(
      tap(
        (next: AuthRes) => {
          localStorage.setItem(LocalStorageEnum.RefreshToken, next.refresh_token)
          localStorage.setItem(LocalStorageEnum.TokenSensedia, next.access_token)
        },
      ),
    );
  }

  refreshToken() {
    const refreshToken = localStorage.getItem(LocalStorageEnum.RefreshToken)
    const body = JSON.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken });
    return this._http.post(this._refreshTokenUrl, body, { headers: this.rnpaAuthHttpHeaders }).pipe(
      tap(
        (next: AuthRes) => {
          localStorage.setItem(LocalStorageEnum.RefreshToken, next.refresh_token)
          localStorage.setItem(LocalStorageEnum.TokenSensedia, next.access_token)
        },
        (err) => {
          const authentication = this.injector.get(AuthenticationService)
          authentication.logout()
        }
      ),
    );
  }
}