import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'brHoraResult' })
export class BrHoraResultPipe implements PipeTransform {
    transform(value: any): string {
        if (value) {

            if (value.toString().length == 6) {
                value = `${value.substring(0, 2)}:${value.substring(2, 4)}:${value.substring(4, 6)}`;
            }

            if (value.toString().length == 4) {
                value = `${value.substring(0, 2)}:${value.substring(2, 4)}`;
            }
        }

        return value;
    }
}